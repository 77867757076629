import React, { useEffect } from 'react'

export const DocenteHomeScreen = () => {

  useEffect(() => {
    window.scrollTo(0,0);
  })

  return (
    <div className='crud-center'>
      <h2>Bienvenido Maestro</h2>
    </div>
  )
}
