import React from 'react';
import { Skeleton, Table } from 'antd';

export const CRUDTableSkeleton = ({columns, loading}) => {
    const data = [
        {key: '1'},
        {key: '2'},
        {key: '3'},
        {key: '4'},
        {key: '5'},
    ]    

    columns = columns.map(column => (
            {
                ...column,
                render: (text, record) => {
                    return (
                        <Skeleton active paragraph={{ rows: 1, width: '100%'}} title={false} loading={loading} />
                    )
                },
            }
        )
    );

    return (
        <div>
            <Table columns={columns} dataSource={data} pagination={false}>
            </Table>
        </div>
    )
}
