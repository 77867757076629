import { Form, Button, Input } from 'antd';
import React from 'react';
import { defaultValidateMessages } from '../../helpers/validateMessages';

import { MailOutlined ,LockOutlined} from '@ant-design/icons';
import { startLoginMaestro } from '../../actions/auth';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

export const DocenteAuth = () => {

    const dispatch = useDispatch();

    const handleLogin = (datos) => {
        dispatch(startLoginMaestro(datos));
    }

    return (
        <div className="auth-center">
            <div className="docente-auth-card">
                    
                <h2>HOLA MAESTRO</h2>
                <p>¡INICIA SESIÓN PARA COMENZAR A ENSEÑAR!</p>
                    
                <Form 
                    className="login-form"
                    style={{paddingTop: '20px'}}
                    validateMessages={defaultValidateMessages}
                    onFinish={handleLogin}
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                            required: true,
                            type: 'email'
                            },
                        ]}
                    >
                        <Input 
                            prefix={<MailOutlined style={{color: 'var(--primary)'}} className="site-form-item-icon" />} 
                            placeholder="Correo"
                            autoComplete="off"
                        />
                    </Form.Item>
                            
                    <Form.Item
                        name="password"
                        rules={[
                            {
                            required: true,
                            },
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined style={{color: 'var(--primary)'}} className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                            autoComplete="off"
                        />
                    </Form.Item>

                    
                    <div style={{width: '100%' , textAlign: 'left', paddingBottom: '10px'}} >
                        <Link to="/docente/auth/sol-password">
                            <Button type="link" style={{color: 'black'}}>
                                ¿Olvidaste tu contraseña?
                            </Button>
                        </Link>
                    </div>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button btn-login">
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
