import React, { useState, useEffect, useRef } from 'react'
import { Col, Row, Input } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
// import screenfull from 'screenfull'
import ReactPlayer from 'react-player'

export const DocenteVideoScreen = ({_id, actividad, statusCurso, setEditado, content, setContent, updateActividadData, setUpdateActividadData}) => {

    const [urlValidation, setUrlValidation] = useState(true);

    useEffect(() => {
        setContent(actividad.data.url);
    }, [setContent, actividad.data.url])

    const handleChangeTitle = ({  target }) => {
        setUpdateActividadData({
            ...updateActividadData,
            titulo: target.value
        });
        setEditado(true);
    }

    const handleChangeDescripcion = ({  target }) => {
        setUpdateActividadData({
            ...updateActividadData,
            descripcion: target.value
        });
        setEditado(true);
    }

    const handleChangeUrl = ({  target }) => {
        let pattern = /https:\/\/www\.youtube\.com\/watch\?v=(.)+/gs;
        if(pattern.test(target.value)){
            setPlayerState({
                ...playerState,
                url: target.value
            });
            setUrlValidation(true);
        }else{
            setUrlValidation(false);
        }
        setContent(target.value);
        setEditado(true);
    }

    const [playerState, setPlayerState] = useState({
        url: actividad.data.url,
        pip: false,
        playing: false,
        controls: true,
        light: false,
        volume: 0.5,
        muted: false,
        playbackRate: 1.0,
        loop: false
    });

    const { url, playing, controls, light, volume, muted, loop, playbackRate, pip } = playerState;

    const player = useRef();

    //Si está productivo
    if(actividad.end === 1 || actividad.curso.status === 2){
        return (
            <>
            <div style={{backgroundColor: 'white', padding: '10px 30px'}}>
                <Row>
                    <Col span={24}>
                        <div style={{width: '100%', textAlign: 'center'}}>
                            <p style={{fontSize: '30px', color: '#80837C'}}>{actividad.nombre}</p>
                        </div> 
                    </Col>

                    <Col span={24}>
                        <div style={{width: '100%', color: '#80837C'}}>
                            {actividad.descripcion}
                        </div>
                    </Col>
                </Row>
                <Row style={{marginTop: '20px'}}>
                    <Col span={24}>
                        <ReactPlayer
                            ref={player}
                            className='react-player'
                            width='700px'
                            height='400px'
                            url={url}
                            pip={pip}
                            playing={playing}
                            controls={controls}
                            light={light}
                            loop={loop}
                            playbackRate={playbackRate}
                            volume={volume}
                            muted={muted}
                        />
                    </Col>
                </Row>
            </div>
            </>
        )
    }
    //Si está en desarrollo
    else{
        return (
            <>
                <Row style={{backgroundColor: 'white', padding: '10px', border: '1px solid #d9d9d9'}}>
                    <Col span={24}>
                        <h4>Titulo</h4>
                        <TextArea 
                            id="nombre" 
                            autoSize={true} 
                            className="input-titulo" 
                            style={{display: 'block', color: '#80837C'}} 
                            placeholder='Titulo de actividad'
                            value={updateActividadData.titulo} 
                            onChange={handleChangeTitle}
                        />
                    </Col>

                    <Col span={24} style={{marginTop: '20px'}}>
                        <h4>Descripción</h4>
                        <TextArea 
                            id='descripcion'  
                            style={{height: '200px', resize: 'none',color:'#80837C'}}  
                            autoSize={false} 
                            placeholder='Descripción de actividad'
                            value={updateActividadData.descripcion}
                            onChange={handleChangeDescripcion}
                        />
                    </Col>
                </Row>
                <Row style={{backgroundColor: 'white', padding: '10px', marginTop: '20px', border: '1px solid #d9d9d9'}}>
                    <Col span={24}>
                        <h4>URL de contenido de video</h4>
                        <div style={{marginBottom: '10px'}} >
                            <Input 
                                id='url'
                                style={{color: '#80837C', 'borderColor': (urlValidation) ? '#d9d9d9' : '#ff4d4f'}}  
                                value={content}
                                onChange={handleChangeUrl}
                            />
                            { !urlValidation && <p style={{color: '#ff4d4f'}}>Formato de url incorrecto</p> }
                        </div>
                        <div style={{marginBottom: '10px'}} >
                        <ReactPlayer
                            ref={player}
                            className='react-player'
                            width='700px'
                            height='400px'
                            url={url}
                            pip={pip}
                            playing={playing}
                            controls={controls}
                            light={light}
                            loop={loop}
                            playbackRate={playbackRate}
                            volume={volume}
                            muted={muted}
                        />
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}
