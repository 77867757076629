import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getAlumno } from '../../actions/admin';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Image } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

export const AdminAlumnoScreen = () => {
    const dispatch = useDispatch();
    const {_id} = useParams();
    const [alumno, setAlumno] = useState({
        nombre: '',
        apellidos: '',
        img: '',
        iniciales: ''
    })

    if(alumno.nombre !== ''){
        alumno.iniciales = alumno.nombre.charAt(0).toUpperCase() + 
        alumno.apellidos.charAt(0).toUpperCase();
    }

    useEffect(() => {
        dispatch(getAlumno(_id, setAlumno));
    }, [dispatch, _id]);

    return (
        <>
            <Link to="/admin/alumnos">
                <div className="btn-atras"><LeftOutlined className="icon-atras" />  atrás</div>
            </Link>
            <div className="admin-maestro-card">
                <div className="admin-maestro-info">
                    {
                        (alumno.img) 
                        ? (
                            <Image preview={false} className="admin-maestro-img" src={alumno.img}></Image>
                        )
                        : (
                            <div className="admin-maestro-iniciales">{alumno.iniciales}</div>
                        )
                    }
                    <div className="admin-maestro-nombre bold">{alumno.nombre + ' ' + alumno.apellidos}</div>
                    <div className="admin-maestro-profesion">Fecha de registro: {alumno.fecha_registro}</div>
                </div>
            </div>
        </>
    )
}