import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Button, Col, Drawer, Image, Input, Row, Space } from 'antd'
import { MenuOutlined, ReadOutlined, SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons'

// eslint-disable-next-line no-unused-vars
import { LoginScreen } from '../auth/LoginScreen';
// eslint-disable-next-line no-unused-vars
import { RegisterScreen } from '../auth/RegisterScreen';

import { agregarCarrito } from '../../actions/usuarios'

import logo from '../../assets/logoEdeX.jpg'
import userDefault from '../../assets/user.jpg'

export const CustomHeader = ({handleLogout}) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const {id, email, nombre} = useSelector(state => state.auth);
    const {img} = useSelector(state => state.usuarios.usuario);
    const {id:uid, origen, sidebar_folder} = useSelector(state => state.auth);
    const {status,items} = useSelector(state => state.usuarios.carritoUsuario);

    const [coll, setColl] = useState(false);
    const [auth, setAuth] = useState('login');

    useEffect(() => {
        dispatch(agregarCarrito());
    }, [dispatch])

    const handleSearch = () => {
        const search = document.getElementById('inputSearch').value;
        history.push(`/courses/search/${search}`);
    }

    const handleGoLearning=()=>{
        history.push('/user/mi_aprendizaje')
    }

    const irCarrito=()=>{
        history.push(`/user/carrito`) 
    }

    const openDrawer = () => {
        setColl(true);
    }

    const closeDrawer = () => {
        setColl(false);
    }

    const handleAcceder = (value) => {

        if(process.env.REACT_APP_ENV === 'BETA'){
            if(value === 'iivvo'){
                window.location = 'https://beta.iivvo.com';
            }
            if(value === 'psicometricos'){
                window.location = 'https://beta.psicometricos.com';
            }
        }else{
            if(value === 'iivvo'){
                window.location = 'https://app.iivvo.com';
            }
            if(value === 'psicometricos'){
                window.location = 'https://www.psicometricos.com';
            }
        }

    }

    // eslint-disable-next-line no-unused-vars
    const changeAuth = () => {
        auth === 'login' ? setAuth('registro') : setAuth('login');
    }

    return (
        <>
            <div className="header-principal">
                <Link to={'/home'}>
                    <img className="logo" alt="logo EdeX" src={logo}></img>
                </Link>
                <div className='header-search-container'>  
                    <Input className='header-search-input' type='text' id='inputSearch' prefix={<SearchOutlined />} placeholder="Buscar" size="large" onPressEnter={handleSearch} />
                </div>
                {
                    (uid) && (
                        <>
                            <div className='header-icon centrar-v' style={{height: '100%', width: 'fit-content'}}>
                                <ReadOutlined style={{fontSize:'20px', marginRight: '15px'}} title="Mi aprendizaje" onClick={handleGoLearning} />
                            </div>
                            <div className='header-icon centrar-v' style={{height: '100%', width: 'fit-content'}}>
                            {
                                (status===true && items.length>0) ? (
                                    <Badge count={items.length}>
                                        <ShoppingCartOutlined style={{fontSize:'20px', marginRight: '15px'}} onClick={irCarrito} />
                                    </Badge>

                                ) : (
                                    <ShoppingCartOutlined style={{fontSize:'20px', marginRight: '15px'}} onClick={irCarrito} />
                                )
                            }
                            </div>
                        </>

                    )

                }
                <div className={(id) ? 'header-icon header-drawer-carpet' : 'header-icon'}>
                    <MenuOutlined onClick={openDrawer}/>
                </div>
            </div>
            <div>
                <Drawer className={(id) ? 'header-drawer-container header-drawer' : 'header-drawer'} visible={coll} onClose={closeDrawer} closable={false} width={window.innerWidth > 767 ? '405px' : '95%'}>
                
                <div>
                    {
                        (id)
                            ?
                                <div>
                                    <div>
                                        <Row gutter={{xs: 2, sm: 4, md: 8, lg: 16}} style={{padding: '25px'}}>
                                            <Col className="gutter-row" span={8}>
                                                <Image className='sidebar-user-img' preview={false} src={img===null ? 'error' : `${img}`} fallback={userDefault}/>
                                            </Col>
                                            <Col className="gutter-row" span={16}>
                                                <p className='header-drawer-nombre'>{nombre}</p>
                                                <p className='header-drawer-email'>{email}</p>
                                            </Col>
                                        </Row>
                                        <hr className='header-drawer-hr' />
                                    </div>
                                    <Row>
                                        <Col>
                                            <div className='header-drawer-item'>
                                                <Link style={{color: 'var(--primary)' }} to="/user/perfil">
                                                    <div className='centrar-v' onClick={handleLogout}>
                                                        <Image className='header-drawer-image' preview={false}  src={`/side_${ sidebar_folder }/perfil.svg`}/>
                                                        <div className='header-drawer-text'>Perfil</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            {(origen === 0)
                                            ? <div className='header-drawer-item'>
                                                <div className='centrar-v' onClick={handleLogout}>
                                                    <Image className='header-drawer-image' preview={false}  src={`/side_${ sidebar_folder }/back.svg`}/>
                                                    <div className='header-drawer-text'>salir</div>
                                                </div>
                                            </div>
                                            : ((origen === 1)
                                            ? <div className='header-drawer-item'>
                                                <div className='centrar-v' onClick={() => handleAcceder('iivvo')}>
                                                    <Image className='header-drawer-image' preview={false}  src={`/side_${ sidebar_folder }/back.svg`}/>
                                                    <div className='header-drawer-text'>iiVVO</div>
                                                </div>
                                            </div>
                                            : <div className='header-drawer-item'>
                                                <div className='centrar-v' onClick={() => handleAcceder('psicometricos')}>
                                                    <Image className='header-drawer-image' preview={false}  src={`/side_${ sidebar_folder }/back.svg`}/>
                                                    <div className='header-drawer-text'>psicometricos</div>
                                                </div>
                                            </div>)}
                                            {/* <div span={24}>
                                                <Link style={{color: 'var(--primary)' }} to="/user/finances">
                                                    <Button onClick={closeDrawer} type="link"><h3>Mis compras</h3></Button>
                                                </Link>
                                            </div> */}
                                        </Col>
                                    </Row>

                                </div>
                            :
                                // auth === 'login' 
                                // ?
                                //     <div style={{padding: '25px'}}>
                                //         <h1 style={{color: 'var(--primary)'}}>Login</h1>
                                //         <LoginScreen changeAuth={changeAuth} closeDrawer={closeDrawer}/>
                                //     </div>
                                // : auth === 'registro' &&
                                    
                                //     <div style={{padding: '25px'}}>
                                //         <h1 style={{color: 'var(--primary)'}}>Registro</h1>
                                //         <RegisterScreen changeAuth={changeAuth} closeDrawer={closeDrawer}/>
                                //     </div>

                                <div style={{padding: '25px'}}>
                                    <h1 style={{color: 'var(--primary)'}}>Acceder</h1>
                                    <Space
                                        direction="vertical"
                                        size="middle"
                                        style={{
                                        display: 'flex',
                                        }}
                                    >
                                        <Button onClick={() => handleAcceder('iivvo')} type="primary" shape="round" block={true} className="acceder-btn">
                                            iiVVO
                                        </Button>
                                        <Button onClick={() => handleAcceder('psicometricos')} type="primary" shape="round" block={true} className="acceder-btn">
                                            Psicometricos
                                        </Button>
                                    </Space>
                                </div>
                    }
                </div>

                </Drawer>
            </div>
        </>
    )
}
