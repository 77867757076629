import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Switch, Row, Col, Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Text from 'antd/lib/typography/Text';

import { defaultValidateMessages } from '../../../helpers/validateMessages';
import { startNewUser, startEditUser } from '../../../actions/admin';

export const AdminModalUsuario = ({params, visible, setVisible, data, setData, initData, setUsuarios, setLoaded}) => {

    const dispatch = useDispatch();
    const {_id, nombre, apellidos, email} = data;
    const [passwordReq, setPasswordReq] = useState(false);
    
    useEffect(() => {
        if(_id !== ''){
            setPasswordReq(false);
        }else{
            setPasswordReq(true);
        }    
    }, [visible, _id])


    const handleAceptar = (data) => {
        if(_id !== ''){
            dispatch(startEditUser(params, _id, data, setUsuarios, passwordReq, setLoaded));
        }else{
            dispatch(startNewUser(params, data, setUsuarios, setLoaded));
        }
        setVisible(false);
        setPasswordReq(false);
        setData(initData);
    }
    
    const handleCancelar = () => {
        setVisible(false);
        setPasswordReq(false);
        setData(initData);
        // form.resetFields();
    }

    return (
        <>
            <Modal
                visible={visible}
                destroyOnClose={true}
                closable={false}
                footer={null}
            >
                {
                    (_id === '')
                        ? <h2 style={{color: 'var(--primary)'}}>Agregar Administrador</h2>
                        : <h2 style={{color: 'var(--primary)'}}>Editar Administrador</h2>
                }
                <Form
                    style={{paddingTop:'15px'}}
                    validateMessages={defaultValidateMessages}
                    onFinish={handleAceptar}
                    initialValues={{
                        nombre: nombre,
                        apellidos: apellidos,
                        email: email
                    }} 
                >
                    <Form.Item
                        name="nombre"
                        rules={[
                            {
                            required: true,
                            },
                        ]}
                    >
                        <Input 
                            placeholder="Nombre" 
                            autoComplete="off" 
                        />
                    </Form.Item>

                    <Form.Item
                        name="apellidos"
                        rules={[
                            {
                            required: true,
                            },
                        ]}
                    >
                        <Input 
                            placeholder="Apellidos" 
                            autoComplete="off" 
                        />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        rules={[
                            {
                            required: true,
                            type: 'email'
                            },
                        ]}
                    >
                        <Input 
                            placeholder="Correo" 
                            autoComplete="off"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                            required: passwordReq,
                            },
                        ]}
                    >
                        <Input
                            type="password"
                            placeholder="Password"
                            autoComplete="off"
                            disabled={!passwordReq}
                        />
                    </Form.Item>

                    {
                        (_id && 
                            (<Row>
                                <Col xs={5} md={3}>
                                    <Switch
                                        checked={passwordReq}
                                        onChange={() => {
                                            setPasswordReq(!passwordReq);
                                        }}
                                    />
                                </Col>
                                <Col  xs={19} md={21}>
                                    <Text type="secondary">Cambiar contraseña</Text>
                                </Col>
                            </Row>)
                        ) 
                    }
                    <div style={{display:'flex' ,width:'100%', justifyContent:'flex-end'}}>
                        <Form.Item>
                            <Button  onClick={() => handleCancelar()} >Cancelar</Button>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" style={{backgroundColor: 'var(--primary)', marginLeft: '5px'}}>Aceptar</Button>
                        </Form.Item>
                    </div>
                </Form> 

            </Modal>
        </>
    )
}

