import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row, Button } from 'antd'
import { StepForwardOutlined, StepBackwardOutlined } from '@ant-design/icons'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'
import Swal from 'sweetalert2'

import { evaluarActividad, progresoVideo } from '../../actions/usuarios'

import { VideoError } from './videoPlayer/VideoError'
import { VideoControls } from './videoPlayer/VideoControls'
import { VideoEndedTransition } from './videoPlayer/VideoEndedTransition'

export const UsuarioVideoScreen = ({actividad, current, setActividad, setIdAct, getActividad}) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const handleContinue=(_id_curso,id_act)=>{
        dispatch(evaluarActividad(_id_curso, id_act, actualizarUrl, setActividad, setIdAct));
    }

    //Función para actualizar ID de actividad actual en URL
    const actualizarUrl = (id) => {
        history.replace(`./${id}`);
    }

    const [playerState, setPlayerState] = useState({
        controls: false,
        duration: 0,
        enabledBackward: true,
        enabledForward: true,
        endedAction: false,
        endedActionCounter: 5,
        light: false,
        loaded: 0,
        loop: false,
        maxPlayed: 0,
        muted: false,
        pip: false,
        playbackRate: 1.0,
        played: 0,
        playerError: false,
        playing: true,
        progressInterval: 1000,
        savedProgress: actividad.data.progress,
        seeking: false,
        url: actividad.data.url,
        volume: 0.8,
        volumeHover: false
    });
    
    const { url, playing, controls, light, volume, muted, volumeHover, loop, played, maxPlayed, savedProgress, loaded, duration, playbackRate, pip, seeking, playerError, endedAction, endedActionCounter, enabledBackward, enabledForward } = playerState;

    const player = useRef();

    const handleProgress = progressState => {
        // We only want to update time slider if we are not currently seeking
        if (!seeking) {
            setPlayerState({ ...playerState, played: progressState.played, maxPlayed: (maxPlayed < progressState.played) ? progressState.played : maxPlayed, loaded: progressState.loaded, enabledForward: ((played + 0.01) <= maxPlayed) })
            let progress = Math.trunc(maxPlayed * 100)
            if(progress > savedProgress){
                setPlayerState({ ...playerState, savedProgress: progress })
                dispatch(progresoVideo(actividad.actividad._id, {progress}));
            }
        }
    }

    const handleDuration = (duration) => {
        let progress = parseFloat((duration / 100) * actividad.data.progress)
        if(actividad.data.progress === 100){
            player.current.seekTo(0)
            setPlayerState({ ...playerState, duration, maxPlayed:  1})
            if(endedAction === true){

            }
        }else{
            player.current.seekTo(progress)
            setPlayerState({ ...playerState, duration })
        }
    }

    const handlePlayPause = () => {
        setPlayerState({ ...playerState, playing: !playing })
    }

    const handleSeekMouseDown = e => {
        setPlayerState({ ...playerState, seeking: true })
    }
    
    const handleSeekChange = e => {
        if(e.target.value <= maxPlayed){
            setPlayerState({ ...playerState, played: parseFloat(e.target.value) })
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No es posible saltar partes del video, para acreditar el curso debes visualizarlo por completo.'
            })
        }
    }
    
    const handleSeekMouseUp = e => {
        if(e.target.value <= maxPlayed){
            setPlayerState({ ...playerState, seeking: false })
            player.current.seekTo(parseFloat(e.target.value))
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No es posible saltar partes del video, para acreditar el curso debes visualizarlo por completo.'
            })
        }
    }

    const handleBackward = () => {
        setPlayerState({ ...playerState, enabledBackward: false })
        let toSeek = parseFloat(played - 0.01);
        if(toSeek < 0){
            toSeek = 0;
        }
        let toPlayed = played - 0.01;
        if(toPlayed < 0){
            toPlayed = 0;
        }
        player.current.seekTo(toSeek)
        setPlayerState({ ...playerState, seeking: false, played: toPlayed, enabledBackward: true })
    }

    const handleForward = () => {
        setPlayerState({ ...playerState, enabledForward: false })
        let toSeek = parseFloat(played + 0.01);
        if(toSeek > 100){
            toSeek = 100;
        }
        let toPlayed = played + 0.01;
        if(toPlayed > 100){
            toPlayed = 100;
        }
        if(toSeek <= maxPlayed){
            player.current.seekTo(toSeek)
            setPlayerState({ ...playerState, seeking: false, played: toPlayed })
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No es posible saltar partes del video, para acreditar el curso debes visualizarlo por completo.'
            })
        }
    }

    const handleReset = () => {
        setPlayerState({ ...playerState, seeking: false, played: 0, playing: true, endedAction: false, endedActionCounter: 5 })
        player.current.seekTo(0)
    }

    const handleVolumeChange = e => {
        setPlayerState({ ...playerState, volume: parseFloat(e.target.value) })
    }
    
    const handleToggleMuted = () => {
        setPlayerState({ ...playerState, muted: !muted })
    }

    const handleVolumeHover = () => {
        setPlayerState({ ...playerState, volumeHover: true })
    }

    const handleVolumeNotHover = () => {
        setPlayerState({ ...playerState, volumeHover: false })
    }

    const handleVideoError = () => {
        setPlayerState({ ...playerState, playerError: true })
    }

    const handleVideoEnded = () => {
        dispatch(progresoVideo(actividad.actividad._id, {progress: 100}))
        setPlayerState({ ...playerState, endedAction: true, endedActionCounter: 5, played: 1, playing:false, maxPlayed: 1, savedProgress: 100 })
    }

    useEffect(() => {
        let myInterval = null;

        if(endedAction === true){
            myInterval = setInterval(function(){
                if(endedAction === true){
                    if(endedActionCounter === 0){
                        clearInterval(myInterval);
                        handleContinue(current.curso._id, actividad.actividad._id);
                    }else{
                        setPlayerState({ ...playerState, endedActionCounter: endedActionCounter - 1 })
                    }
                }else{
                    clearInterval(myInterval);
                }
            }, 1000);
        }
    
        return () => {
            clearInterval(myInterval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endedAction, endedActionCounter])

    const handlePreventContinue = () => {
        setPlayerState({ ...playerState, endedAction: false, endedActionCounter: 5 })
    }

    const handleClickFullscreen = () => {
        screenfull.request(document.querySelector('.react-player'))
    }

    const handlePlay = () => {
        setPlayerState({ ...playerState, playing: true })
    }

    const handlePause = () => {
        setPlayerState({ ...playerState, playing: false })
    }
    
    return (
        <>
        <Row justify='center' style={{paddingRight: '3%', paddingLeft: '3%', overflow: 'hidden'}} className="viewMakeCourse-actividad-content">
            <Col md={24} style={{position: 'relative'}}>
                {(!playerError)
                ? <>
                    <ReactPlayer
                        ref={player}
                        className='react-player'
                        width='100%'
                        height='100%'
                        url={url}
                        pip={pip}
                        playing={playing}
                        controls={controls}
                        light={light}
                        loop={loop}
                        playbackRate={playbackRate}
                        volume={volume}
                        muted={muted}
                        onPlay={handlePlay}
                        onPause={handlePause}
                        onProgress={handleProgress}
                        onDuration={handleDuration}
                        onEnded={handleVideoEnded}
                        onError={handleVideoError}
                    />
                    {(endedAction)
                    && <VideoEndedTransition
                        endedActionCounter={endedActionCounter}
                        handlePreventContinue={handlePreventContinue}
                        />
                    }
                    <VideoControls
                        duration={duration}
                        enabledBackward={enabledBackward}
                        enabledForward={enabledForward}
                        endedAction={endedAction}
                        loaded={loaded}
                        muted={muted}
                        played={played}
                        playing={playing}
                        volume={volume}
                        volumeHover={volumeHover}
                        handlePlayPause={handlePlayPause}
                        handleSeekMouseDown={handleSeekMouseDown}
                        handleSeekChange={handleSeekChange}
                        handleSeekMouseUp={handleSeekMouseUp}
                        handleBackward={handleBackward}
                        handleForward={handleForward}
                        handleReset={handleReset}
                        handleVolumeChange={handleVolumeChange}
                        handleToggleMuted={handleToggleMuted}
                        handleVolumeHover={handleVolumeHover}
                        handleVolumeNotHover={handleVolumeNotHover}
                        handleClickFullscreen={handleClickFullscreen}
                    />
                </>
                : <VideoError />}
            </Col>
        </Row>
        {(!playerError)
        && <>
            <div className='viewMakeCourse-activities-buttons'> 
                <div className='d-flex'>
                    {(actividad.actividad.prev != null) &&
                    <Button className='usuarioTextScreen-btn-prev' onClick={()=>getActividad(true, actividad.actividad.prev, true)}>
                        <StepBackwardOutlined />
                    </Button>
                    }
                    <Button className='usuarioTextScreen-btn-next' onClick={()=>handleContinue(current.curso._id,actividad.actividad._id)} disabled={!(endedAction || maxPlayed === 1)}>
                        <StepForwardOutlined />
                        Continuar
                    </Button>
                </div>
            </div>
        </>}
        </>
    )
}
