import React from 'react';
import {Form, Input, Button} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { startLogin } from '../../actions/auth';
import { defaultValidateMessages } from '../../helpers/validateMessages';
import { Link } from 'react-router-dom';

export const LoginScreen = ({changeAuth, closeDrawer}) => {

    const dispatch = useDispatch();

    const cambioAuth = () => {
      changeAuth();
    }

    const handleLogin = (datos) => {
      dispatch( startLogin(datos, closeDrawer));
    }

    const handleCloseDrawer = () => {
      closeDrawer();
    }

    return (
        <div>
          <Form
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={handleLogin}
            validateMessages={defaultValidateMessages}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email'
                },
              ]}
            >
              <Input 
                prefix={<UserOutlined style={{color: 'var(--primary)'}} className="site-form-item-icon" />} 
                placeholder="Correo" 
                autoComplete="off"
              />

            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                prefix={<LockOutlined style={{color: 'var(--primary)'}} className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item>
              <Link to="/sol-password">
                <Button type="link" style={{color: 'black'}} onClick={handleCloseDrawer}>
                  ¿Olvidaste tu contraseña?
                </Button>
              </Link>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button btn-login">
                Log in
              </Button>
              <Button style={{color: 'var(--primary)' }} type="link" onClick={()=>{cambioAuth()}}>
                Ó regístrate
              </Button>
            </Form.Item>
          </Form>
          
        </div>
    )
}

//Comienzo Recuperacion Contraseña
