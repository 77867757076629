import { DeleteFilled } from '@ant-design/icons'
import { Button, Card, Col, Modal, Row } from 'antd'

import JoditEditor from 'jodit-react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { crearRecurso, eliminarRecurso } from '../../actions/maestros'
import Swal from 'sweetalert2';
import TextArea from 'antd/lib/input/TextArea'

export const DocenteTextScreen = ({_id, actividad, statusCurso, recursos, setEditado, content, setContent, updateActividadData, setUpdateActividadData}) => {

    const dispatch = useDispatch();
    const [recursosState, setRecursos] = useState(recursos);

	const config = {
		readonly: false,
        removeButtons:['source']
	}

    const contenido = actividad.data.text;
    
    useEffect(() => {
        setContent(actividad.data.text)
    }, [setContent, actividad.data.text])


    const processFile=(e)=>{
        const recurso=e.target.files[0];
        setFile(recurso);
    }

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const [file, setFile] = useState();
    
    const handleAddRecurso=()=>{
        //Confirmacion de archivo seleccionado
        if(file !== undefined){
            //Validación de extencion de archivo
            let ext = file.name.split('.')[1];
            console.log('ext', ext);
            if(ext === "pdf" || ext === "doc" || ext === "docx" || ext === "xls" || ext === "xlsx" || ext === "ppt" || ext === "pptx" || ext === "png" || ext === "jpg" || ext === "jpeg"){
                Swal.fire({
                    icon: 'info',
                    title: 'Cargando archivo',
                    text: 'Espera un momento...',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: 'var(--primary)'
                });
                dispatch(crearRecurso(_id,file,setRecursos,Swal));
                setIsModalVisible(false);
            }else{
                Swal.fire({
                    icon: 'info',
                    title: 'Archivo invalido',
                    text: 'Los archivos validos son pdf, doc, docx, xls, xlsx, ppt, pptx, png, jpg ó jpeg',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: 'var(--primary)'
                });
            }
        }else{
            Swal.fire({
                icon: 'info',
                title: 'Archivo requerido',
                text: 'Selecciona un archivo para añadir como recurso',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: 'var(--primary)'
            });
        }
    }
    const handleDelete=(val)=>{
        Swal.fire({
            icon: 'info',
            title: 'Eliminando recurso',
            text: 'Espera un momento...',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: 'var(--primary)'
        });
        dispatch(eliminarRecurso(_id,val,setRecursos,recursosState,Swal))//val es el segundo id
    }

    const handleChangeTitle = ({  target }) => {
        setUpdateActividadData({
            ...updateActividadData,
            titulo: target.value
        });
        setEditado(true);
    }

    const handleChangeDescripcion = ({  target }) => {
        setUpdateActividadData({
            ...updateActividadData,
            descripcion: target.value
        });
        setEditado(true);
    }

    //Si está productivo
    if(actividad.end === 1 || actividad.curso.status === 2){
        return (
            <>
            <div style={{backgroundColor: 'white', padding: '10px 30px'}}>
                <Row>
                    <Col span={24}>
                        <div style={{width: '100%', textAlign: 'center'}}>
                            <p style={{fontSize: '30px', color: '#80837C'}}>{actividad.nombre}</p>
                        </div> 
                    </Col>

                    <Col span={24}>
                        <div style={{width: '100%', color: '#80837C'}}>
                            {actividad.descripcion}
                        </div>
                    </Col>
                </Row>
                <Row style={{marginTop: '20px'}}>
                    <Col span={24}>
                        <div style={{width: '100%'}} dangerouslySetInnerHTML={{__html: contenido}}></div>
                    </Col>
                    <Col span={24}>
                        <h4>Recursos</h4>
                    </Col>
                    <Col span={24}>
                    {
                        recursosState.map(element=>{
                            return(
                            
                                <div key={element._id} style={{height:'40px' ,backgroundColor:'#f2f2f2',border:'2px solid #f2f2f2', marginTop: '5px'}}>
                                    
                                    <Row style={{height: '100%'}}>
                                        <Col span={24} > 
                                            <div  style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <a href={element.recurso_url} className='recurso-a'>{element.recurso_nombre}</a>
                                            </div>
                                        </Col>
                                    </Row>       
                                </div>
                            )
                        })
                    }
                </Col>
                </Row>
            </div>
            </>
        )
    }
    //Si está en desarrollo
    else{
        return (
            <>
                <Row style={{backgroundColor: 'white', padding: '10px', border: '1px solid #d9d9d9'}}>
                    <Col span={24}>
                        <h4>Titulo</h4>
                        <TextArea 
                            id="nombre" 
                            autoSize={true} 
                            className="input-titulo" 
                            style={{display: 'block', color: '#80837C'}} 
                            placeholder='Titulo de actividad'
                            value={updateActividadData.titulo}
                            onChange={handleChangeTitle}
                        />
                    </Col>

                    <Col span={24} style={{marginTop: '20px'}}>
                        <h4>Descripción</h4>
                        <TextArea 
                            id='descripcion'  
                            style={{height: '200px', resize: 'none',color:'#80837C'}}  
                            autoSize={false} 
                            placeholder='Descripción de actividad'
                            value={updateActividadData.descripcion}
                            onChange={handleChangeDescripcion}
                        />
                    </Col>
                </Row>
                <Row style={{backgroundColor: 'white', padding: '10px', marginTop: '20px', border: '1px solid #d9d9d9'}}>
                    <Col span={24}>
                        <h4>Contenido</h4>
                        <div style={{marginBottom: '10px'}} >
                            <JoditEditor
                                value={content}
                                config={config}
                                tabIndex={1} 
                                onBlur={newContent => setContent(newContent)} 
                                onChange={() => setEditado(true)}
                            />
                        </div>
                    </Col>
                </Row>

                <Row style={{backgroundColor: 'white', padding: '10px', marginTop: '20px', border: '1px solid #d9d9d9'}}>
                    <Col span={24}>
                        <h4>Recursos</h4>
                    </Col>
                    <Col span={24}>
                        {
                            recursosState.map(element=>{
                                return(
                                
                                    <div key={element._id} style={{height:'40px' ,backgroundColor:'#f2f2f2',border:'2px solid #f2f2f2', marginTop: '5px'}}>
                                        
                                        <Row style={{height: '100%'}}>
                                            <Col span={23} > 
                                                <div  style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                    <a href={element.recurso_url} className='recurso-a'>{element.recurso_nombre}</a>
                                                </div>
                                            </Col>

                                            <Col span={1}>
                                                <div  style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                    <DeleteFilled  onClick={()=>{handleDelete(element._id)}} style={{ fontSize: '1.5em',color:'#767871'}}/>
                                                </div>
                                                
                                            </Col>    
                                        </Row>       
                                    </div>
                                )
                            })
                        }
                    </Col>
                    <Col span={24}>
                    
                        <Card style={{height:75,margin:'0 auto',display:'flex', alignItems:'center',justifyContent:'center',border:'none'}}>
                            <Button className='btn-link-agregar' type='link' onClick={showModal}  style={{color:'grey'}}>
                        
                                + Agregar recurso
                            </Button>
                        </Card>
                    </Col>
                </Row>

                <Modal title="Añade un nuevo recurso" style={{textAlign:'center'}}  visible={isModalVisible} footer={null} onCancel={handleCancel}>
                    <input type="file" onChange={processFile} name='recurso'></input>

                    <Button onClick={handleAddRecurso} type="primary" htmlType="submit" style={{display:'flex',alignItems:'center',justifyContent:'center',margin:'0 auto',width:'100%',backgroundColor:'var(--primary)',border:'none',marginTop:'7px',fontSize:'1.2em'}}>
                        Añadir recurso
                    </Button>
                </Modal> 
            </>
        )
    }
}
