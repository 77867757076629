import React from 'react'
import { Col, Row, Button } from 'antd'
import { evaluarActividad } from '../../actions/usuarios';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StepForwardOutlined, StepBackwardOutlined } from '@ant-design/icons'

export const UsuarioTextScreen = ({actividad, current, setActividad, setIdAct, getActividad}) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const handleContinue=(_id_curso,id_act)=>{
        dispatch(evaluarActividad(_id_curso, id_act, actualizarUrl, setActividad, setIdAct));
    }

    //Función para actualizar ID de actividad actual en URL
    const actualizarUrl = (id) => {
        history.replace(`./${id}`);
    }

    return (
        <>
        <Row justify='center' style={{paddingRight: '3%', paddingLeft: '3%'}} className="viewMakeCourse-actividad-content">
            <Col md={24} style={{backgroundColor: '#1b2a49'}}>
                <div className='usuarioTextScreen-content'>
                    <div dangerouslySetInnerHTML={{__html: actividad.data.text}}></div>
                </div>
            </Col>

        </Row>
        <div className='viewMakeCourse-activities-buttons'> 
            <div className='d-flex'>
                {(actividad.actividad.prev != null) &&
                <Button className='usuarioTextScreen-btn-prev' onClick={()=>getActividad(true, actividad.actividad.prev, true)}>
                    <StepBackwardOutlined />
                </Button>
                }
                <Button className='usuarioTextScreen-btn-next' onClick={()=>handleContinue(current.curso._id,actividad.actividad._id)}>
                    <StepForwardOutlined />
                    Continuar
                </Button>
            </div>
        </div>
        </>
    )
}
