import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getCurso, getParticipantes, getValoraciones } from '../../actions/admin';
import { Rate, Row, Col, Tag, Collapse, Table, Pagination, Form, Button, Input } from 'antd';
import { UserOutlined, LeftOutlined, FileTextFilled, QuestionCircleFilled, MessageFilled, TagsOutlined, SearchOutlined } from '@ant-design/icons';
import { SkeletonAdminCursoScreen } from './UI/SkeletonAdminCursoScreen';
import { CRUDTableSkeleton } from './UI/CRUDTableSkeleton';
import { AdminValoracion } from './UI/AdminValoracion';

export const AdminCursoScreen = () => {
    const dispatch = useDispatch();
    const {_id} = useParams();
    const {Panel} = Collapse;
    const [bandera, setBandera] = useState(false);
    
    //Sección curso
    const [loadingCurso, setLoadingCurso] = useState(true);
    const[temas, setTemas] = useState([]);
    const [curso, setCurso] = useState({
        nombre: '',
        iniciales: '',
        maestro: {
            nombre: '',
            apellidos: '',
            img: ''
        },
        caracteristicas: {
            textos: '',
            quizzes: '',
            foros: '',
            categorias: []
        }
    });
    const {maestro} = curso;
    const {caracteristicas} = curso;

    useEffect(() => {
        dispatch(getCurso(_id, setCurso, setTemas, setLoadingCurso));
    }, [dispatch, _id, setCurso, setTemas]);


    //Sección participantes
    const [loadingParticipantes, setLoadingParticipantes] = useState(true);
    const[participantes, setParticipantes] = useState({
        items: [],
        total: 8
    });
    const [paramsParticipantes, setParamsParticipantes] = useState({
        pagina: 1,
        search: '',
        limit: 8
    });
    const columns = [
        {
            title:'Nombre', 
            dataIndex:'nombre', 
            key:'nombre'
        },
        {
            title:'Apellidos',
            dataIndex:'apellidos',
            key:'apellidos',
        },
        {
            title:'Email',
            dataIndex:'email',
            key:'email',
            responsive: ['md']
        }
    ];

    const cambioPaginaParticipantes = (pagina) => {
        setParamsParticipantes({
            ...paramsParticipantes,
            pag: pagina
        });
    }
    const handleBuscar = (search) => {
        setParamsParticipantes({
            ...paramsParticipantes,
            pagina: 1,
            search: search.value
        })
    }
    const handleBuscando = () => {
        setLoadingParticipantes(true);
    }

    useEffect(() => {
        dispatch(getParticipantes(_id, setParticipantes, setLoadingParticipantes, paramsParticipantes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_id, paramsParticipantes]);

    //Sección Valoraciones
    const [loadingValoraciones, setLoadingValoraciones] = useState(false);
    const[valoraciones, setValoraciones] = useState({
        items: [],
        total: 8
    });
    const [paramsValoraciones, setParamsValoraciones] = useState({
        pagina: 1,
        search: '',
        limit: 8
    });

    const cambioPaginaValoraciones = (pagina) => {
        setParamsValoraciones({
            ...paramsValoraciones,
            pag: pagina
        });
    }

    useEffect(() => {
        dispatch(getValoraciones(_id, setValoraciones, setLoadingValoraciones, paramsValoraciones));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_id, paramsValoraciones]);

    return (
    <>
        {(loadingCurso) ?
            (<>
                <SkeletonAdminCursoScreen />
            </>)
        :
        (   
            <>
                <Link to="/admin/cursos">
                    <div className="btn-atras"><LeftOutlined className="icon-atras" />  atrás</div>
                </Link>
                <div className="admin-curso-card">
                <Row className="row-padding" justify="space-between">
                    <div className="admin-curso-title">{curso.nombre}</div>
                    <Row className="row-padding admin-curso-side" justify="center" align="center">
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img className="admin-curso-maestro-img" src={maestro.img} alt=""/>
                            <div className="admin-curso-side-item">
                                <h5 className="admin-curso-title-side">Maestro</h5>
                                <div>{maestro.nombre +  ' ' + maestro.apellidos}</div>
                            </div>
                        </div>
                        <div className="admin-curso-side-item">
                            <h5 className="admin-curso-title-side">Calificación</h5>
                            <Rate disabled allowHalf defaultValue={curso.rate} style={{fontSize: '15px', color: 'orange', margin: '0px 5px 0px 5px'}} />
                            <span>({curso.no_evaluaciones})</span>
                        </div>
                        <div className="admin-curso-side-item">
                            <h5 className="admin-curso-title-side">Participantes</h5>
                            <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center'}} >
                                <UserOutlined style={{fontSize: '25px'}}/>
                                <h3>{curso.no_participantes}</h3>
                            </div>
                        </div>
                    </Row>
                    
                    <Row className="admin-curso-responisve" style={{flexDirection: 'column'}}>
                        <div className="admin-curso-responsive-item">Maestro: {maestro.nombre +  ' ' + maestro.apellidos}</div>
                        <div style={{display: 'flex'}}>
                            <div>Calificación: </div>
                            <Rate disabled allowHalf defaultValue={curso.rate} style={{fontSize: '15px', color: 'orange', margin: '0px 5px 0px 5px'}} />
                            <span>({curso.no_evaluaciones})</span>
                        </div>
                        
                        <div className="admin-curso-responsive-item">Participantes: {curso.no_participantes}</div>
                    </Row>
                </Row>

                <Row gutter={[16, 24]} className="row-padding">
                    <Col xs={24} lg={6}>
                        <div style={{backgroundImage: 'url(' + curso.img + ')'}} className="admin-curso-img"/>
                    </Col>
                    <Col xs={24} lg={18}>
                        <div className="admin-curso-outline">
                            <div className="admin-curso-subtitle">Descripción</div>
                            <div>{curso.descripcion}</div>
                        </div>
                    </Col>
                </Row>

                <Row gutter={[16, 24]} className="row-padding">
                    <Col xs={24} lg={6}>
                        <div className= "admin-curso-outline" style={{boxShadow: '2px 2px 0px #ddd'}}>
                            <div className="admin-curso-subtitle" style={{textAlign: 'center'}}>Características</div>
                            <div>
                                <div className="admin-curso-caracteristica">
                                    <div style={{display: 'flex', alignItems: 'center'}}><FileTextFilled style={{ fontSize: '1.4em', color: 'var(--primary)', marginRight: '5px' }} />Textos</div>
                                    <div>{caracteristicas.textos}</div>
                                </div>
                                <div className="admin-curso-caracteristica">
                                    <div style={{display: 'flex', alignItems: 'center'}}><QuestionCircleFilled style={{ fontSize: '1.4em', color: 'var(--primary)', marginRight: '5px'}}/>Quizzes</div>
                                    <div>{caracteristicas.quizzes}</div>
                                </div>
                                <div className="admin-curso-caracteristica">
                                    <div style={{display: 'flex', alignItems: 'center'}}><MessageFilled style={{ fontSize: '1.4em', color: 'var(--primary)', marginRight: '5px'}}/>Foros</div>
                                    <div>{caracteristicas.foros}</div>
                                </div>
                                <div className="admin-curso-caracteristica" style={{borderBottom: 'none !important'}}>
                                    <div style={{display: 'flex', alignItems: 'center'}}><TagsOutlined style={{ fontSize: '1.4em', color: 'var(--primary)', marginRight: '5px'}} />Categorias</div>
                                </div>
                                <div className="admin-curso-categorias">
                                {
                                    caracteristicas.categorias.map((categoria) => {
                                        return(
                                            <Tag 
                                                key={categoria._id} 
                                                id={categoria._id}
                                                className="admin-curso-tag"
                                            >
                                                {categoria.nombre}
                                            </Tag>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} lg={18}>
                        <div className="admin-curso-subtitle" style={{textAlign: 'center'}}>Contenido</div>

                        <div>
                            {
                                (temas.length > 0) ?
                                (
                                    temas.map ((tema) => {
                                        return(
                                            <Collapse
                                                destroyInactivePanel	
                                                expandIconPosition="left"
                                                accordion
                                                key={tema._id}
                                            >
                                                <Panel
                                                    bodyStyle={{padding: '0'}}
                                                    header={tema.nombre}
                                                    key={tema._id}
                                                >
                                                        {(tema.actividades.length > 0)?
                                                        (tema.actividades.map((act) => {
                                                            return(
                                                                <div 
                                                                key={act._id}
                                                                className="admin-curso-act">
                                                                    <div>{act.nombre}</div>
                                                                    {(act.tipo === 1) ?
                                                                    <FileTextFilled style={{ fontSize: '1.2em', color: 'grey' }} /> : <QuestionCircleFilled style={{ fontSize: '1em', color: 'grey'}} />}
                                                                </div>
                                                            )})
                                                        ) :
                                                        <div className="admin-curso-act">No hay temas disponibles</div>
                                                        }
                                                </Panel>
                                            </Collapse>
                                        )  
                                    })
                                ) : (
                                    <div style={{textAlign: 'center'}} className="no-disponible">No hay contenido disponible</div>
                                )
                            }
                        </div>
                    </Col>
                </Row>
                </div>
            </>
        )}
        <div className="admin-curso-card">
                <div className="centrar" style={{justifyContent: "space-around"}}>
                    <Button onClick={() => setBandera(true)} type="text" className={`admin-curso-subtitle-2 ${(bandera)? "admin-curso-subtitle-2-selected" : ""}`}>Valoraciones</Button>
                    <Button onClick={() => setBandera(false)}  type="text" className={`admin-curso-subtitle-2 ${(!bandera) ? "admin-curso-subtitle-2-selected" : ""}`}>Participantes</Button>
                </div>
                {(!bandera) ?
                    (
                        <div style={{margin: '20px'}}>
                            <Form onFinish={handleBuscar} onValuesChange={handleBuscando}>
                                <Form.Item name="value">
                                    <Input className="input-buscador" placeholder="Buscar" prefix={<Button type="text" htmlType="submit" style={{padding: '5px !important'}}><SearchOutlined className="search-icon" /></Button>}/>
                                </Form.Item>
                            </Form>

                            {(loadingParticipantes) ? 
                                (
                                    <CRUDTableSkeleton columns={columns} loading={loadingParticipantes} />
                                ) : 
                                (
                                    <Table dataSource={participantes.items}
                                    pagination={false} columns={columns} />
                                )
                            }

                            <div style={{width: '100%', textAlign: 'center', marginTop: '15px'}}>
                                <Pagination onChange={cambioPaginaParticipantes} pageSize={paramsParticipantes.limit} total={participantes.total} responsive={true} />
                            </div>

                        </div>
                    ) :
                    (   <>
                            {(loadingValoraciones) ? 
                                (
                                    <CRUDTableSkeleton columns={columns} loading={loadingParticipantes} />
                                ) : 
                                (
                                    valoraciones.items.map((valoracion) => {
                                        return (
                                            <AdminValoracion 
                                                valoracion={valoracion} 
                                                key={valoracion._id} 
                                            />
                                        )
                                    })
                                )}
                            

                            <div style={{width: '100%', textAlign: 'center', marginTop: '15px'}}>
                                <Pagination onChange={cambioPaginaValoraciones} pageSize={paramsValoraciones.limit} total={valoraciones.total} responsive={true} />
                            </div>

                        </>
                    )
                }
            </div>
    </>
    )
}
