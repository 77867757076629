import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Image, Input, Popover, Button, Form, Divider, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { defaultValidateMessages } from '../../helpers/validateMessages';
import { responderPregunta } from '../../actions/maestros';

export const Pregunta = ({info, eliminarPregunta}) => {
    const dispatch = useDispatch();
    const {_id, usuario:{img, nombre, apellidos}, texto, respuesta:resp} = info;
    const { confirm } = Modal;
    const [editar, setEditar] = useState(false);
    const [respuesta, setRespuesta] = useState(resp);

    const handleEditarAceptar = (data) => {
        dispatch(responderPregunta(_id, data, setRespuesta));
        setEditar(false);
    }

    const handleEditar = () => {
        setEditar(true);
    }

    const handleCancelar = () => {
        setEditar(false);
    }

    const showDeleteConfirm = () =>  {
        confirm({
          title: '¿Seguro que desea eliminar la pregunta?',
          icon: <ExclamationCircleOutlined />,
          okText: 'Aceptar',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk() {
            // console.log('OK');
            eliminarPregunta();
          },
          onCancel() {
            // console.log('Cancel');
          },
        });
    }

    return (
        <div className="pregunta-card">
            <Popover content='Eliminar pregunta'>
                <Button type="text" style={{float: 'right'}} onClick={() => showDeleteConfirm()}>
                    <DeleteOutlined className="pregunta-icon" />
                </Button>
            </Popover>
            <div className="pregunta-desktop">
                <Image src={img}  className="pregunta-img-usuario" preview={false}/>
                <div style={{marginLeft: '20px', display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
                    <div className="pregunta-usuario">{nombre + apellidos}</div>
                    <div className="pregunta-texto">{texto}</div>
                </div>
            </div>
            <div className="pregunta-movil">
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Image src={img}  className="pregunta-img-usuario" preview={false}/>
                    <div className="pregunta-usuario-movil">{nombre + apellidos}</div>
                </div>
                <div className="pregunta-texto-movil">{texto}</div>
            </div>
            <Divider style={{margin: '12px 0px !important'}} />
            <div> 
                <p style={{fontStyle: 'italic'}}>Respuesta </p>
                {
                    (editar === false && respuesta !== '') 
                    ? (
                        <>
                            {respuesta} 
                            <Popover content='Editar respuesta'>
                                <Button type="text" style={{float: 'right'}} onClick={handleEditar}>
                                    <EditOutlined className="pregunta-icon" />
                                </Button>
                            </Popover>
                        </>
                    )
                    : (
                        <Form 
                            style={{paddingTop:'15px', display: 'flex'}}
                            validateMessages={defaultValidateMessages}
                            onFinish={handleEditarAceptar}
                            initialValues={{
                                "respuesta": respuesta
                            }}
                        >
                            <Form.Item
                                name="respuesta"
                                rules={[{required: true}]}
                                style={{width: '100%', marginRight: '10px'}}
                            >
                                <Input placeholder='Responder' />
                            </Form.Item>
                            <Form.Item>
                                <Button htmlType="submit" style={{marginRight: '10px'}}>Aceptar</Button>
                            </Form.Item>
                            <Button  style={{float: 'right'}} onClick={handleCancelar}>Cancelar</Button>
                        </Form>
                    )
                }
            </div>
        </div>
    )
}
