import React, { useEffect, useState } from 'react'
import { Col, Rate, Row, Button, Modal, Input, Form, Layout, Image, Progress } from 'antd';
import { useParams, useHistory } from 'react-router';
// eslint-disable-next-line no-unused-vars
import { courseTracker, resetCurso, currentActivity, calificarActividad, viewActividad, getPreguntas, preguntar } from '../../actions/usuarios';
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined, TeamOutlined, FlagFilled, RightOutlined, ReloadOutlined, TrophyFilled } from '@ant-design/icons';
import { defaultValidateMessages } from '../../helpers/validateMessages';
import Swal from 'sweetalert2';
import { ViewMakeCourseActividad } from './ViewMakeCourseActividad';
import { ViewMakeCourseEnd } from './ViewMakeCourseEnd';

export const ViewMakeCourse = () => {
    const dispatch = useDispatch();  
    const history = useHistory();
    const [form] = Form.useForm();

    const { tracker } = useSelector(state => state.usuarios.courseTracker);
    const { current } = useSelector(state => state.usuarios);
    const { maestro: docente } = useSelector(state => state.usuarios.cursoUsuario.curso);
    const { preguntas_y_respuestas: preguntasRespuestas } = useSelector(state => state.usuarios.cursoUsuario.curso);
    const { _id, idact } = useParams();

    const [preguntas, setPreguntas] = useState({
        pregunta: undefined,
        total: 5
    });

    // eslint-disable-next-line no-unused-vars
    const [statusCargaVista, setStatusCargaVista] = useState(false);
    
    //Actividad
    const [actividad, setActividad] = useState();
    const [idAct, setIdAct] = useState(idact);

    //Sider
    const { Sider, Content } = Layout;
    const [collapsed, setCollapsed] = useState(false);
 
    useEffect(() => {
        window.scrollTo(0,0)
        dispatch(currentActivity(_id, setStatusCargaVista))
        dispatch(courseTracker(_id))
        if(preguntasRespuestas){
          dispatch(getPreguntas(_id, setPreguntas));
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [dispatch]);

    const handleCertificado=(id_curso)=>{
        const token = localStorage.getItem('token') || '';
        const  myHeaders = new Headers();
        myHeaders.append("x-token", token)

        const  requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

      fetch(`https://apibcoursera.iivvo.com/api/curso/cert/${id_curso}`, requestOptions)
        .then(result => {
          if (result.status !== 200){
            throw new Error("Error de servidor");
          }

          return result.blob();
        })
        .then(data => {
          
          const url = window.URL.createObjectURL(data);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = 'Certificado';
          anchor.click();
  
          // CLEAN UP
          window.URL.revokeObjectURL(url);
          document.removeChild(anchor);
        })
        .catch(error => console.log('error', error));
    }
    const handleResetCurso = (trak) => {
      Swal.fire({
        title: '¿Deseas resetear el progreso del Curso?',
        text: 'Perderas todo tu progreso si lo haces',
        showDenyButton: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: 'var(--primary)',
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          dispatch(resetCurso(_id,trak[0].actividades[0]._id,setActividad,setStatusCargaVista,setIdAct));
        }
      })
    }
    const handleForum=(uid)=>{
      history.push(`/user/foro/${uid}`)
    }

    //Hacer preguntas
    const handlePreguntar = (data) => {
      dispatch(preguntar(_id, data.texto, setPreguntas));
      form.resetFields();
    }

    //Evaluar si el tema ha sido completado
    const evaluarTemaCompletado = (actividades) => {
      var status;
      actividades.forEach(actividad => {
        if(!actividad.status) status = false;
        else status = true;
      });
      return status;
    }

    //Cambio de actividad con sider
    const getActividad = (status, id, status_anterior) => {
      if(status || status_anterior){
        // dispatch(viewActividad(id, setActividad, setIdAct));
        // setIdAct(id);
        history.replace(`./${id}`);
      }
    }

    //Modal para evaluar curso
    const { TextArea } = Input;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleEvaluate=(data)=>{
      dispatch(calificarActividad(actividad.curso._id,data.rate,data.rate_comment,setStatusCargaVista))
      setIsModalVisible(false); 
    }

    const css = `
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
        background: #1b2a49;
    }
    ::-webkit-scrollbar-thumb {
        background: #32a2ff;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #007BE0; 
    }
    .header{
      box-shadow: none !important;
    }
    .index-cursos-usuario {
      background-color: #0f172f !important;
    }`;

    return (
      <>
      <style>{css}</style>
      <Layout>
        {(actividad != null) && 
        <Sider
          collapsible
          collapsed={collapsed} 
          trigger={null}
          collapsedWidth={44}
          style={{height: 'calc(100vh - 60px)', backgroundColor: '#1b2a49', overflow: 'auto', position: 'sticky', top: '0'}}
          width={300}
        >
          <div style={{display: 'flex'}}>
            <Button onClick={() => setCollapsed(!collapsed)} className='viewMakeCourse-sider-btn'>
              {(collapsed) 
              ? <RightOutlined />
              : <LeftOutlined />}
            </Button>
            <div className='viewMakeCourse-sider-titulo'>Contenido del curso</div>
          </div>
          {(tracker !== undefined) &&
              tracker.map((tema, key) => (
                <div key={key}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{color: `${(evaluarTemaCompletado(tema.actividades)) ? 'var(--primary)' : 'rgb(82 103 140)' }`, marginLeft: '16px', paddingRight: '4px'}}><FlagFilled /></div>
                    <div>{evaluarTemaCompletado(tema.actividades)}</div>
                    <div style={{color: '#fff'}} className='viewMakeCourse-tema viewMakeCourse-tema-cursor'>{tema.nombre}</div>
                    {(tema.foro) && (<div style={{color: 'rgb(82 103 140)', marginRight: '5px', paddingRight: '4px'}}><TeamOutlined title="Ir al foro"  style={{fontSize: '17px'}} onClick={()=>handleForum(tema._id)} /></div>)}
                  </div>
                  {
                  tema.actividades.map((actividad, n) => (
                    <div style={{display: 'flex', alignItems: 'center'}} onClick={() => getActividad(actividad.status, actividad._id, ((n > 0 && tema.actividades[n-1].status) || (key > 0 && n === 0 && tracker[key-1].actividades[0].status)) ? ((n !== 0) ? tema.actividades[n-1].status : true) : false)} key={n} className={((actividad.status || (n > 0 && tema.actividades[n-1].status) || (key > 0 && n === 0 && tracker[key-1].actividades[0].status)) ? `viewMakeCourse-act viewMakeCourse-actividad` : 'viewMakeCourse-act') + (((n > 0 && tema.actividades[n-1].status) || (key > 0 && n === 0 && tracker[key-1].actividades[0].status) || (key === 0 && n === 0)) ? '' : ' viewMakeCourse-actividad-disabled')}>
                      <div className={`viewMakeCourse-numero ${(actividad.status) && `viewMakeCourse-numero-done`} ${(actividad._id === idAct) && `viewMakeCourse-numero-actual` } `}><span>{n+1}</span></div>
                      <div className='viewMakeCourse-tema' >{actividad.nombre}</div>
                    </div>
                  ))
                  }
                </div>
              ))
          }
        </Sider>
        }
        <Content>
          <Row>
              
            {/*Actividad*/}
            {(current.endView !== undefined && current.endView)
            ? (
              <ViewMakeCourseEnd
                current={current}
                handleCertificado={handleCertificado}
                setStatusCargaVista={setStatusCargaVista}
              />
            )
            : (
              <ViewMakeCourseActividad 
                id={idact}
                actividad={actividad}
                setActividad={setActividad}
                current={current}
                docente={docente}
                setStatusCargaVista={setStatusCargaVista}
                setIdAct={setIdAct}
                showModal={showModal}
                getActividad={getActividad}
              />
            )
            }

              
            {/*Progreso, Valoración y Preguntas*/}
            {(actividad != null) && 
            <Col md={6}>
              <Row>
                {/* Progreso */}
                <Col md={24}>
                  <div className='viewMakeCourse-progress-div'>
                    <h5 className='viewMakeCourse-progress-title'>Progreso del curso</h5>
                    <div className='viewMakeCourse-progress-container'>
                      <Progress
                        type="circle"
                        strokeColor='var(--primary)'
                        trailColor='#1b2a49'
                        percent={parseInt(current.progress_percentaje)}
                        width={100}
                        format={()=>(current.progress_percentaje==="100") ? <TrophyFilled onClick={()=>handleCertificado(current.curso._id)} style={{color: 'var(--primary)'}}/> : <TrophyFilled style={{color: '#1b2a49'}}/>}
                      />
                    </div>
                    <div className='viewMakeCourse-progress-percentage'>{` ${parseInt(current.progress_percentaje)}% completado`}</div>
                    {(current.progress_percentaje > 0) &&
                      (<div className='centrar'>
                        <Button
                          className='viewMakeCourse-progress-reset-btn'
                          icon={<ReloadOutlined />}
                          onClick={() => handleResetCurso(tracker)}
                          type="primary"
                        >
                          Reset de progreso
                        </Button>
                      </div>)
                    }
                  </div>
                </Col>
                {/* Valoraciones */}
                <Col md={24}>
                  <h5 className='viewMakeCourse-rate-title'>Valoración</h5>
                  {(current.rated===false) ? (
                    <>
                      <div className='centrar'>
                        <Rate disabled  allowHalf style={{fontSize: '14px', color: 'orange', margin: '0px 5px 0px 5px'}} />
                      </div>
                      <div className='text-center viewMakeCourse-rate-msg'>Es necesario terminar el curso para valorarlo</div>
                    </>
                  ) : (
                    <div>
                      <div className='centrar'>
                        {(current.rate > 0) && (<div className='viewMakeCourse-rate-value'>{parseFloat(current.rate).toFixed(1)}</div>)}
                        <Rate disabled  allowHalf value={Number(current.rate)} style={{fontSize: '14px', color: 'orange', margin: '0px 5px 0px 5px'}} />
                      </div>
                    </div>
                  )}
                  {(current.progress_percentaje==='100') && (
                    <div className='centrar mt-10'>
                      <Button type="primary" onClick={showModal} className='viewMakeCourse-numero-calificar'>
                          {(current.rated === false) ? (
                            <>
                            <span>Calificar curso</span>
                            </>
                          ) : (
                            <>
                            <span>Actualizar Calificación</span>
                            </>
                          )}
                      </Button>
                    </div>
                  )}
                </Col>
                {/* Preguntas */}
                {(preguntasRespuestas) && 
                <Col md={24}>
                  <div className='viewMakeCourse-preguntar-container'>
                  <Form 
                    form={form}
                    style={{paddingTop:'15px', display: 'flex'}}
                    onFinish={handlePreguntar}
                    className='viewMakeCourse-preguntar'
                  >
                    <Form.Item
                      name="texto"
                      rules={[{required: true}]}
                      style={{width: '100%', marginRight: '10px'}}
                    >
                      <Input placeholder='Escribe una pregunta' />
                    </Form.Item>
                  </Form>
                  </div>

                  {(preguntas.pregunta !== undefined) &&
                    (preguntas.pregunta.map((pregunta, key) => (
                      <div className='viewMakeCourse-pregunta' key={key}>
                        <div style={{display: 'flex'}}>
                        <Image src={pregunta.usuario.img}  className='viewMakeCourse-pregunta-img' preview={false}/>
                        <div style={{marginLeft: '20px', display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
                          <div className="pregunta-usuario">{pregunta.usuario.nombre + pregunta.usuario.apellidos}</div>
                        </div>
                        </div>
                        <div className="viewMakeCourse-pregunta-texto">{pregunta.texto}</div>
                      </div>
                    )))
                  }
                </Col>
                }
              </Row>
            </Col>
            }

            {/* Modal para calificar curso*/}
            {(actividad != null) && 
            <Modal footer={null} visible={isModalVisible} onCancel={handleCancel} width={600}>
                <Form
                onFinish={handleEvaluate}
                style={{paddingTop:'15px'}}
                validateMessages={defaultValidateMessages}
                >
                    <h3 style={{textAlign:'center', color: 'rgb(50, 162, 255) !important'}}>Calificación del curso</h3>
                    <Form.Item
                        name="rate"
                        rules={[
                            {
                                required: true
                            }
                        ]}
                    >
                        <Rate allowHalf style={{display:'flex',alignItems:'center',justifyContent:'center'}}   />
                    </Form.Item>
                    <h3 style={{textAlign:'center', color: 'rgb(50, 162, 255) !important'}}>Por favor, dejenos su comentario acerca del curso</h3>
                    <Form.Item
                        name="rate_comment"
                        rules={[
                            {
                                required: true
                            }
                        ]}
                        help=""
                    >
                        <TextArea rows={4} autoSize={{minRows: 2, maxRows: 6}} maxLength={150}  />
                    </Form.Item>
                    <div style={{display:'flex' ,width:'100%', justifyContent:'flex-end'}}>
                        <Form.Item>
                            <Button  onClick={() => handleCancel()} >Cancelar</Button>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" style={{backgroundColor: 'var(--primary)', color: 'white' , marginLeft: '5px'}}>Aceptar</Button>
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
            }

          </Row> 
        </Content>
      </Layout>
      </>
    )
}
