import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Row, Col, Pagination, Input, Form, Skeleton, Button, Select } from 'antd';
import { LeftOutlined, SearchOutlined } from '@ant-design/icons';
import { Option } from 'antd/lib/mentions';
import { getAlumnos } from '../../actions/admin';

export const AdminAlumnosScreen = () => {
    const dispatch = useDispatch();

    const [params, setParams] = useState({
        pagina: 1,
        search: '',
        filter: 0,
        filter_key: ''
    });
    const [loaded, setLoaded] = useState(true);
    const [alumnos, setAlumnos] = useState({
        alumnos: [],
        total: 8
    });

    useEffect(() => {
        dispatch(getAlumnos(setAlumnos, params, setLoaded));
    }, [dispatch, params]);

    const cambioPagina = (pag) => {
        setParams({
            ...params,
            pagina: pag
        });
    }

    const handleBuscar = (search) => {
        setParams({
            ...params,
            pagina: 1,
            search: search.nombre
        });
    }

    const handleBuscando = () => {
        setLoaded(true);
    }

    /* Filtros */
    const cambioOrigen = (value) => {
        if(value === 0){
            setParams({
                ...params,
                pagina: 1,
                filter: value,
                filter_key: ''
            })
        }else{
            setParams({
                ...params,
                pagina: 1,
                filter: 1,
                filter_key: value
            })
        }
    }

    return (
        <>
            <div>
                <h1 className="admin-crud-titulo">Alumnos</h1>
            </div>
            <Link to="/admin" className="admin-btn-atras">
                <div className="admin-btn-atras"><LeftOutlined className="icon-atras" />  atrás</div>
            </Link>
            <Form onFinish={handleBuscar} onValuesChange={handleBuscando}>
                <Form.Item name="nombre">
                    <Input className="input-buscador" placeholder="Buscar" prefix={<Button type="text" htmlType="submit" style={{padding: '5px !important'}}><SearchOutlined className="search-icon" /></Button>}/>
                </Form.Item>
            </Form>

            <Row gutter={[16, 16]}>
            <Col md={19} sm={24}>    
                <Row gutter={[8, 8]}>
            {   
            (!loaded) 
                ? ( 
                    (alumnos.alumnos.length > 0) 
                        ? (alumnos.alumnos.map((alumno) => {
                            return (
                                <>
                                <Col xs={24} sm={12} md={6} key={alumno._id}> 
                                    <Link to={"/admin/alumno/" + alumno._id}>
                                    <Card hoverable style={{height: '200px'}} key={alumno._id}>
                                        <div className="maestro-card">
                                            {
                                                (alumno.img)
                                                ?(<img className="maestro-card-img" src={alumno.img} alt=""/>)
                                                :(<div className="maestro-card-iniciales">{alumno.iniciales}</div>)
                                            }
                                            <div className="maestro-card-nombre bold">{alumno.nombre}</div>
                                        </div>
                                    </Card>
                                    </Link>
                                </Col>
                                </>
                            )})
                            ):(<div className="no-disponible">No hay maestros por mostrar</div>)
                ):(       
                    [0,1,2,3,4,5,6,7].map(n => (
                        <Col className="gutter-row" xs={24} sm={12} md={12} lg={6}>     
                            <Card style={{height: '200px'}}>
                                <div className="maestro-card-skeleton">
                                    <Skeleton.Avatar active className="maestro-skeleton-img" shape="circle"/>
                                    <Skeleton title={false} paragraph={{ rows: 2, width: '100%' }} />
                                </div>
                            </Card>
                        </Col>
                    ))
                )
            }
                </Row>
            </Col>
            <Col md={5} sm={12} className="maestros-filtros-card">
                <div className="maestros-filtros">Filtros</div>
                <Select defaultValue="-1" onChange={cambioOrigen} style={{display: 'block'}}>
                    <Option value="-1" disabled>Origen</Option>
                    <Option value="0">Todos</Option>
                    <Option value="Local">Local</Option>
                    <Option value="iiVVO">iiVVO</Option>
                    <Option value="Psicometricos">Psicometricos</Option>
                </Select>
            </Col>
            </Row>
            <Row>
                <div style={{width: '100%', textAlign: 'center', marginTop: '15px'}}>
                    <Pagination onChange={cambioPagina} current={params.pagina} pageSize={8} total={alumnos.total} responsive={true} />
                </div>
            </Row>
        </>
    )
}