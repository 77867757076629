import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Image } from 'antd'

import userDefault from '../../assets/user.jpg'
import { LogoutOutlined } from '@ant-design/icons'

export const CustomSidebar = ({handleLogout}) => {

    const { img } = useSelector(state => state.usuarios.usuario);
    const { origen, sidebar_folder } = useSelector(state => state.auth);

    const handleAcceder = (value) => {

        if(process.env.REACT_APP_ENV === 'BETA'){
            if(value === 'iivvo'){
                window.location = 'https://beta.iivvo.com';
            }
            if(value === 'psicometricos'){
                window.location = 'https://beta.psicometricos.com';
            }
        }else{
            if(value === 'iivvo'){
                window.location = 'https://app.iivvo.com';
            }
            if(value === 'psicometricos'){
                window.location = 'https://www.psicometricos.com';
            }
        }

    }

    return (
        <div>
            <div className='sideber-item centrar'>
                <Link to="/user/perfil">
                    <Image className='sidebar-user-img' preview={false}  src={img===null ? 'error' : `${img}`} fallback={userDefault}/>
                </Link>
            </div>
            {(origen === 0)
            ? <div className='sideber-item' onClick={handleLogout}>
                <div className='centrar'>
                    <Image preview={false}  src={`/side_${ sidebar_folder }/back.svg`} />
                </div>
                <div className='sidebar-text'>Salir</div>
            </div>
            : ((origen === 1)
            ? <div className='sideber-item' onClick={() => handleAcceder('iivvo')}>
                <div className='centrar'>
                    <Image preview={false}  src={`/side_${ sidebar_folder }/back.svg`} />
                </div>
                <div className='sidebar-text'>iiVVO</div>
            </div>
            : <div className='sideber-item' onClick={() => handleAcceder('psicometricos')}>
                <div className='centrar'>
                    <Image preview={false}  src={`/side_${ sidebar_folder }/back.svg`} />
                </div>
                <div className='sidebar-text'>psicometricos</div>
            </div>)}
        </div>
    )
}
