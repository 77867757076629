import { message } from "antd";
import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from "sweetalert2";

export const eventSetActive=(event)=>({//llamaremos esta accion cuando se haya grabado correctamente en la base de datos
    type:types.eventSetActive,
    payload:event
});
export const eventClearActiveEvent=()=>({
    type:types.eventClearActiveEvent
});

export const maestrosStartLoading=(_id)=>{
    return async(dispatch)=>{
        try {
            const resp=await fetchConToken(`master/maestro/${_id}`);
            const body=await resp.json();

            const maestros= body.usuario;
            dispatch( maestrosLoaded(maestros));
            
        } catch (error){
            console.log(error);
            
        }
    }
}
const maestrosLoaded=(maestros)=>({
    type:types.maestrosLoaded,
    payload:maestros
});

export const changePassword=(_id,{old_password,password,confirmacion})=>{
    return async()=>{
        const resp=await fetchConToken(`master/password/${_id}`,{old_password,password,confirmacion},'PUT');
        const body=await resp.json();
        const success = () => {
            message.info("Su contraseña ha sido actualizada con exito");
        }

        const error = () => {
            message.error(body.msg);
        }
        if (resp.ok===true){
            success();
        }else{
            error();
        }
    }
}

export const editarMaestro=(_id,{nombre,apellidos},img)=>{

    return async(dispatch)=>{
        Swal.fire({
            title: 'Por favor espere...',
            text: 'Actualizando contenido',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        const url = `https://apibcoursera.iivvo.com/api/master/maestro/${_id}`;
        //console.log(url);
        const token = localStorage.getItem('token') || '';

        const formData=new FormData();
        formData.append('nombre',nombre);
        formData.append('apellidos',apellidos);
        formData.append('img',img);
        try {
            const resp=await fetch( url, {
                
                method:'PUT',
                headers: {
                    'x-token': token
                },
                body: formData,
                redirect: 'follow'
            });

            if (resp.ok===true){
                dispatch(maestrosStartLoading(_id))
                Swal.fire({
                    icon: 'success',
                    title: 'Contenido actualizado',
                    showConfirmButton: false,
                    timer: 1200
                })
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Ocurrió un error al actualizar el contenido :('
                })
            }
        
        }catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error
            })
        }
       
    }
}

export const editarMaestroProf=(_id,{profesion,resumen})=>{

    return async(dispatch)=>{

        const url = `https://apibcoursera.iivvo.com/api/master/maestro/${_id}`;
        const token = localStorage.getItem('token') || '';

        const formData=new FormData();
        formData.append('profesion',profesion);
        formData.append('resumen',resumen);
        try {
            const resp=await fetch( url, {
                
                method:'PUT',
                headers: {
                    'x-token': token
                },
                body: formData,
                redirect: 'follow'
            });
            const success = () => {
                message.info("Datos actualizados");
            }

            const error = () => {
                message.error('hoal');
            }
            if (resp.ok===true){
                dispatch(maestrosStartLoading(_id))
                success();
            }else{
                error();
            }
        
            } catch (error) {
                console.log(error);
        
        }
       
    }
}

/*File {name: "pc.jpg", lastModified: 1560188663870, lastModifiedDate: Mon Jun 10 2019 12:44:23 GMT-0500 (hora de verano central), webkitRelativePath: "", size: 5369, …}
lastModified: 1560188663870
lastModifiedDate: Mon Jun 10 2019 12:44:23 GMT-0500 (hora de verano central) {}
name: "pc.jpg"
size: 5369
type: "image/jpeg"
webkitRelativePath: ""
[[Prototype]]: File*/

/*Preguntas y respuestas*/
export const getPreguntas=(id, setPreguntas, pag=1, limite=5)=>{
    return async(dispatch)=>{
        try {
            const resp=await fetchConToken(`master/preguntas-respuestas/${id}?limite=${limite}&pag=${pag}`);
            const body=await resp.json();
            
            console.log(body);
            setPreguntas({
                pregunta: body.preguntas,
                total: body.total_preguntas 
            });
            
        } catch (error){
            console.log(error);
        }
    }
}
export const getPregunta=(id)=>{
    return async(dispatch)=>{
        try {
            const resp=await fetchConToken(`master/pregunta-respuesta/${id}`);
            const body=await resp.json();
            console.log(body);
            
        } catch (error){
            console.log(error);
        }
    }
}
export const responderPregunta=(id, {respuesta}, setRespuesta)=>{
    return async(dispatch)=>{
        // console.log(respuesta);
        try {
            const resp=await fetchConToken(`master/pregunta-respuesta/${id}`, {respuesta}, 'PUT');
            const body=await resp.json();
            setRespuesta(body.pregunta.respuesta);
            
        } catch (error){
            console.log(error);
        }
    }
}
export const borrarPregunta=(_id, preguntaId, setPreguntas)=>{
    return async(dispatch)=>{
        const resp=await fetchConToken(`master/pregunta-respuesta/${preguntaId}`, {}, 'DELETE');
        const body=await resp.json();

        const success = () => {  
            message.info("Eliminado correctamente");
            dispatch(getPreguntas(_id, setPreguntas));
        }

        const error = () => {
            message.error(body.msg);
        }
        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const loadCursos = (num_pag, search, setCursosInfo) => {
    return async() => {
        const resp = await fetchConToken(`master/curso?limite=8&pag=${num_pag}&search=${search}`);
        const body = await resp.json();
        

        const success = () => {
            setCursosInfo({
                status: true,
                cursos: body.cursos,
                total: body.total_cursos
            });
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const crearNuevoCurso = (nombre, setVis, setRedirect) => {
    return async() => {
        const resp = await fetchConToken('master/curso', {nombre}, 'POST');
        const body = await resp.json();

        const success = () => {
            console.log(body.curso);
            setVis(false);
            setRedirect({
                status: true,
                courseId: body.curso._id
            });
            message.info("Curso creado exitosamente");
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
           success();
        }else{
            error();
        }
    }
}

export const loadCursoById = (_id, setCursoData) => {
    return async() => {
        const resp = await fetchConToken(`master/curso/${_id}`);
        const body = await resp.json();
        const curso = await body.curso;
        const success = () => {
            
            if(curso.comunidad === 0){
                setCursoData({
                    ...curso,
                    comunidad: false
                });
            }else{
                setCursoData({
                    ...curso,
                    comunidad: true
                });
            }
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const editarInfoGeneralCurso = (_id, {categorias, comunidad, descripcion, duracion, estado, gratis,  nombre, precio, preguntasRespuestas}, currentStatus, setCurso) => {
    return async(dispatch) => {
        Swal.fire({
            title: 'Por favor espere...',
            text: 'Guardando información',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        //Notificación de error
        const error = (error) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error
            })
        }
        //Curso en desarrollo
        if(currentStatus !== 2){
            //Editar encabezado
            const resp = await fetchConToken(`master/curso/${_id}`, {nombre, descripcion}, 'PUT');
            const body = await resp.json();
            if(resp.ok === true){
                //Editar comunidad
                const resp_2 = await fetchConToken(`master/curso/comunidad/${_id}`, {comunidad}, 'PUT');
                const body_2 = await resp_2.json();
                if(resp_2.ok === true){
                    //Editar preguntas respuestas
                    const resp_3 = await fetchConToken(`master/curso/preguntasyrespuestas/${_id}`, {'preguntas_y_respuestas': preguntasRespuestas}, 'PUT');
                    const body_3 = await resp_3.json();
                    if(resp_3.ok === true){
                        //Editar configuración de curso
                        const resp_4 = await fetchConToken(`master/curso/config/${_id}`, {categorias, duracion, gratis, precio}, 'PUT');
                        const body_4 = await resp_4.json();
                        if(resp_4.ok === true){
                            if(estado !== 0){
                                //Editar status del curso
                                const resp_5 = await fetchConToken(`master/curso/status/${_id}`, {status: estado}, 'PUT');
                                const body_5 = await resp_5.json();
                                if(resp_5.ok === true){
                                    dispatch(loadCursoById(_id, setCurso));
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Curso actualizado',
                                        showConfirmButton: false,
                                        timer: 1200
                                    });
                                }else{
                                    error(body_5.msg);
                                }
                            }else{
                                dispatch(loadCursoById(_id, setCurso));
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Curso actualizado',
                                    showConfirmButton: false,
                                    timer: 1200
                                });
                            }
                        }else{
                            error(body_4.msg);
                        }
                    }else{
                        error(body_3.msg);
                    }
                }else{
                    error(body_2.msg);
                }
            }else{
                error(body.msg);
            }   
        }
        //Curso en producción
        else if(currentStatus === 2){
            //Editar configuración de curso
            const resp_4 = await fetchConToken(`master/curso/config/${_id}`, {categorias, duracion, gratis, precio}, 'PUT');
            const body_4 = await resp_4.json();
            if(resp_4.ok === true){
                dispatch(loadCursoById(_id, setCurso));
                Swal.fire({
                    icon: 'success',
                    title: 'Curso actualizado',
                    showConfirmButton: false,
                    timer: 1200
                });
            }else{
                error(body_4.msg);
            }
        }
        
    }
}

export const editarEncabezado = (nombre, descripcion, _id, setCursoData) => {
    return async(dispatch) => {
        const resp = await fetchConToken(`master/curso/${_id}`, {nombre, descripcion}, 'PUT');
        const body = await resp.json();

        const success = () => {
            message.info("Información guardada exitosamente");
            dispatch(loadCursoById(_id, setCursoData));
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

const baseUrl = process.env.REACT_APP_API_URL;

export const editarPortada = (_id, img, setCursoData) => {
    return async(dispatch) => {
        Swal.fire({
            title: 'Por favor espere...',
            text: 'Actualizando portada',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })
        
        const url = `${baseUrl}/master/curso/portada/${_id}`;
        const token = localStorage.getItem('token') || '';

        const formdata = new FormData();
        formdata.append('img', img);   

        try{
            const resp = await fetch(url, {
                method: 'PUT',
                headers: {
                    'x-token': token
                },
                body: formdata,
                redirect: 'follow'
            })

            if(resp.ok === true){
                dispatch(loadCursoById(_id, setCursoData));
                Swal.fire({
                    icon: 'success',
                    title: 'Imagen actualizada',
                    showConfirmButton: false,
                    timer: 1200
                });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Ocurrió un error al actualizar la imagen :('
                })
            }
        }
        catch{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrió un error al actualizar la imagen :('
            })
        }
    }
}

export const editarConfig = (_id, configs, setCursoData) => {
    return async(dispatch) => {
        const resp = await fetchConToken(`master/curso/config/${_id}`, configs, 'PUT');
        const body = await resp.json();

        const success = () => {
            message.info("Información guardada exitosamente");
            dispatch(loadCursoById(_id, setCursoData));
        }

        const error = () => {
            message.error(body.msg);
            dispatch(loadCursoById(_id, setCursoData));
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const editarStatusCurso = (_id, status, setCursoData) => {
    return async(dispatch) => {
        const resp = await fetchConToken(`master/curso/status/${_id}`, {status}, 'PUT');
        const body = await resp.json();

        const success = () => {
            message.info("Status actualizado correctamente");
            dispatch(loadCursoById(_id, setCursoData));
        }

        const error = () => {
            message.error(body.msg);
            dispatch(loadCursoById(_id, setCursoData));
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const editarComunidadCurso = (_id, comunidad, setCursoData) => {
    return async(dispatch) => {
        const resp = await fetchConToken(`master/curso/comunidad/${_id}`, {comunidad}, 'PUT');
        const body = await resp.json();

        const success = () => {
            message.info("Actualizado correctamente");
            dispatch(loadCursoById(_id, setCursoData));
        }

        const error = () => {
            message.error(body.msg);
            dispatch(loadCursoById(_id, setCursoData));
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const editarPreguntasRespuestasCurso = (_id, preguntas_y_respuestas, setCursoData) => {
    return async(dispatch) => {
        const resp = await fetchConToken(`master/curso/preguntasyrespuestas/${_id}`, {preguntas_y_respuestas}, 'PUT');
        const body = await resp.json();

        const success = () => {
            message.info("Actualizado correctamente");
            dispatch(loadCursoById(_id, setCursoData));
        }

        const error = () => {
            message.error(body.msg);
            dispatch(loadCursoById(_id, setCursoData));
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const loadIndexCategorias = (setIndexCategorias) => {
    return async() => {
        const resp = await fetchConToken(`master/categoria`, {}, 'GET');
        const body = await resp.json();

        const success = () => {
            setIndexCategorias(body.categorias);
        }

        const error = () => {
            
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}


//Temas y actividades

export const loadTemasIndex = (_id, setTemas) => {
    return async() => {
        const resp = await fetchConToken(`master/temas/${_id}`);
        const body = await resp.json();
        

        const success = () => {
            setTemas(body.temas);
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const crearTema = ( _id, data, setVis, setTemas ) => {
    return async(dispatch) => {
        const resp = await fetchConToken(`master/tema/${_id}`, data, 'POST');
        const body = await resp.json();

        const success = () => {
            setVis(false);
            dispatch(loadTemasIndex(_id, setTemas));
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const subirNivelTema = (_id_tema, setTemas, _id) => {
    return async(dispatch) => {
        const resp = await fetchConToken(`master/tema/subir-nivel/${_id_tema}`, {}, 'PUT');
        const body = await resp.json();

        const success = () => {
            dispatch(loadTemasIndex(_id, setTemas));
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const bajarNivelTema = (_id_tema, setTemas, _id) => {
    return async(dispatch) => {

        const resp = await fetchConToken(`master/tema/bajar-nivel/${_id_tema}`, {}, 'PUT');
        const body = await resp.json();

        const success = () => {
            dispatch(loadTemasIndex(_id, setTemas));
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const loadTemaById = (_id, setTemaValues, setVis) => {
    return async() => {

        const resp = await fetchConToken(`master/tema/${_id}`);
        const body = await resp.json();
        const {tema} = body;

        const success = () => {
            setTemaValues({
                '_id': tema._id,
                'nombre': tema.nombre,
                'descripcion': tema.descripcion,
                'quiz': tema.quiz,
                'foro': tema.foro
            });
            setVis(true);
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const editarTema = (_id, _id_tema, data, setTemas, setVis, setStatusCarga) => {
    return async(dispatch) => {

        const resp = await fetchConToken(`master/tema/${_id_tema}`, data, 'PUT');
        const body = await resp.json();

        const success = () => {
            message.info('Información actualizada correctamente');
            setVis(false);
            dispatch(loadTemasIndex(_id, setTemas));
            dispatch(actividadesStartLoading(_id_tema, setStatusCarga));
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const eliminarTema = (_id_tema, _id, setTemas) => {
    
    return async(dispatch) => {

        const resp = await fetchConToken(`master/tema/${_id_tema}`, {}, 'DELETE');
        const body = await resp.json();

        const success = () => {
            dispatch(loadTemasIndex(_id, setTemas));
            message.info('Tema eliminado correctamente');
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

//Actividades

export const actividadesStartLoading=(_id, setLoading, setActividades)=>{
    //console.log(_id);
    return async(dispatch)=>{
        try {
            setLoading(true);
            const resp=await fetchConToken(`master/actividades/${_id}`);
            const body=await resp.json();

            const actividades= body.actividades;

            dispatch(actividadesClear());
            dispatch( actividadesLoaded(actividades));
            setActividades(actividades);
            setLoading(false);
            
        } catch (error){
            
        }
    }
}

const actividadesLoaded=(actividades)=>({
    type:types.actividadesLoaded,
    payload:actividades
});

const actividadesClear = () => ({
    type: types.actividadClear
});

export const viewActividad=(_id, setActividad, setUpdateActividadData)=>{
    return async(dispatch)=>{
        try {
            const resp=await fetchConToken(`master/actividad/${_id}`);
            const body=await resp.json();

            setActividad({
                ...body.actividad,
                curso: {...body.curso},
                data: {...body.data}
            });
            setUpdateActividadData({
                titulo: body.actividad.nombre,
                descripcion: body.actividad.descripcion,
                tipo: body.actividad.tipo
            });
            
        } catch (error){
            console.log(error);
        }
    }
}

export const crearActividad=(_id, {nombre, descripcion, tipo}, setActualizar, actualizar, handleSelectActividad)=>{
    //console.log(nombre,descripcion);
    return async(dispatch)=>{
        const resp=await fetchConToken(`master/actividad/${_id}`, {nombre, descripcion, tipo},'POST');
        const body=await resp.json();
        //console.log(body);
        const success = () => {
            message.info("Registro añadido con exito ");
            handleSelectActividad(body.actividad._id);
            setActualizar(!actualizar);
        }

        const error = () => {
            message.error(body.msg);
        }
        if (resp.ok===true){
            success();
        }else{
            error();
        }
    }
}

export const subirNivelActividad=(_id,_idAct, setActualizar, actualizar)=>{
    return async(dispatch)=>{
        const resp=await fetchConToken(`master/actividad/subir-nivel/${_idAct}`,{},'PUT');
        const body=await resp.json();
        const success = () => {
            message.info("Nivel subido ");
            // dispatch(actividadesStartLoading(_id, setStatusCarga));
            setActualizar(!actualizar);
        }

        const error = () => {
            message.error(body.msg);
        }
        if (resp.ok===true){
            success();
        }else{
            error();
        }
    }
}
export const bajarNivelActividad=(_id,_idAct, setActualizar, actualizar)=>{
    return async(dispatch)=>{
        const resp=await fetchConToken(`master/actividad/bajar-nivel/${_idAct}`,{},'PUT');
        const body=await resp.json();
        console.log(body);
        const success = () => {
            message.info("Nivel descendido ");
            setActualizar(!actualizar);
        }

        const error = () => {
            message.error(body.msg);
        }
        if (resp.ok===true){
            success();
        }else{
            error();
        }
    }
}
export const eliminarActividad=(_id,_idAct, setActualizar, actualizar)=>{
    return async(dispatch)=>{
        const resp=await fetchConToken(`master/actividad/${_idAct}`,{},'DELETE');
        const body=await resp.json();
        //console.log(body);
        const success = () => {
            message.info("Actividad eliminada");
            setActualizar(!actualizar)
        }

        const error = () => {
            message.error(body.msg);
        }
        if (resp.ok===true){
            success();
        }else{
            error();
        }
    }
}

export const editarActividad=(_idAct,{nombre, descripcion, tipo}, content, setEditado)=>{
    return async(dispatch)=>{
        Swal.fire({
            title: 'Por favor espere...',
            text: 'Actualizando actividad',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })
        const resp=await fetchConToken(`master/actividad/${_idAct}`,{nombre, descripcion, tipo},'PUT');
        // const body=await resp.json();

        if (resp.ok===true){
            //Auxiliar para respuesta
            let resp = null;
            //De acuerdo al tipo de actividad
            switch (tipo) {
                case 0:
                    resp = await fetchConToken(`master/actividad/text/${_idAct}`,{ text: content },'PUT');
                    // const body_text = await resp.json();
                    if(resp.ok === true){
                        dispatch(viewActividad(_idAct));
                        setEditado(false);
                        Swal.fire({
                            icon: 'success',
                            title: 'Actividad actualizada',
                            showConfirmButton: false,
                            timer: 1200
                        });
                    }
                    break;
                case 2:
                    let pattern = /https:\/\/www\.youtube\.com\/watch\?v=(.)+/gs;
                    if(pattern.test(content)){
                        resp = await fetchConToken(`master/actividad/video/${_idAct}`,{ url: content },'PUT');
                        // const body_text = await resp.json();
                        console.log(resp);
                        if(resp.ok === true){
                            dispatch(viewActividad(_idAct));
                            setEditado(false);
                            Swal.fire({
                                icon: 'success',
                                title: 'Actividad actualizada',
                                showConfirmButton: false,
                                timer: 1200
                            });
                        }else{
                            Swal.fire({
                                icon: 'error',
                                title: 'Error al actualizar contenido de actividad',
                                showConfirmButton: false,
                                timer: 1200
                            });
                        }
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'La url del contenido de video no puede estar vacia y debe cumplir con el formato adecuado',
                            showConfirmButton: false,
                            timer: 1200
                        });
                    }
                    break;
            
                default:
                    dispatch(viewActividad(_idAct));
                    setEditado(false);
                    Swal.fire({
                        icon: 'success',
                        title: 'Actividad actualizada',
                        showConfirmButton: false,
                        timer: 1200
                    })
                    break;
            }
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrió un error al actualizar la actividad :('
            })
        }
    }
}

/* ACCION DE EDITAR TEXTO */
export const editarTexto=(_id,text)=>{
    return async(dispatch)=>{
        Swal.fire({
            title: 'Por favor espere...',
            text: 'Actualizando contenido',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        const url = `https://apibcoursera.iivvo.com/api/master/actividad/text/${_id}`;
        const token = localStorage.getItem('token') || '';
        const formData=new FormData();
        formData.append('text',text);
       
        try {
            const resp=await fetch( url, {
                method:'PUT',
                headers: {
                    'x-token': token
                },
                body: formData,
                redirect: 'follow'
            });
            if (resp.ok===true){
                dispatch(viewActividad(_id))
                Swal.fire({
                    icon: 'success',
                    title: 'Contenido actualizado',
                    showConfirmButton: false,
                    timer: 1200
                })
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Ocurrió un error al actualizar el contenido :('
                })
            }
        
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error
                })
        
        }
       
    }
}
/*CREAR RECURSO */
export const crearRecurso=(_id,recurso,setRecursos,Swal)=>{
    //console.log(recurso);

    return async(dispatch)=>{

        const url = `https://apibcoursera.iivvo.com/api/master/actividad/text/recurso/${_id}`;
        //console.log(url);
        const token = localStorage.getItem('token') || '';

        const formData=new FormData();
        formData.append('recurso',recurso);
       
        try {
            const resp=await fetch( url, {
                
                method:'POST',
                headers: {
                    'x-token': token
                },
                body: formData,
                redirect: 'follow'
            });
            const success = () => {
                message.info("Recurso creado");
            }

            const error = () => {
                message.error('No se pudo crear el recurso');
            }
            if (resp.ok===true){
                const body=await resp.json();
                setRecursos([
                    ...body.data.recursos
                ]);
                Swal.close();
                dispatch(viewActividad(_id));
                success();
            }else{
                Swal.close();
                error();
            }
        
            } catch (error) {
                console.log(error);
        }
    }
}
/* ELIMINAR RECURSO*/
export const eliminarRecurso=(_id,recurso,setRecursos,recursos,Swal)=>{
    //console.log(_id,_idRec);
    return async(dispatch)=>{
        const resp=await fetchConToken(`master/actividad/text/recurso/${_id}`,{recurso},'DELETE');
        const body=await resp.json();
        //console.log(body);
        const success = () => {
            message.info("Recurso eliminado");
        }

        const error = () => {
            message.error(body.msg);
        }
        if (resp.ok===true){
            // console.log('respuesta',body);
            recursos = recursos.filter(recurso_aux => (recurso_aux._id !== recurso));
            // console.log('recursos', recursos);
            setRecursos([
                ...recursos
            ]);
            Swal.close();
            dispatch(viewActividad(_id));
            success();
        }else{
            Swal.close();
            error();
        }
    }
}

//Actividad Quiz

export const loadQuizDocente = (_id, setQuiz) => {
    return async() => {
        const resp = await fetchConToken(`master/actividad/${_id}`);
        const body = await resp.json();

        const success = () => {
            setQuiz({
                ...body.actividad,
                curso: body.curso,
                data: body.data
            });
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const crearPregunta = (_id, pregunta, setQuiz, setVis) => {
    return async(dispatch) => {
        const resp = await fetchConToken(`master/actividad/quiz/pregunta/${_id}`, pregunta , 'POST');
        const body = await resp.json();

        const success = () => {
            message.info('Pregunta creada correctamente');
            dispatch(loadQuizDocente(_id, setQuiz));
            setVis(false);
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const editarPregunta = (_id, pregunta, setQuiz, setVis) => {
    return async(dispatch) => {
        const resp = await fetchConToken(`master/actividad/quiz/pregunta/${_id}`, pregunta , 'PUT');
        const body = await resp.json();

        const success = () => {
            message.info('Pregunta editada correctamente');
            dispatch(loadQuizDocente(_id, setQuiz));
            setVis(false);
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const eliminarPregunta = (_id, id_pregunta, setQuiz) => {
    
    return async(dispatch) => {
        const resp = await fetchConToken(`master/actividad/quiz/pregunta/${_id}`, {id_pregunta} , 'DELETE');
        const body = await resp.json();

        const success = () => {
            message.info('Pregunta eliminada correctamente');
            dispatch(loadQuizDocente(_id, setQuiz));
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const editarPreguntasMinimas = (_id, preguntas_minimas) => {
    
    return async() => {
        const resp = await fetchConToken(`master/actividad/quiz/${_id}`, {preguntas_minimas} , 'PUT');
        const body = await resp.json();

        const success = () => {
            message.info('Pregunta minimas actualizadas');
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

/* FORO DE MAESTRO */
export const postsStartLoading=(_id_tema,num_pag,setStatusCarga)=>{
    
    return async(dispatch)=>{
       
        try {
            const resp=await fetchConToken(`master/foro/posts/${_id_tema}?limite=5&pag=${num_pag}`);
            const body=await resp.json();
            //console.log(body);//todas los posts de un tema
             dispatch(postsLoaded(body));
             setStatusCarga({
                status:body.status,
                total:body.total_posts
            })
            

            
        } catch (error){
            console.log(error);
            
        }
    }
}

const postsLoaded=(body)=>({
    type:types.postsLoaded,
    payload:body
});

export const crearForo=(_id,post,num_pag)=>{
    //console.log(recurso);

    return async(dispatch)=>{

        const url = `https://apibcoursera.iivvo.com/api/master/foro/post/${_id}`;
        //console.log(url);
        const token = localStorage.getItem('token') || '';

        const formData=new FormData();
        formData.append('post',post);
       
        try {
            const resp=await fetch( url, {
                
                method:'POST',
                headers: {
                    'x-token': token
                },
                body: formData,
                redirect: 'follow'
            });
            const success = () => {
                message.info("Foro creado");
            }

            const error = () => {
                message.error('No se pudo crear el foro');
            }
            if (resp.ok===true){
                dispatch(postsStartLoading(_id,num_pag))
               
                success();
            }else{
                error();
            }
        
            } catch (error) {
                console.log(error);
        }
    }
}
/*const postsClear = () => ({
    type: types.postsClear
});*/

export const eliminarForo = (_id,_id_post,num_pag) => {
    
    return async(dispatch) => {
        const resp = await fetchConToken(`master/foro/post/${_id_post}`, {} , 'DELETE');
        const body = await resp.json();

        const success = () => {
            message.info('Foro eliminado');
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            dispatch(postsStartLoading(_id,num_pag));
            success();
        }else{
            error();
        }
    }
}

export const editarForo=(_id,_id_post,content,num_pag)=>{
    //console.log(recurso);

    return async(dispatch)=>{

        const url = `https://apibcoursera.iivvo.com/api/master/foro/post/${_id_post}`;
        //console.log(url);
        const token = localStorage.getItem('token') || '';

        const formData=new FormData();
        formData.append('post',content);
       
        try {
            const resp=await fetch( url, {
                
                method:'PUT',
                headers: {
                    'x-token': token
                },
                body: formData,
                redirect: 'follow'
            });
            const success = () => {
                message.info("Foro editado");
            }

            const error = () => {
                message.error('No se pudo editar el foro');
            }
            if (resp.ok===true){
                dispatch(postsStartLoading(_id,num_pag))
               
                success();
            }else{
                error();
            }
        
            } catch (error) {
                console.log(error);
        }
    }
}

export const loadUsuariosEnroladosCurso = (setUsuarios, _id, {pagina, search, filter, filter_key}, limite=6) => {
    
    return async(dispatch) => {
        const resp = await fetchConToken(`master/curso/usuarios/${_id}?limite=${limite}&pag=${pagina}&search=${search}`);
        const body = await resp.json();

        const usuarios = body.usuarios.map( (usuario, key) => (
            {
                key: key,
                ...usuario
            }
        ))

        const success = () => {
            setUsuarios({
                status: true,
                usuarios: usuarios,
                total: body.total_usuarios
            });
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const loadReviewsCurso = (pag, _id, setReviews) => {
    return async() => {
        const resp = await fetchConToken(`master/curso/valoraciones/${_id}?limite=5&pag=${pag}`);
        const body = await resp.json();

        const success = () => {
            setReviews({
                status: true,
                reviews: body.valoraciones,
                total: body.total_valoraciones
            });
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}








