import React, { useState } from 'react';
import queryString from 'query-string';
import { Row, Col, Button } from 'antd';
import { AvisoPrivacidad } from './AvisoPrivacidad';
import { TerminosCondiciones } from './TerminosCondiciones';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const Politicas = () => {

    const [politicas, setPoliticas] = useState({
        title: "Aviso de privacidad",
        active: true
    });
    const {title, active} = politicas;

    const location = useLocation();    
    const {q} = queryString.parse(location.search);

    useEffect(() => {
        window.scrollTo(0,0)
        if(q === '1'){
            setPoliticas({
                ...Politicas,
                active: true
            })
        }else if(q === '2'){
            setPoliticas({
                ...Politicas,
                active: false
            })
        }
    }, [q, setPoliticas])
    
    const avisoPrivacidad = () => {
        setPoliticas({
            title: "Aviso de privacidad",
            active: true
        })
    }

    const terminosCondiciones = () => {
        setPoliticas({
            title: "Terminos y Condiciones",
            active: false
        })
    }

    const btnPoliticas = {
        backgroundColor: '#4CAF50',
        borderColor: '#4CAF50',
        marginBottom: '20px'
    }

    const btnPoliticasActive = {
        backgroundColor: 'rgb(67 158 71)',
        borderColor: 'rgb(67 158 71)',
        marginBottom: '20px'
    }

    return (
        <>
            <Row className ="politicas-banner" 
                style={{ 
                        backgroundImage: `url('https://beta.iivvo.com/img/aviso.jpg')`
                      }}>
                <Col span={24}>
                    <div className="politicas-title bold">
                        {title}                    
                    </div>
                </Col>
            </Row>
            <div style={{padding: '0px 100px'}}>
                <Row>
                    <Col span={12}>
                        <Button type="primary" 
                                onClick={avisoPrivacidad}
                                block
                                style={
                                    (!active)
                                    ? (btnPoliticas)
                                    : (btnPoliticasActive)
                                }
                                size="large"
                        >
                            Aviso de privacidad
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button type="primary"
                                onClick={terminosCondiciones}
                                block
                                style={
                                    (active)
                                    ? (btnPoliticas)
                                    : (btnPoliticasActive)
                                }
                                size="large"
                        >
                            Términos y condiciones
                        </Button>
                    </Col>
                </Row>

                <Row>
                    {
                        (active)
                            ? (<AvisoPrivacidad />)
                            : (<TerminosCondiciones />)
                    }
                </Row>
            </div>
        </>
    )
}
