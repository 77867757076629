import { UserOutlined,UserAddOutlined,MailOutlined } from '@ant-design/icons';
import {  Button, Form, Input } from 'antd';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../actions/maestros';
import { defaultValidateMessages } from '../../helpers/validateMessages';

export const PasswdMaestro = () => {
    const dispatch = useDispatch();
    const {id:_id} = useSelector(state => state.auth);
    const handlePassword=(datos)=>{
        dispatch(changePassword(_id,datos));
    }
  
    return (
        <div>
             
             <Form
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={handlePassword}
                validateMessages={defaultValidateMessages}
                >

                <Form.Item
                    name="old_password"
                    rules={[
                    {
                        required: true,
                        min:8
                        
                    },
                    ]}
                >
                    <Input.Password
                    prefix={<MailOutlined style={{color: 'var(--primary)'}} className="site-form-item-icon" />} 
                    placeholder="Contraseña anterior" 
                    type="password"
                    className="inputs"
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                    {
                        required: true,
                        min:8
                    },
                    ]}
                >
                    <Input.Password
                    prefix={<UserOutlined style={{color: 'var(--primary)'}} className="site-form-item-icon" />}
                    className="inputs"
                    placeholder="Nueva Contraseña"
                    />
                </Form.Item>

                <Form.Item
                    name="confirmacion"
                    rules={[
                    {
                        required: true,
                        min:8
                    },
                    ]}
                >
                    <Input.Password
                    prefix={<UserAddOutlined style={{color: 'var(--primary)'}} className="site-form-item-icon" />}
                    className="inputs"
                    placeholder="Confirmar contraseña"
                    />
                </Form.Item>
                
                    <Button type="primary" htmlType="submit" className="btn-forms">
                        Guardar
                    </Button>
               
                </Form>
        </div>
    )
}
