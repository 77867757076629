import React from 'react'
import { FacebookFilled, InstagramOutlined, LinkedinFilled, YoutubeFilled, WhatsAppOutlined } from '@ant-design/icons'

export const FooterAdmin = () => {
    return (
        <div className="admin-footer-container">
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/iivvo.oficial" ><FacebookFilled className="icon-footer-admin"/></a>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/iivvo.oficial/"><InstagramOutlined className="icon-footer-admin"/></a>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/iivvo---conectamos-a-j%C3%B3venes-con-universidades"><LinkedinFilled className="icon-footer-admin"/></a>
                    <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UC1r0DPbF1X5SyyJdTpSAIvw"><YoutubeFilled className="icon-footer-admin"/></a>
                    <a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=8112125982"><WhatsAppOutlined className="icon-footer-admin"/></a>
                </div>
                <div >
                    Jóvenes transformando al mundo® 2016 - 2021
                </div>
            </div>
        </div>
    )
}
