import { UserOutlined } from '@ant-design/icons';
import { Col, Row, Card, Pagination, Rate, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { loadCursosUsuario } from '../../actions/usuarios';

import portada from '../../assets/nuevoCurso.jpg';

export const CursosScreen = () => {

    const {parametro} = useParams();
    const dispatch = useDispatch();

    const [cursosInfo, setCursosInfo] = useState({
        status: false,
        cursos: [],
        total: 8
    });

    const url = window.location.pathname;
    
   useEffect(() => {
        if(url.indexOf('categories') !== -1){
            
            const params = `&filter=1&filter_key=${parametro}`;
            dispatch(loadCursosUsuario(1, params, setCursosInfo));
            
        }else if(url.indexOf('search') !== -1){
            const filtros = parametro.split(' ').join('+');
            const params = `&search=${filtros}`;
            dispatch(loadCursosUsuario(1, params, setCursosInfo));
        }else{
            dispatch(loadCursosUsuario(1,'',setCursosInfo))
        }
   }, [dispatch, url, parametro])

    const handleCambioPagina = (pagina) => {
        setCursosInfo({
            ...cursosInfo,
            status: false
        });

        if(url.indexOf('categories') !== -1){
            const params = `&filter=1&filter_key=${parametro}`;
            dispatch(loadCursosUsuario(pagina, params, setCursosInfo));
            
        }else if(url.indexOf('search') !== -1){
            
            const filtros = parametro.split(' ').join('+');
            const params = `&search=${filtros}`;
            dispatch(loadCursosUsuario(pagina, params, setCursosInfo));
        }else{
            dispatch(loadCursosUsuario(pagina,'',setCursosInfo))
        }
        
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', padding: '60px 8% 60px 8%'}}>
             <Row>
                    <Col span={15}>
                        <h3 className="bold">Cursos Disponibles</h3>
                    </Col>
                       
                    <Col span={9}>
                        <Row justify='end'>
                            <Col>
                                <Pagination simple onChange={handleCambioPagina} pageSize={8} total={cursosInfo.total} responsive={true} />
                            </Col>
                        </Row>
                    </Col>
                       
                    <Col style={{marginTop: '10px'}} span={24}>
                        <Row gutter={[24,24]}>
                            {
                                (cursosInfo.status === true)
                                    ?
                                        cursosInfo.cursos.map ((curso) => {
                                            return (
                                                <Col key={curso._id} className="gutter-row" xs={24} sm={12} md={12} lg={6} >
                                                    <Link to={`/courses/${curso._id}`}>
                                                            <Card
                                                                hoverable
                                                                cover={<img style={{height: '170px'}} alt="Podcast" src={(curso.img) ? curso.img : portada} />}
                                                            >  
                                                                <Card.Grid hoverable={false} style={{width: '100%', height:'60px', boxShadow: 'none'}}>
                                                                    <div className="card-titulo bold">{curso.nombre}</div>
                                                                </Card.Grid>
        
                                                                <Card.Grid hoverable={false} style={{width: '100%', boxShadow: 'none'}}>
                                                                    <div style={{display: 'flex'}}>
                                                                        <span><h3>{curso.rate}</h3></span>
                                                                        <Rate disabled allowHalf defaultValue={parseFloat(curso.rate)} style={{fontSize: '14px', color: 'orange', margin: '0px 5px 0px 5px'}} />
                                                                        <span>({curso.no_evaluaciones})</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style={{fontSize: '12px'}}>{`${curso.maestro.nombre} ${curso.maestro.apellidos}`}</span>
                                                                    </div>
                                                                    <div className="card-header">
                                                                        <div><UserOutlined /> {curso.no_participantes}</div>
                                                                        <div style={{color: 'green'}}>{(curso.precio === '0') ? "Gratis" : '$'+curso.precio}</div>
                                                                    </div>
                                                                </Card.Grid>
                                                                
                                                                
                                                            </Card>
                                                    </Link>
                                                </Col>
                                            )
                                        })
                                    :
                                        ([0,1,2,3,4,5,6,7]).map(n => (
                                            <Col key={n} className="gutter-row" xs={24} sm={12} md={12} lg={6} >
                                                <Skeleton paragraph={{rows: 8}} active />
                                            </Col>
                                        ))
                            }
                            
                        </Row>
                    </Col>
                </Row>
        </div>
    )
}
