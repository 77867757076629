import { Modal, Form, Input, Button, Row, Col, Checkbox, message } from 'antd'
import Table from 'rc-table';
import React from 'react'
import { useDispatch } from 'react-redux';
import { crearPregunta, editarPregunta } from '../../actions/maestros';
import { defaultValidateMessages } from '../../helpers/validateMessages';

export const ModalQuiz = ({_id, vis, setVis, setQuiz, preguntaActual, setRespActual, respActual}) => {

    const {TextArea} = Input;
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const handleCancelar = () => {
        setVis(false);
    }
    
    const handleAddRespuesta = () => {
        const respuesta = document.getElementById('inputRespuesta').value;
        const correcta = document.getElementById('checkCorrecta').checked;

        const newResp = {
            respuesta: respuesta,
            correcta: correcta
        }

        //Limpiar input de respuesta
        form.resetFields(['inputRespuesta']);
        form.resetFields(['checkCorrecta']);

        if(respActual.indexOf(newResp) === -1){
            setRespActual([
                ...respActual,
                newResp
            ]);
        }else{
            message.info('Ya has registrado esa respuesta');
        }
    }
    const handleCambiarCorrecta = (respuesta, e) => {
        //Buscar index de la respuesta a modificar
        var index = respActual.findIndex(x => x.respuesta === respuesta);

        //Cambiar el status de la respuesta
        let newArr = [...respActual];
        respActual[index].correcta = e.target.checked;
        setRespActual(newArr);
    }
    const handleAceptar = (formData) => {
        if(preguntaActual.id_pregunta === ''){
            const pregunta = {
                pregunta: formData.pregunta,
                respuestas: respActual
            }
    
            dispatch(crearPregunta(_id, pregunta, setQuiz, setVis));
        }else{
            const pregunta = {
                id_pregunta: preguntaActual.id_pregunta,
                pregunta: formData.pregunta,
                respuestas: respActual
            }
    
            dispatch(editarPregunta(_id, pregunta, setQuiz, setVis));
        }
    }
    const handleEliminar = (respuestaEliminar) => {
        const respFiltradas = respActual.filter(respuesta => respuesta.respuesta !== respuestaEliminar);
        setRespActual(respFiltradas);
    }

    const columns = [
        {
            title: 'Respuesta',
            key: 'respuesta',
            dataIndex: 'respuesta',
            width: '75%',
            
        },
        {
            title: 'Correcta',
            dataIndex: 'correcta',
            width: '15%',
            align: 'center',
            render: (text, respuesta) => {
                const value = respuesta.correcta;
                return <Checkbox 
                            defaultChecked={value} 
                            onChange={(e) => handleCambiarCorrecta(respuesta.respuesta, e)}
                        />
            }
        },
        {
            title: 'Eliminar',
            key: 'eliminar',
            dataIndex: 'eliminar',
            width: '10%',
            align: 'center',
            render: (text, respuesta) => {
                return <Button type='link' onClick={() => {handleEliminar(respuesta.respuesta)}} >Eliminar</Button>
            }
        }
    ]
    

    return (
        <>
            <div>
                <Modal
                    visible={vis}
                    destroyOnClose={true}
                    closable={false}
                    footer={null}
                    
                >
                    {(preguntaActual.id_pregunta === '') ? <h2>Nueva Pregunta</h2> : <h2>Editar Pregunta</h2>}
                    <Form
                        form={form}
                        onFinish={handleAceptar}
                        validateMessages={defaultValidateMessages}
                        initialValues={{
                            'pregunta': preguntaActual.pregunta
                        }}
                    >
                        <Form.Item
                            style={{marginBottom: '5px'}}
                            name='pregunta'
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                        >
                            <TextArea 
                                id='hola'
                                style={{width: '100%', resize: 'none', border: '0'}}
                                placeholder='Ingresa tu pregunta' 
                            />
                        </Form.Item>
                        
                        <div style={{marginBottom: '10px'}}>
                                <h4>Agregar respuesta</h4>
                                <Row gutter={[12,12]}>
                                    <Col className='gutter-row' xs={24} sm={18}>
                                        <Form.Item name='inputRespuesta' >
                                            <Input 
                                                id='inputRespuesta' 
                                                allowClear placeholder='respuesta' 
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className='gutter-row' xs={12} sm={6}>
                                        <Form.Item name='checkCorrecta'>
                                            <Checkbox id='checkCorrecta'>
                                                Correcta
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col align={'right'} className='gutter-row' xs={12} sm={24}>
                                        <Button onClick={handleAddRespuesta}>Agregar</Button>
                                    </Col>
                                </Row>
                        </div>

                        <div style={{width:'100%'}}>
                            <Table
                                rowKey='respuesta'
                                columns={columns}
                                data={respActual}
                                scroll={{y: 100}}
                            />
                        </div>

                        <div style={{display:'flex' ,width:'100%', justifyContent:'flex-end', marginTop: '10px'}}>
                            <Form.Item>
                                <Button onClick={() => handleCancelar()} >
                                    Cancelar
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                    <Button htmlType="submit" style={{backgroundColor: 'var(--primary)', color: 'white' , marginLeft: '5px'}}>Aceptar</Button>
                            </Form.Item>
                        </div>
                        
                    </Form>
                </Modal>
            </div>
        </>
    )
}