import { types } from "../types/types";

const initialState={
    activeButton:null,
    usuario:{},
    cursoUsuario:{curso:{}},
    valoracionesUsuario:{},
    bienvenidaUsuario:{curso:{}},
    carritoUsuario:{},
    courseTracker:{},
    viewActividad:{},
    current:{curso:{},actividad:''},
    evaluacion:{},
    calificacion:{},
    posteos:{posts:[]}
};

export const usuariosReducer=(state=initialState,action)=>{
    switch (action.type){
        case types.eventSetActive:
            return{
                ...state,
                activeButton:true
            }
        case types.eventClearActiveEvent:
            return{
                ...state,
                activeButton:null
            }
        case types.usuariosLoaded:
            return{
                ...state,
                usuario:{...action.payload}
            }
        case types.usuariosLogin:
            return{
                ...state,
                usuario:{...action.payload}
            }
        case types.cursoUsuarioLoaded:
            return{
                ...state,
                cursoUsuario:{...action.payload}
            }
        case types.valoracionesLoaded:
            return{
                ...state,
                valoracionesUsuario:{...action.payload}
            }
        case types.bienvenidaUsuario:
            return{
                ...state,
                bienvenidaUsuario:{...action.payload}
            }
        case types.carritoUsuario:
            return{
                ...state,
                carritoUsuario:{...action.payload}
            } 
        case types.courseTracker:
            return{
                ...state,
                courseTracker:{...action.payload}
            }
        case types.resetCarritoUsuario:
            return{
                ...state,
                carritoUsuario:{}
            }
        case types.ViewActividad:
            return{
                ...state,
                viewActividad:{...action.payload}
            }
        case types.currentActivity:
            return{
                ...state,
                current:{...action.payload}
            }
        case types.currentActivityEnd:
            return{
                ...state,
                current:{
                    ...state.current,
                    ...action.payload
                }
            }
        case types.evaluacionActividad:
            return{
                ...state,
                evaluacion:{...action.payload}
            }
        case types.calificacionCurso:
            return{
                ...state,
                calificacion:{...action.payload}
            }
        case types.postsUsuarioLoaded:
            return{
                ...state,
                posteos:{...action.payload}
            }
        
        default:
            return state;
            
    }

}