import { Button, Form, Input, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import { useDispatch } from 'react-redux';
import { agregarCategoria, editarCategoria } from '../../../actions/admin';
import { defaultValidateMessages } from '../../../helpers/validateMessages';

export const ModalAddCategory = ({vis, setVis, _id, nombre, clasificacion, setCategory, initValue, params, setCategorias, setLoading, clasificaciones = []}) => {

    const dispatch = useDispatch();

    const handleCancelar = () => {
        setVis(false);
        setCategory(initValue);
    }

    const handleAceptar = (data) => {
        
        if(_id===''){
            dispatch(agregarCategoria(data.nombre, data.clasificacion, params, setCategorias, setLoading));
            setVis(false);
            setCategory(initValue);
        }else{
            dispatch(editarCategoria(data.nombre, data.clasificacion, _id, params, setCategorias, setLoading));
            setVis(false);
            setCategory(initValue);
        }
    }

    return (
        <>
            <Modal
                visible={vis}
                destroyOnClose={true}
                closable={false}
                footer={null}
            >
                {
                    (_id === '')
                        ? <h2 style={{color: 'var(--primary)'}}>Agregar Categoría</h2>
                        : <h2 style={{color: 'var(--primary)'}}>Editar Categoría</h2>
                }
                <Form 
                    style={{paddingTop:'15px'}}
                    validateMessages={defaultValidateMessages}
                    onFinish={handleAceptar}
                    initialValues={{
                        "nombre": nombre,
                        "clasificacion": clasificacion
                    }}
                >
                    <Form.Item
                        name="nombre"
                        rules={[{required: true}]}
                    >
                        <Input placeholder='Nueva Categoría' />
                    </Form.Item>

                    <Form.Item
                        name="clasificacion"
                        rules={[{required: true}]}
                        label="Clasificacion"
                    >
                        <Select>
                            {
                                clasificaciones.map((clasificacion) => {
                                    return(
                                        <Select.Option value={clasificacion._id}>{clasificacion.nombre}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>

                    <div style={{display:'flex' ,width:'100%', justifyContent:'flex-end'}}>
                        <Form.Item>
                            <Button  onClick={() => handleCancelar()} >Cancelar</Button>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" style={{backgroundColor: 'var(--primary)', marginLeft: '5px'}}>Aceptar</Button>
                        </Form.Item>
                    </div>

                </Form>
            </Modal>
        </>
    )
}
