import { Avatar, Card, Col, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Categorias } from '../../actions/usuarios';

export const CategoriasScreen = () => {
  const dispatch=useDispatch();

  const [categorias,setCategorias] = useState({
      status:false,
      clsificacion:[]
  });

  useEffect(() => {
    dispatch(Categorias(setCategorias))
  }, [dispatch]);

  const Icon = ({ type, ...rest }) => {
    const icons = require(`@ant-design/icons`);
    const Component = icons[type];
    return <Component {...rest} />;
  
    //onst Component = require(`@ant-design/icons/${type}`).default;
    //return <Component {...rest}/>
  };
  
  return (
    <div style={{padding:'2% 5%',backgroundColor:'white'}}>
      <h1 style={{textAlign:'center',fontSize:'30px'}}>Categorias</h1>
      <Row gutter={[12, 12]}>
        {
          (categorias.status === true)
              ?
                categorias.clsificacion.map ((curso) => {
                  return (
                      <Col key={curso._id} className="gutter-row" xs={24} sm={12} md={12} lg={6} >

                          
                        {/*<Link to={`/user/make-course/${curso._id}`}>*/}
                            <Card 
                            style={{height:'250px',padding:'2%'}}
                            cover={<div style={{textAlign:'center',marginTop:'5px'}}><Avatar size={50} icon={<Icon  type={`${curso.icon}`} />} style={{backgroundColor: 'var(--primary)'}}/></div>}
                            > 
                            
                            
                              <Card.Grid hoverable={false} style={{width: '100%', height:'60px', boxShadow: 'none',padding:'10px'}}>
                                  <div style={{width:'100%',fontSize:'20px',fontWeight:'bold',textAlign:'center',color:'var(--primary)'}}>{curso.nombre}</div>
                              </Card.Grid>

                              {
                                curso.categorias.map((categoria=>{
                                  return(
                                    <Card.Grid hoverable={false} style={{width: '25%', boxShadow: 'none',padding:'10px'}} key={categoria._id}>
                                        <div style={{alignItems:'center',justifyContent:'center',width:'100%'}}>
                                            <Link to={`/courses/categories/${categoria._id}`}><span style={{fontSize:'17px',color:'black',borderBottom:'1px solid black'}}>{categoria.nombre}</span></Link>
                                        </div>
                                        
                                    </Card.Grid>

                                  )
                                    
                                }))
                              }


                          </Card>
                      {/*</Link>*/}
                    </Col>
                )
              })
            :
            ([0,1,2,3,4,5,6,7]).map(n => (
                <Col key={n} className="gutter-row" xs={24} sm={12} md={12} lg={6} >
                    <Skeleton paragraph={{rows: 8}} active />
                </Col>
            ))
        }
      </Row>
    </div>
  );
};

