import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getPreguntas, borrarPregunta, preguntar } from '../../actions/usuarios';
import { Row, Col, Pagination, Form, Input, Button } from 'antd';
import { defaultValidateMessages } from '../../helpers/validateMessages';
import { useParams } from 'react-router-dom';
import { Pregunta } from './Pregunta';

export const PreguntasScreen = () => {
    const {_id}=useParams()
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const [preguntas, setPreguntas] = useState({
        pregunta: [],
        total: 5
    });

    useEffect(() => {
        window.scrollTo(0,0)
        dispatch(getPreguntas(_id, setPreguntas));
    }, [_id, dispatch]);

    const onChange = (pag) => {
        dispatch(getPreguntas(_id, setPreguntas, pag));
    }

    const handlePreguntar = (data) => {
        dispatch(preguntar(_id, data.texto, setPreguntas));
        form.resetFields();
    }

    console.log(preguntas);

    return (
        <div style={{padding: '30px'}}>
            <Row>
                <Col span={16} offset={4} >
                    <h1 style={{textAlign: 'center'}}>Preguntas</h1>
                    <div>
                    {(Object.keys(preguntas).length > 0)
                    ? (
                       <div> 
                            {
                                preguntas.pregunta.map (pregunta =>(
                                    <Pregunta 
                                        key = {pregunta._id}
                                        info = {pregunta}
                                        eliminarPregunta = {() => {
                                            dispatch(borrarPregunta(_id, pregunta._id, setPreguntas))
                                        }}
                                    />
                                ))
                            }
                        </div>
                     ) 
                    :
                        (<div className="pregunta-card">
                            <p className="preguntas-none">No se han hecho preguntas todavía</p>
                        </div>)
                    }
                    </div>

                    <div>
                    <Form 
                        form={form}
                        style={{paddingTop:'15px', display: 'flex'}}
                        validateMessages={defaultValidateMessages}
                        onFinish={handlePreguntar}
                    >
                        <Form.Item
                            name="texto"
                            rules={[{required: true}]}
                            style={{width: '100%', marginRight: '10px'}}
                        >
                            <Input placeholder='Preguntar' />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" style={{marginRight: '10px'}}>Preguntar</Button>
                        </Form.Item>
                    </Form>
                    </div>

                    <Pagination 
                        simple
                        defaultPageSize={5} 
                        onChange={onChange} 
                        total={preguntas.total}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                    </Pagination>
                </Col>
            </Row>
        </div>
    )
}
