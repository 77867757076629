import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { UsuarioScreen } from '../../components/usuario/UsuarioScreen';
import { BienvenidaScreen } from '../../components/usuario/BienvenidaScreen';
import { PagarCarritoScreen } from '../../components/usuario/PagarCarritoScreen';
import { ViewMakeCourse } from '../../components/usuario/ViewMakeCourse';
import { AprendizajeUsuario } from '../../components/usuario/AprendizajeUsuario';
import { PreguntasScreen } from '../../components/usuario/PreguntasScreen';
import { ForoUsuarioScreen } from '../../components/usuario/ForoUsuarioScreen';
import { FinanzasUsuarioScreen } from '../../components/usuario/FinanzasUsuarioScreen';

export const SemiprivateRouter = () => {
    return (
        <div>
            <Switch>
                <Route exact path="/user/perfil" component={UsuarioScreen}/>
                <Route path="/user/bienvenida/:_id" component={BienvenidaScreen}/>
                <Route path="/user/make-course/:_id/:idact?/:token?" component={ViewMakeCourse}/>
                <Route path="/user/carrito" component={PagarCarritoScreen}/>
                <Route path="/user/mi_aprendizaje" component={AprendizajeUsuario}/>
                <Route path="/user/foro/:_id" component={ForoUsuarioScreen}/>
                <Route path="/user/preguntas/:_id" component={PreguntasScreen}/>
                <Route exact path ="/user/finances" component={FinanzasUsuarioScreen} />
                <Redirect to="/home"/>
            </Switch>
        </div>
    )
}
