import { Button, Col, Pagination, Row, Skeleton } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadCursos } from '../../actions/maestros';
import { ModalAddCurso } from './ModalAddCurso';

import portada from '../../assets/nuevoCurso.jpg';
import { TarjetaCursoDocente } from './general/TarjetaCursoDocente';

export const DocenteIndexCursosScreen = () => {

    const dispatch = useDispatch();

    const [cursosInfo, setCursosInfo] = useState({
        status: false,
        cursos: [],
        total: 8
    });

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        window.scrollTo(0,0);
        dispatch(loadCursos(1, '', setCursosInfo));
    }, [dispatch])

    const [vis, setVis] = useState(false);

    const openAddModal = () => {
        setVis(true);
    }

    const handleCambioPagina = (pag) => {
        setCursosInfo({
            ...cursosInfo,
            status: false
        });

        setCurrentPage(pag);
        const search = document.getElementById('inputSearch').value;
        dispatch(loadCursos(pag, search, setCursosInfo));
    }

    const handleBusqueda = () => {
        setCursosInfo({
            ...cursosInfo,
            status: false
        });

        setCurrentPage(1);
        const search = document.getElementById('inputSearch').value;
        dispatch(loadCursos(1, search, setCursosInfo));
    }


    return (
        <>
            <div className="crud-cursos">
                <Row>
                    <Col span={24} style={{marginTop: '10px'}}>
                        <Row justify='end' align='middle' gutter={[0,12]}>
                            <Col xs={12} md={8}>
                                <Button onClick={openAddModal} className='btn-crud-opciones' style={{fontWeight: 'bold'}}>
                                    Crear Curso
                                </Button>
                            </Col>
                            <Col xs={12} md={8} style={{textAlign: 'end'}}>
                                <Search id='inputSearch' size='small' onSearch={handleBusqueda} onPressEnter={handleBusqueda} />
                            </Col>
                            <Col xs={24}  md={8} style={{textAlign: 'end'}}>
                                <Pagination current={currentPage} size='small' simple onChange={handleCambioPagina} pageSize={8} total={cursosInfo.total} responsive={true} />
                            </Col>
                        </Row>
                    </Col>
                       
                    <Col style={{marginTop: '20px'}} span={24}>
                        <Row gutter={[36,24]}>
                            {
                               (cursosInfo.status===true)
                                    ?
                                        cursosInfo.cursos.map ((curso) => {
                                            return (
                                                <Col key={curso._id}className="gutter-row" xs={24} sm={12} md={12} lg={6} >
                                                    <Link to={"/docente/courses/"+curso._id}>
                                                        <TarjetaCursoDocente
                                                        curso={curso} portada={portada} />
                                                    </Link>
                                                </Col>
                                            )
                                        })
                                    :   
                                        ([0,1,2,3,4,5,6,7]).map(n => (
                                            <Col key={n} className="gutter-row" xs={24} sm={12} md={12} lg={6} >
                                                <Skeleton active paragraph={{rows: 8}} />
                                            </Col>
                                        ))
                            }
                            
                        </Row>
                    </Col>
                </Row>
            </div>

            <ModalAddCurso 
                vis={vis}
                setVis={setVis}  
            />
        </>
    )
}
