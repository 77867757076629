import React, { useState } from 'react';
import { Card, Button, Radio, Space, Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { evaluarActivi, evaluarQuiz, viewActividad, currentActividadFinal } from '../../actions/usuarios';
import { ReloadOutlined, StepBackwardOutlined, StepForwardOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';

export const UsuarioQuizScreen = ({current, showModal, actividad, setActividad, setIdAct, getActividad}) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const data = [];
    const [resultados, setResultados] = useState({});

    const handleSelectAnswer = (preguntaId, respuestaId) => {

        const index = data.findIndex(pregunta => pregunta.pregunta === preguntaId);

        index !== -1 ? data[index].respuesta = respuestaId : data.push({pregunta: preguntaId, respuesta: respuestaId});

    }

    const handleEvaluar = () => {
        //Comprobacion de preguntas contestadas
        if(data.length >= actividad.data.preguntas.length){
            dispatch(evaluarQuiz(actividad.actividad._id, data, setResultados));
        }else{
            Swal.fire({
                icon: 'info',
                title: 'Responde el quiz',
                text: 'Debes seleccionar una respuesta para cada pregunta',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: 'var(--primary)',
            });
        }
    }

    const genExtra = (correcta) => {
        return(
            (correcta === true)
               ?
                <div>
                     <h4 style={{color: 'var(--primary)', fontWeight: 'bold'}}>Correcta</h4>
                </div>
                :
                <div>
                     <h4 style={{color: 'red', fontWeight: 'bold'}}>Incorrecta</h4>
                </div>
            
        )
    }

    const handleReintentar = () => {
        setResultados({});
    }

    const handleContinuar = () => {
        //Validacion de test aprobado
        if(typeof resultados.evaluacion !== 'undefined' && resultados.evaluacion){
            //Validación de curso terminado
            if(resultados.actividad_siguiente == null && resultados.terminado){
                //Curso finalizado

                dispatch(evaluarActivi(resultados));
                //Cambiar estado para mostrar vista de finalizado despues de ultima actividad
                dispatch(currentActividadFinal({ endView: true }));
            }else{
                //Siguiente actividad

                dispatch(evaluarActivi(resultados));
                // dispatch(viewActividad(resultados.actividad_siguiente, setActividad, setIdAct));
                actualizarUrl(resultados.actividad_siguiente);
            }
        }
    }

    //Función para actualizar ID de actividad actual en URL
    const actualizarUrl = (id) => {
        history.replace(`./${id}`);
    }

    return (
        <>
        {(resultados.status) &&
        <Col span={24} style={{textAlign: 'center', marginTop: '20px'}}>
            {(resultados.evaluacion === true) ? 
                <h1 style={{color: 'var(--primary)'}}>Aprobaste</h1> : 
                <h1 style={{color: 'red'}}>No Aprobaste</h1>
            }
        </Col>}
        <div className='viewMakeCourse-actividad-content' style={{paddingRight: '3%', paddingLeft: '3%'}}>
            {
                (resultados.status)
                    ?  //Quiz evaluado
                        <>
                        {actividad.data.preguntas.map((pregunta) =>(
                            resultados.evaluacion_data.preguntas.map((res) => (
                                (pregunta._id === res.pregunta) &&
                                    <div key={pregunta._id}>
                                        <Card extra={genExtra(res.correta)} type='inner' title={pregunta.pregunta} className={(res.correta === true) ? 'usuarioQuizScreen-pregunta-correcta' : 'usuarioQuizScreen-pregunta-incorrecta'}  style={{marginBottom: '12px'}}>
                                            <Radio.Group defaultValue={res.respuesta} disabled>
                                                <Space direction='vertical'>
                                                    {pregunta.respuestas.map((respuesta) => (
                                                        <Radio key={respuesta._id} onClick={() => handleSelectAnswer(pregunta._id, respuesta._id)} value={respuesta._id}>{respuesta.respuesta}</Radio>    
                                                    ))
                                                    }
                                                </Space>
                                            </Radio.Group>
                                        </Card>
                                    </div>
                            ))
                        ))}
                        </>
                    : //Quiz sin evaluar
                    <>
                        {actividad.data.preguntas.map((pregunta) => (
                            <div key={pregunta._id}>
                                <Card type='inner' title={pregunta.pregunta}  style={{marginBottom: '12px'}}
                                className='usuarioQuizScreen-pregunta'>
                                    <Radio.Group>
                                        <Space direction='vertical'>
                                            {pregunta.respuestas.map((respuesta) => (
                                                <Radio key={respuesta._id} onClick={() => handleSelectAnswer(pregunta._id, respuesta._id)} value={respuesta._id}>
                                                    {respuesta.respuesta}
                                                </Radio>    
                                            ))}
                                        </Space>
                                    </Radio.Group>
                                </Card>
                            </div>
                        ))} 
                    </>
            }
        </div>
        {(resultados.status) ?
            <Row style={{marginTop: '20px'}}>
                <Col span={24} style={{textAlign: 'center'}}>
                    {
                        (resultados.evaluacion !== true) 
                        ? 
                            <Button onClick={() => handleReintentar()} className='viewMakeCourse-numero-calificar' style={{backgroundColor: 'rgb(50, 162, 255)', border: 'none', color: 'white', width: '200px'}}>
                                Reintentar 
                                <ReloadOutlined />
                            </Button> 
                        : 
                            <Button onClick={()=>handleContinuar()} className='viewMakeCourse-numero-calificar' style={{backgroundColor: 'rgb(50, 162, 255)', border: 'none', color: 'white', width: '200px'}}>
                            Siguiente actividad 
                                <StepForwardOutlined />
                            </Button> 
                    }
                </Col>
            </Row>
        : 
            <Row style={{marginTop: '20px'}}>
                <Col span={24} style={{textAlign: 'center'}}>
                    <Button onClick={handleEvaluar} style={{backgroundColor: 'rgb(50, 162, 255)', border: 'none', color: 'white', width: '200px'}}>Evaluar</Button>
                </Col>
            </Row>
        }
        <div className='viewMakeCourse-activities-buttons'> 
            <div className='d-flex'>
                {(actividad.actividad.prev != null) &&
                <Button className='usuarioTextScreen-btn-prev' onClick={()=>getActividad(true, actividad.actividad.prev, true)}>
                    <StepBackwardOutlined />
                </Button>
                }
                <Button className={(typeof resultados.evaluacion !== 'undefined' && resultados.evaluacion) ? 'usuarioTextScreen-btn-next' : 'usuarioTextScreen-btn-next-disabled'} onClick={()=>handleContinuar()} disabled={(typeof resultados.evaluacion === 'undefined' || resultados.evaluacion === false)}>
                    <StepForwardOutlined />
                    Continuar
                </Button>
            </div>
        </div>      
        </>
    )
}
