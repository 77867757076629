import { Card, Col, DatePicker, Pagination, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import 'moment/locale/es-mx';
import locale from 'antd/es/date-picker/locale/es_ES';
import { loadFinanzasIndex } from '../../actions/usuarios';

export const FinanzasUsuarioScreen = () => {

    const { RangePicker } = DatePicker;

    const dispatch = useDispatch();

    const [currentPag, setCurrentPag] = useState(1);
    const [fechas, setFechas] = useState({
        desde: '',
        hasta: ''
    })

    const [pagos, setPagos] = useState({
        status: false,
        pagos: [],
        total: 6
    });

    useEffect(() => {
        dispatch(loadFinanzasIndex(1, 0, '', '', setPagos));
    }, [dispatch])

    const handleCambioPagina = (pag) => {
        setPagos({
            ...pagos,
            status: false
        });

        setCurrentPag(pag);

        (fechas.desde && fechas.hasta !== '') ? dispatch(loadFinanzasIndex(pag, 1, fechas.desde, fechas.hasta, setPagos)) : dispatch(loadFinanzasIndex(pag, 0, '', '', setPagos)); 
    }

    const handleBusquedaFecha = (value, dateStrings) => {
        if(dateStrings[0] !== '' || dateStrings[1] !== ''){
            setPagos({
                ...pagos,
                status: false
            });
    
            setFechas({
                desde: dateStrings[0],
                hasta: dateStrings[1]
            });
    
            setCurrentPag(1);
    
            dispatch(loadFinanzasIndex(1, 1, dateStrings[0], dateStrings[1], setPagos));
        }else{
            setPagos({
                ...pagos,
                status: false
            });
    
            setFechas({
                desde: '',
                hasta: ''
            });
    
            setCurrentPag(1);
    
            dispatch(loadFinanzasIndex(1, 0, '', '', setPagos));
        }
                
    }

    return (
        <div style={{backgroundColor: 'white', padding: '5vh 12vw 5vh 12vw'}}>
            <Row gutter={[6,12]}>
                <Col span={24}>
                    <h2>Finanzas</h2>
                </Col>
                <Col xs={24} md={16}>
                    <Row justify='end'>
                        <Col>
                            Rango de tiempo: <RangePicker onChange={handleBusquedaFecha} id='rangoFecha' size='small' locale={locale}/>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} md={8} style={{textAlign: 'end'}}>
                    <Pagination current={currentPag} onChange={handleCambioPagina} size='small' total={pagos.total} pageSize={5} showSizeChanger={false}/>
                </Col>
            </Row>
            <Row gutter={[24,24]} style={{marginTop: '20px'}}>
                <Col span={24}>
                    <Card bodyStyle={{padding: '0'}} >
                        {
                            (pagos.status === true)
                                ?
                                    pagos.pagos.map((pago) => {
                                        return(
                                            <div>
                                                <Card.Grid key={pago._id} style={{width: '100%', paddingTop: '10px', paddingBottom: '3px'}}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <h3>{`${pago.data}`}</h3>
                                                        </Col>
                                                        <Col span={12}>
                                                            <p>
                                                                {`Fecha y Hora: ${pago.fecha}`} <br />
                                                                {`Medio de pago: ${(pago.medio === 0) ? 'Tarjeta de credito' : 'Al contado'}`}
                                                            </p>
                                                        </Col>
                                                        <Col span={12}>
                                                            <p>
                                                                Monto:
                                                                <span style={{color: 'var(--primary)'}}> ${pago.monto}</span> 
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Card.Grid> 
                                            </div>
                                        )
                                    })
                                :
                                    [0,1,2,3,4].map((n) => {
                                        return(
                                            <Card.Grid key={n} style={{width: '100%', paddingTop: '20px', paddingBottom: '5px'}}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Skeleton size='small' title={false} active paragraph={{rows: 3}} />
                                                    </Col>
                                                </Row>
                                            </Card.Grid>
                                        )
                                    })
                        }
                    </Card>
                </Col>
            </Row>
            
        </div>
    )
}