import { Button, Modal } from 'antd'
import JoditEditor from 'jodit-react'
import React from 'react';
import { useDispatch } from 'react-redux';
import { crearForo, editarForo } from '../../actions/maestros';

export const ModalAddForo = ({_id,vis,setVis,_id_post,set_Id_post,content,setContent,paginaActual}) => {

    const dispatch = useDispatch();
    //const [content, setContent] = useState(values.post);
    const config = {
		readonly: false,
        removeButtons:'source'
	}
    const handleSubmit=()=>{
        if (_id_post===''){
            dispatch(crearForo(_id,content,paginaActual));
            set_Id_post('');
            setContent('');
            //console.log('creado');  

        }else{
            dispatch(editarForo(_id,_id_post,content,paginaActual));
            console.log('editado'); 
            set_Id_post('');
            setContent('');

        }
        setVis(false);
    }
    
      const handleCancel = () => {
        set_Id_post('');
        setContent('')
        setVis(false);
      };

    return (
        <div>
            
            <Modal visible={vis} footer={null}  onCancel={handleCancel} width={800} >
                {
                    (_id_post==='') ? <h3 style={{textAlign:'center'}}>Nuevo Foro</h3> :  <h3 style={{textAlign:'center'}}>Editar Foro</h3>
                }
            <div style={{marginBottom: '10px'}}>
                
                <JoditEditor
                        value={content}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        //onChange={handleEditor}
                    />
                </div>
                <Button type="primary" htmlType="submit" style={{display:'flex',alignItems:'center',justifyContent:'center',margin:'0 auto',width:'100%',backgroundColor:'var(--primary)',border:'none'}} onClick={handleSubmit}>
                    Aceptar
                </Button>
            </Modal>

        </div>
    )
}
