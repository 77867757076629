import { message } from "antd";
import { fetchConToken } from "../helpers/fetch"

/* Categorias */
export const getCategorias = (setCategorias, {limite, pagina}, setLoaded) => {
    return async() => {
        setLoaded(true);
        const resp = await fetchConToken(`admin/categoria?limite=${limite}&pag=${pagina}&paginacion=true`);
        const body = await resp.json();
    
        const categorias = body.categorias.map( categoria => (
            {
                key: categoria._id,
                ...categoria
            }
        ))

        setCategorias({
            items: categorias,
            total: body.total_categorias
        });

        setLoaded(false);
    }
}

export const getClasificacionCategorias = (setClasificaciones) => {
    return async() => {
        const resp = await fetchConToken(`admin/clasificacion`);
        const body = await resp.json();

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            setClasificaciones(body.clasificaciones);
        }else{
            error();
        }
    }
}

export const agregarCategoria = (nombre, clasificacion_categoria, params, setCategories, setLoading) => {
    return async(dispatch) => {
        console.log(nombre);
        const resp = await fetchConToken('admin/categoria?limite=5&pag=1', {nombre, clasificacion_categoria}, 'POST');
        const body = await resp.json();

        const success = () => {
            dispatch(getCategorias(setCategories, params, setLoading));
            message.info("Categoria agregada exitosamente");
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const editarCategoria = (nombre, clasificacion_categoria, _id, params, setCategories, setLoaded) => {
    return async(dispatch) => {
        const resp = await fetchConToken(`admin/categoria/${_id}`, {nombre, clasificacion_categoria}, 'PUT');
        const body = await resp.json();

        const success = () => {
            dispatch(getCategorias(setCategories, params, setLoaded));
            message.info("Categoría editada exitosamente");
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const eliminarCategoria = (_id, params, setCategories, setLoading) => {
    return async(dispatch) => {
        const resp = await fetchConToken(`admin/categoria/${_id}?limite=5&pag=1`, {}, 'DELETE');
        const body = await resp.json();

        const success = () => {
            dispatch(getCategorias(setCategories, params, setLoading));
            message.info("Categoría eliminada exitosamente");
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

/* Usuarios */
export const getUsuarios = (setUsuarios, {pagina, limite}, setLoading) => {
    return async() => {
        setLoading(true);
        const resp = await fetchConToken(`admin/usuario?limite=${limite}&pag=${pagina}`, 'GET');
        const {msg, usuarios, total_usuarios} = await resp.json();

        const usuariosKey = usuarios.map( item =>
            ( { key: item._id, ...item } )
        );

        const error = () => {
            message.error(msg);
        }

        const success = () => {
            setUsuarios({
                items: usuariosKey,
                total: total_usuarios
            });
            setLoading(false);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const getUser = (id, setVisible, setData) => {
    return async () => {  
        const resp = await fetchConToken(`admin/usuario/${id}`, {}, 'GET');
        const body = await resp.json();

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            setData(body.usuario);
            setVisible(true);
        }else{
            error();
        }
    }
}


export const startNewUser = (params, {email, password, nombre, apellidos},setUsuarios, setLoaded) => {
    return async (dispatch) => {   
        const resp = await fetchConToken('/admin/usuario', {email, password, nombre, apellidos}, 'POST');
        const body = await resp.json();

        const success = () => {
            message.info("Creado exitosamente");
            dispatch(getUsuarios(setUsuarios, params, setLoaded));
        }
        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const startEditUser = (params, id, {email, password, nombre, apellidos}, setUsuarios, passwordReq, setLoaded) => {
    return async (dispatch) => {
        let resp;

        if(passwordReq){
             resp = await fetchConToken(`/admin/usuario/${id}`, {email, password, nombre, apellidos}, 'PUT');
        }else{
            resp = await fetchConToken(`/admin/usuario/${id}`, {email, nombre, apellidos}, 'PUT');
        }  
        const body = await resp.json();

        const success = () => {
            message.info("Editado correctamente");
            dispatch(getUsuarios(setUsuarios, params, setLoaded));
        }
        const error = () => {
            message.error(body.msg);
        }
        
        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}


export const startDeleteUser = (id, setUsuarios, params, setLoaded) => {
    return async (dispatch) => {  
        const resp = await fetchConToken(`/admin/usuario/${id}`, {}, 'DELETE');
        const body = await resp.json();

        const success = () => {
            message.info("Eliminado exitosamente");
            dispatch(getUsuarios(setUsuarios , params, setLoaded));
        }
        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

/* Maestros */
export const getMaestros = (setMaestros, {pagina, search, filter, filter_key}, setLoaded, limite=5) => {
    return async() => {
        setLoaded(true);
        const resp = await fetchConToken(`admin/maestro?limite=${limite}&pag=${pagina}&search=${search}&filter=${filter}&filter_key=${filter_key}`, 'GET');
        const {msg, maestros, total_maestros} = await resp.json();

        const maestrosKey = maestros.map( item =>
            ( { 
                key: item._id, ...item,
                nombre: item.nombre + ' ' + item.apellidos,
                iniciales: item.nombre.charAt(0).toUpperCase() + 
                item.apellidos.charAt(0).toUpperCase(),
            } )
        );

        const error = () => {
            message.error(msg);
        }

        const success = () => {
            setMaestros({
                maestros: maestrosKey,
                total: total_maestros
            });
            setLoaded(false);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}



export const getMaestro = (id, setMaestro) => {
    return async () => {  
        const resp = await fetchConToken(`admin/maestro/${id}`, {}, 'GET');
        const body = await resp.json();

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            setMaestro(body.maestro);
        }else{
            error();
        }
    }
}

export const getCursosMaestro = (id, setCursos, pag=1, setCursosLoaded, limite=8) => {
    return async () => { 
        const resp = await fetchConToken(`admin/maestro/cursos/${id}?limite=${limite}&pag=${pag}`, {}, 'GET');
        const body = await resp.json();

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            setCursos({
                cursos: body.cursos,
                total: body.total_cursos
            });
            setCursosLoaded(true);
        }else{
            error();
        }
    }
}

/* Alumnos */
export const getAlumnos = (setAlumnos, {pagina, search, filter, filter_key}, setLoaded, limite=8) => {
    return async() => {
        setLoaded(true);
        const resp = await fetchConToken(`admin/alumno?limite=${limite}&pag=${pagina}&search=${search}&filter=${filter}&filter_key=${filter_key}`, 'GET');
        const {msg, alumnos, total_alumnos} = await resp.json();

        const alumnosKey = alumnos.map( item =>
            ( { 
                key: item._id, ...item,
                nombre: item.nombre + ' ' + item.apellidos,
                iniciales: item.nombre.charAt(0).toUpperCase() + 
                item.apellidos.charAt(0).toUpperCase(),
            } )
        );

        const error = () => {
            message.error(msg);
        }

        const success = () => {
            setAlumnos({
                alumnos: alumnosKey,
                total: total_alumnos
            });
            setLoaded(false);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const getAlumno = (id, setAlumno) => {
    return async () => {  
        const resp = await fetchConToken(`admin/alumno/${id}`, {}, 'GET');
        const body = await resp.json();

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            setAlumno(body.alumno);
        }else{
            error();
        }
    }
}


/* Cursos */
export const getCursos = (setCursos, {pagina, search, filter, filter_key, limit}, setLoading) => {
    return async() => {
        setLoading(true);
        const resp = await fetchConToken(`admin/curso?limite=${limit}&pag=${pagina}&search=${search}&filter=${filter}&filter_key=${filter_key}`, 'GET');
        const {msg, cursos, total_cursos} = await resp.json();

        const cursosKey = cursos.map( item =>
            ( { 
                key: item._id, ...item,
                maestro_nombre: item.maestro.nombre + ' ' + item.maestro.apellidos
            } )
        );

        const error = () => {
            message.error(msg);
        }

        const success = () => {
            setCursos({
                items: cursosKey,
                total: total_cursos
            });
            setLoading(false);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const getCurso = (id, setCurso, setTemas, setLoading) => {
    return async () => { 
        setLoading(true);
        const resp = await fetchConToken(`admin/curso/${id}`, {}, 'GET');
        const body = await resp.json();

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            setCurso(body.curso);
            setTemas(body.temas);
            setLoading(false);
        }else{
            error();
        }
    }
}

export const getParticipantes = (id, setParticipantes, setLoadingParticipantes, {limit, pag, search = ''}) => {
    return async () => {  
        setLoadingParticipantes(true);
        const resp = await fetchConToken(`admin/curso/usuarios/${id}?limite=${limit}&pag=${pag}&search=${search}`, {}, 'GET');

        const body = await resp.json();

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            setParticipantes({
                items: body.usuarios,
                total: body.total_usuarios
            });
            setLoadingParticipantes(false);
        }else{
            error();
        }
    }
}

export const getValoraciones = (id, setValoraciones, setLoadingValoraciones, {limit, pag, search = ''}) => {
    return async () => {  
        setLoadingValoraciones(true);
        const resp = await fetchConToken(`admin/curso/valoraciones/${id}?limite=${limit}&pag=${pag}&search=${search}`, {}, 'GET');

        const body = await resp.json();

        const error = () => {
            message.error(body.msg);
        }
        
        if(resp.ok === true){
            setValoraciones({
                items: body.valoraciones,
                total: body.total_valoraciones
            });
            setLoadingValoraciones(false);
        }else{
            error();
        }
    }
}

/* Estadísticas */
export const getEstadisticas = (setEstadisticas) => {
    return async () => {  
        const resp = await fetchConToken(`admin/estadisticas/dashboard`, {}, 'GET');
        const body = await resp.json();

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            setEstadisticas({
                totales: body.totales,
                tendencias: body.tendencias,
                ingresos: body.ingresos
            });
        }else{
            error();
        }
    }
}