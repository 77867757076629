import { Avatar, Button, Card, Col, Drawer, Image, Rate, Row,Tag, Collapse, Space } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router';
// eslint-disable-next-line no-unused-vars
import { currentActivity, CursoUsuario, CursoNoUsuario, enrolarseCurso } from '../../actions/usuarios';
import TextArea from 'antd/lib/input/TextArea';
import { ClockCircleOutlined, FileTextFilled, LeftOutlined, MessageFilled, QuestionCircleFilled, TagsOutlined, UserOutlined, PlayCircleFilled, TrophyFilled } from '@ant-design/icons';
import nuevoCurso from '../../assets/nuevoCurso.jpg';
import { Link } from 'react-router-dom';
import { ValoracionesCursoUsuario } from './ValoracionesCursoUsuario';
// eslint-disable-next-line no-unused-vars
import { LoginScreen } from '../auth/LoginScreen';
// eslint-disable-next-line no-unused-vars
import { RegisterScreen } from '../auth/RegisterScreen';

export const ViewCursoUsuario = () => {
    const {id} = useSelector(state => state.auth)
    const history=useHistory()
    const dispatch = useDispatch();
    const {_id}=useParams();
    const {Panel} = Collapse;
    const {curso,enrolado,realizado,actividad_actual} = useSelector(state => state.usuarios.cursoUsuario)
    const {id:usuarioId} = useSelector(state => state.auth)

    // eslint-disable-next-line no-unused-vars
    const [carrito, setCarrito] = useState({carro:false})

    const [coll, setColl] = useState(false);
    const [auth, setAuth] = useState('login');
    const [temas, setTemas] = useState([]);

    const [statusCarga, setStatusCarga] = useState(false);

    useEffect(() => {
        window.scrollTo(0,0)
        //Si hay una sesión iniciada
        if(usuarioId){
            dispatch(CursoUsuario(_id, setStatusCarga, setTemas))
            // dispatch(currentActivity(_id))
        }else{
            dispatch(CursoNoUsuario(_id, setStatusCarga, setTemas))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch,_id])

    const handlePush=(idBoton)=>{
        history.push(`/user/make-course/${_id}/${idBoton}`) 
    }
    const handleEnrolarseGratis=()=>{
        dispatch(enrolarseCurso(_id,setCarrito))       
        history.push(`/user/bienvenida/${_id}`) 
        
    }
    const handleEnrolarsePago=()=>{
        dispatch(enrolarseCurso(_id,setCarrito))
       
        history.push(`/user/carrito`) 

    }
   
    // eslint-disable-next-line no-unused-vars
    const changeAuth = () => {
        auth === 'login' ? setAuth('registro') : setAuth('login');
    }

    const closeDrawer = () => {
        setColl(false);
    }

    const openDrawer=()=>{
        setColl(true); 
    }

    // eslint-disable-next-line no-unused-vars
    const handlePreguntas=()=>{
        history.push(`/user/preguntas/${_id}`)
    }

    const handleAcceder = (value) => {

        if(process.env.REACT_APP_ENV === 'BETA'){
            if(value === 'iivvo'){
                window.location = 'https://beta.iivvo.com';
            }
            if(value === 'psicometricos'){
                window.location = 'https://beta.psicometricos.com';
            }
        }else{
            if(value === 'iivvo'){
                window.location = 'https://app.iivvo.com';
            }
            if(value === 'psicometricos'){
                window.location = 'https://www.psicometricos.com';
            }
        }

    }

    const handleCertificado=(id_curso)=>{
        const token = localStorage.getItem('token') || '';
        const  myHeaders = new Headers();
        myHeaders.append("x-token", token)

        const  requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const baseUrl = process.env.REACT_APP_API_URL;

        fetch(`${baseUrl}/curso/cert/${id_curso}`, requestOptions)
        .then(result => {
          if (result.status !== 200){
            throw new Error("Error de servidor");
          }

          return result.blob();
        })
        .then(data => {
          
          const url = window.URL.createObjectURL(data);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = 'Certificado';
          anchor.click();
  
          // CLEAN UP
          window.URL.revokeObjectURL(url);
          document.removeChild(anchor);
        })
        .catch(error => console.log('error', error));
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', padding: '60px 5% 60px 5%'}}>
        {
            (statusCarga===true && curso.nombre) && (
                <div>
                    <div style={{marginBottom:'5px'}}>
                        <Row>
                            <Col xs={24} sm={24} lg={(realizado) ? 16 : 18} className="gutter-row">
                            {
                                (id) ? (
                                    <div style={{marginBottom:'5px'}}>
                                        <Link to={`/home`}>
                                            <Row>
                                                <Col xs={24} sm={24} lg={8} className="gutter-row">
                                                    <div style={{color:'var(--primary)',fontSize:'20px',fontWeight:'bold',display:'inline'}}><LeftOutlined className="icon-atras" />Ir a pagina principal</div>
                                                </Col>
                                            </Row>
                                        </Link>
                                    </div>
                                )
                                : (
                                    <div>
                                        <Link to={`/home`}>
                                            <div className="btn-atras"><LeftOutlined className="icon-atras" />Regresar a la pagina principal</div>
                                        </Link>
                                    </div>

                                )
                            }
                            </Col>
                            {(realizado) &&
                            <Col className="gutter-row" xs={8} sm={8} lg={2} style={{textAlign: 'center'}}>
                                <h5 style={{color:'grey'}}>Curso completo</h5>
                                <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center', color: 'var(--primary)'}} >
                                    <TrophyFilled onClick={()=>handleCertificado(curso._id)} style={{fontSize: '25px'}}/>
                                </div>
                            </Col>
                            }
                            <Col className="gutter-row" xs={(realizado) ? 8 : 12} sm={(realizado) ? 8 : 12} lg={4} style={{textAlign: 'center'}}>
                                <div style={{paddingLeft: '5px'}}>
                                    <div style={{background: 'white', borderRadius: '10px', padding: '10px'}}>
                                        <h5 style={{color:'grey'}}>Calificacion</h5>

                                        <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center'}}>
                                            <span><h3>{curso.rate}</h3></span>
                                            <Rate
                                            disabled 
                                            allowHalf  
                                            value={Number(curso.rate)} 
                                            style={{fontSize: '14px', color: 'orange', margin: '0px 5px 0px 5px'}} />
                                            <span>({curso.no_evaluaciones})</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={(realizado) ? 8 : 12} sm={(realizado) ? 8 : 12} lg={2} style={{textAlign: 'center'}}>
                                <div style={{paddingLeft: '5px'}}>
                                    <div style={{background: 'white', borderRadius: '10px', padding: '10px'}}>
                                        <h5 style={{color:'grey'}}>Participantes</h5>
                                        <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center'}} >
                                            <UserOutlined style={{fontSize: '25px'}}/>
                                            <h3>{curso.no_participantes}</h3>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    
                    <Row gutter={[36, 36]}>
                        <Col style={{marginTop: '10px'}} xs={24} sm={24} md={8} lg={6} className="gutter-row">
                            <Card
                                hoverable
                                className='card-view-curso'
                                cover={
                                    <div className='image-container'>
                                        <Image preview={false} width={'100%'} height={'100%'} src={curso.img===null ? 'error' : `${curso.img}`} fallback={nuevoCurso} />
                                    </div>
                                }
                            >

                                {//Botón variable para inscribirse o ir al cursoUsuario  
                                    (enrolado===true) ? (<Button className='btn-view-curso' onClick={()=>handlePush(actividad_actual)} block>Ir al curso </Button>)
                                    :
                                    
                                    (enrolado===false && Number(curso.precio)===0 && !id) ?  (<Button  className='btn-view-curso' onClick={openDrawer} block>Iniciar sesion para inscribirse</Button>) :
                                    
                                    (enrolado===false && Number(curso.precio)===0) ?  (<Button className='btn-view-curso' onClick={handleEnrolarseGratis}  block>Inscribirse</Button>) :

                                    //AQUI ABAJO VAMOS A PONER LA RUTA PARA BAJAR EL CARRITO
                                    (enrolado===false && Number(curso.precio)>0) &&  (<Button className='btn-view-curso' onClick={handleEnrolarsePago}  block>Inscribirse {`${Number(curso.precio)} $`}</Button>)
                                    
                                }

                                <h3 className='info-view-curso'>Información del curso:</h3>
                                <div>
                                <Row gutter={12}>
                                    <Col xs={24} sm={24} md={12} lg={24} className="gutter-row">

                                        <Row style={{borderBottom:'1px solid #DCE1D6'}}>
                                            <Col span={12}>
                                                <Row >
                                                    <Col span={4}  style={{paddingTop:'10%'}}>
                                                        <div style={{color: 'var(--primary)', width: '50%'}}><ClockCircleOutlined /></div>
                                                    </Col>
                                                    <Col span={20}  style={{paddingTop:'10%'}}>
                                                        <div><p style={{color:'grey'}}>Duración</p></div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col style={{textAlign: 'right'}} span={12}>
                                                <div  style={{paddingTop:'10%'}}>
                                                    <span>{curso.caracteristicas.duracion}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        

                                        <Row style={{borderBottom:'1px solid #DCE1D6'}}>
                                            <Col span={12} >
                                                <Row>
                                                    <Col span={4} style={{paddingTop:'10%'}}>
                                                        <div style={{color: 'var(--primary)', width: '50%'}}><FileTextFilled /></div>
                                                    </Col>
                                                    <Col span={20} style={{paddingTop:'10%'}}>
                                                        <div ><p style={{color:'grey'}}>Textos</p></div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col style={{textAlign: 'right'}} span={12}>
                                                <div style={{paddingTop:'10%'}}>
                                                    <span>{curso.caracteristicas.textos}</span>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row style={{borderBottom:'1px solid #DCE1D6'}}>
                                            <Col span={12}>
                                                <Row>
                                                    <Col span={4}  style={{paddingTop:'10%'}}>
                                                        <div style={{color: 'var(--primary)', width: '50%'}}><QuestionCircleFilled /></div>
                                                    </Col>
                                                    <Col span={20}  style={{paddingTop:'10%'}}>
                                                        <div><p style={{color:'grey'}}>Quizzes</p></div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col style={{textAlign: 'right'}} span={12}>
                                                <div  style={{paddingTop:'10%'}}><span>{curso.caracteristicas.quizzes}</span></div>
                                            </Col>
                                        </Row>

                                        <Row style={{borderBottom:'1px solid #DCE1D6'}}>
                                            <Col span={12}>
                                                <Row>
                                                    <Col span={4}  style={{paddingTop:'10%'}}>
                                                        <div style={{color: 'var(--primary)', width: '50%'}}><PlayCircleFilled /></div>
                                                    </Col>
                                                    <Col span={20}  style={{paddingTop:'10%'}}>
                                                        <div><p style={{color:'grey'}}>Videos</p></div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col style={{textAlign: 'right'}} span={12}>
                                                <div  style={{paddingTop:'10%'}}><span>{curso.caracteristicas.videos}</span></div>
                                            </Col>
                                        </Row>

                                        <Row style={{borderBottom:'1px solid #DCE1D6'}}>
                                            <Col span={12}>
                                                <Row>
                                                    <Col span={4} style={{paddingTop:'10%'}} >
                                                        <div style={{color: 'var(--primary)', width: '50%'}}><MessageFilled /></div>
                                                    </Col>
                                                    <Col span={20} style={{paddingTop:'10%'}} >
                                                        <div><p style={{color:'grey'}}>Foros</p></div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col style={{textAlign: 'right'}} span={12} >
                                                <div  style={{paddingTop:'10%'}}>{curso.caracteristicas.foros}</div>
                                            </Col>
                                        </Row>

                                        
                                    </Col>

                                    <Col xs={24} sm={24} md={12} lg={24} className="gutter-row">
                                        
                                        {
                                            (curso.caracteristicas.categorias.length>0) && (
                                                <div>
                                                <Row>
                                                <Col span={12}>
                                                    <Row>
                                                        <Col span={4}  style={{paddingTop:'10%'}}>
                                                            <div style={{color: 'var(--primary)', width: '50%'}}><TagsOutlined /></div>
                                                        </Col>
                                                        <Col span={20}  style={{paddingTop:'10%'}}>
                                                            <div><p style={{color:'grey'}}>Categorias</p></div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            
                                            <Row>
                                                {
                                                    curso.caracteristicas.categorias.map(categoria => (
                                                        
                                                        <Tag 
                                                            key={categoria._id} 
                                                            //closable 
                                                            id={categoria._id}
                                                            style={{backgroundColor: 'var(--primary)', color: 'white'}}
                                                            //onClose={() => {handleDeleteCategoria(categoria._id)}}
                                                        >
                                                            {categoria.nombre}
                                                        </Tag>
                                                    ))
                                                }
                                            </Row>
                                            </div>
                                                
                                            )
                                        }
                                        
                                    </Col>
                                </Row>
                                </div>
                            </Card>    
                        </Col>

                        <Col xs={24} sm={24} md={16} lg={18} className="gutter-row">
                            <div style={{background: 'white', borderRadius: '10px', padding: '10px'}}>
                                <h1 style={{display: 'block'}} id="nombre" className="titulo-view-curso" >{ curso.nombre }</h1>

                                <h2 className='subtitulo-view-curso'>Descripción</h2>
                                <TextArea value={curso.descripcion} className='desc-view-curso' bordered={false}  autoSize={true} disabled={true}/>

                                <h2 className='subtitulo-view-curso'>Temario del curso</h2>
                                {(temas.length > 0 ) && (
                                    <>
                                        <Collapse	
                                            expandIconPosition="left"
                                            accordion
                                        >
                                            {
                                                temas.map(tema => (
                                                    <Panel
                                                        bodyStyle={{padding: '0', overlow: 'hidden'}}
                                                        header={tema.nombre}
                                                        key={tema._id}
                                                    >    
                                                        {tema.actividades.map(actividad => (
                                                        <Card 
                                                            key={actividad._id}
                                                            bodyStyle={{ padding:'5px 50px', height: 'inherit',display: 'flex'}} 
                                                            style={{height: '35px'}} 
                                                        >
                                                            <div style={{width: '75%'}}>
                                                                <div 
                                                                    className='nombre-actividad'
                                                                >
                                                                    {actividad.nombre}
                                                                </div>
                                                            </div> 

                                                            <div style={{ display:'flex',alignItems:'end',justifyContent: 'end',paddingTop:'2px',margin:'auto'}}>
                                                                {(actividad.tipo === 0) && <FileTextFilled style={{ fontSize: '1.1em', color: 'grey' }} />}
                                                                {(actividad.tipo === 1) && <QuestionCircleFilled style={{ fontSize: '1em', color: 'grey' }} />}
                                                                {(actividad.tipo === 2) && <PlayCircleFilled style={{ fontSize: '1em', color: 'grey' }} />}
                                                            </div>
                                                        </Card>
                                                        ))}
                                                    </Panel>
                                    
                                                ))
                                            }             
                                        </Collapse>
                                    </>
                                )}

                                <h2 className='subtitulo-view-curso'>Valoraciones del curso</h2>
                                <ValoracionesCursoUsuario _id={_id}/>

                                <h2 className='subtitulo-view-curso'>Instructor</h2>
                                <div style={{display:'flex',alignItems:'center',justifyContent:'left'}}>
                                    <Avatar size={64} src={curso.maestro.img===null ? 'error' : `${curso.maestro.img}`} fallback={<UserOutlined />} style={{ marginLeft:'5px',marginRight:'5px',fontSize:'10px',border:'2px solid #46b419'}} /> 
                                        <div style={{float:'right'}}>
                                            <span style={{fontSize:'25px'}}> {curso.maestro.nombre} {curso.maestro.apellidos}</span><br></br>
                                            <span style={{fontSize:'18px', color:'grey'}}> {curso.maestro.profesion}</span>
                                        </div>
                                </div>
                            </div>
                        </Col>
                    </Row>


                     {/* <Row gutter={12} >
                        <Col style={{marginTop: '25px'}} xs={24} sm={24} lg={6} className="gutter-row">
                            <Row gutter={24}>
                                    

                                <Col style={{marginTop: '5px'}} xs={24} sm={10} md={6} lg={24} className="gutter-row">

                                    <div style={{margin: 'auto', marginTop: '15px'}}>
                                        
                                        <Button  className="btn-preg-resp" block>Compartir</Button>
                                    </div>

                                    {
                                        (id && curso.preguntas_y_respuestas===true && enrolado===true) && (
                                            <div style={{margin: 'auto', marginTop: '15px'}}>
                                        
                                                <Button style={{backgroundColor: 'var(--primary)', color: 'white', fontWeight: 'bold'}} block onClick={handlePreguntas}>Preguntas y respuestas </Button>
                                            </div>
                                        )
                                    }
                                    
                                </Col>
                            </Row>
                        </Col>
                        <Col style={{textAlign: 'center'}} xs={24} sm={24} lg={18} className="gutter-row">
                            <h1 style={{color: 'grey',fontSize:'30px', marginTop: '50px'}}>Contenido</h1>
                            <UsuarioTemasIndex _id={_id} /> 
                        </Col>
                       
                    </Row>
                         */}

                    <Row>
                        <Col xs={24} sm={24}  lg={6} className="gutter-row"></Col>
                    </Row>
                    
                    <Drawer visible={coll} onClose={closeDrawer} closable={false} width={window.innerWidth > 767 ? '405px' : '95%'}>
                        <div>
                            {/* {
                                auth === 'login' 
                                ?
                                <div style={{padding: '25px'}}>
                                    <h1 style={{color: 'var(--primary)'}}>Login</h1>
                                    <LoginScreen changeAuth={changeAuth} closeDrawer={closeDrawer}/>
                                </div>

                                : auth === 'registro' &&
                                    
                                <div style={{padding: '25px'}}>
                                    <h1 style={{color: 'var(--primary)'}}>Registro</h1>
                                    <RegisterScreen changeAuth={changeAuth} closeDrawer={closeDrawer}/>
                                </div>
                            } */}
                            <div style={{padding: '25px'}}>
                                <h1 style={{color: 'var(--primary)'}}>Acceder</h1>
                                <Space
                                    direction="vertical"
                                    size="middle"
                                    style={{
                                    display: 'flex',
                                    }}
                                >
                                    <Button onClick={() => handleAcceder('iivvo')} type="primary" shape="round" block={true} className="acceder-btn">
                                        iiVVO
                                    </Button>
                                    <Button onClick={() => handleAcceder('psicometricos')} type="primary" shape="round" block={true} className="acceder-btn">
                                        Psicometricos
                                    </Button>
                                </Space>
                            </div>
                        </div>
                
                    </Drawer>

                </div>
            )
        }
        </div>
    )  
}
