import React from 'react';

export const AdminHomeScreen = () => {

    return (
        <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h1>Bienvenido</h1>
        </div>  
    )
}
