import React, { useEffect, useState } from 'react';
import { Avatar, Button, Col, List, Pagination, Row, Skeleton } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { eliminarForoUsuario, postsUsuarioLoading } from '../../actions/usuarios';
import { useParams } from 'react-router-dom';
import { Button as FloatingButton,Container } from 'react-floating-action-button';
import { ModalAddForoUsuario } from './ModalAddForoUsuario';

export const ForoUsuarioScreen = () => {
    //_id- el id del tema 
    const dispatch = useDispatch();
    const {_id}=useParams();
    const {posteos} = useSelector(state => state.usuarios)

    const [statusCarga, setStatusCarga] = useState({
        status:false,
        total:5
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [paginaActual, setpaginaActual] = useState(1);
    const [content, setContent] = useState('');
    
    const [_id_post,set_Id_post]= useState('')

    
    useEffect(() => {
        window.scrollTo(0,0)
        dispatch(postsUsuarioLoading(_id,1,setStatusCarga))
    }, [dispatch,_id])

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleDelete=(val)=>{
        dispatch(eliminarForoUsuario(_id,val,paginaActual))
    }
    const handleEditar=(_id,post)=>{
        //console.log(_id,post);
        set_Id_post(_id)
        setContent(post);
        showModal();
    }
    const handleCambioPagina = (pagina) => {
        window.scrollTo(0,0)
        setStatusCarga(false)
        setpaginaActual(pagina)
        dispatch(postsUsuarioLoading(_id,pagina,setStatusCarga));
    }
    return (
        <div style={{padding:'2% 5%',backgroundColor:'white'}}>
            {
                (statusCarga.status===true) 
                ?
               
                <div>
                    <div style={{backgroundColor:'#EDF1E7',margin:' 25px 50px'}}>
                        {`Mostrando del ${posteos.desde} al ${posteos.hasta} de ${posteos.total_posts} posts totales  `}
                    </div>
               
                    <List
                    style={{margin:' 20px 40px'}}
                    bordered={true}
                    itemLayout="vertical"
                    size="large"
                    dataSource={posteos.posts}
                    
                    renderItem={item => (
                        
                    <List.Item >
                        
                        <Row gutter={[16,16]}>
                            
                            <Col xs={24} md={12} className="gutter-row" >
    
                                    
                                <List.Item.Meta
                                avatar={<Avatar src={item.usuario.img}/>}
                                title={` De: ${item.usuario.nombre} ${item.usuario.apellidos}`}
                                />
    
                            </Col>
                            <Col xs={24} s={24} md={12}  className="gutter-row" >
                                <div style={{display:'flex', alignItems:'center', justifyContent:'center',marginBottom:'5px'}} >
                                    {
                                        (item.owner===true) && (
                                            <div>
                                                <Button type="primary" danger onClick={()=>handleDelete(item._id)}>
                                                    <DeleteOutlined /> Eliminar foro
                                                    </Button>
                                                
                                                    <Button style={{backgroundColor:'var(--primary)',marginLeft:'5px',border:'none'}} type="primary" onClick={()=>handleEditar(item._id,item.post)} >
                                                    <EditOutlined /> Editar foro
                                                </Button>
                                            </div>
                                        )
                                    }
                                </div>
                            
                            </Col>
                        </Row>
                    
    
                        <Row>
                            <Col span={24}>
                                <div style={{marginBottom: '10px'}}>
                                    
                                        <textarea rows={4} className="forotxt"  value={item.post.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "")} style={{resize:'none',border:'none',fontSize:'18px',color:'grey',width:'100%'}} readOnly/>
                                </div>
                            </Col>
                        </Row>
                        
                    </List.Item>
                    
                    )}
                    />
                </div> 
             :
             <Skeleton active avatar/>
            }
            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <Pagination defaultCurrent={paginaActual} onChange={handleCambioPagina} pageSize={5} total={statusCarga.total} responsive={true} />
            </div>
    


        <Container>
                <FloatingButton onClick={showModal} styles={{backgroundColor: 'var(--primary)', color: 'white'}}><PlusOutlined style={{fontSize: '20px'}} /></FloatingButton>
        </Container>

        <ModalAddForoUsuario paginaActual={paginaActual} _id={_id} _id_post={_id_post} content={content} setContent={setContent} set_Id_post={set_Id_post} vis={isModalVisible} setVis={setIsModalVisible}/>
                
        
       
        </div>  
    )
}
