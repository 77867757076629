import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Button } from 'antd'
import { CaretRightOutlined, PauseOutlined, AudioOutlined, AudioMutedOutlined, BackwardOutlined, ForwardOutlined, ReloadOutlined, ExpandOutlined } from '@ant-design/icons'

import { VideoTimeIndicator } from './VideoTimeIndicator'

export const VideoControls = ({ duration, enabledBackward, enabledForward, endedAction, loaded, muted, played, playing, volume, volumeHover, handlePlayPause, handleSeekMouseDown, handleSeekChange, handleSeekMouseUp, handleBackward, handleForward, handleReset, handleVolumeChange, handleToggleMuted, handleVolumeHover, handleVolumeNotHover, handleClickFullscreen }) => {
  return (
    <div className='usuarioVideoScreen-controls-container w-100'>
        <Row justify='center'>
            <Col md={24} className='p-relative'>
                <input
                    type='range'
                    min={0}
                    max={0.999999}
                    step='any'
                    value={played}
                    onMouseDown={handleSeekMouseDown}
                    onChange={handleSeekChange}
                    onMouseUp={handleSeekMouseUp}
                    className='usuarioVideoScreen-video-range w-100 p-relative'
                    style={{zIndex: '3'}}
                />
                <div className='usuarioVideoScreen-video-range-container' style={{zIndex: '2'}}>
                    <div className='usuarioVideoScreen-video-range-progress' style={{width: `${ played * 100 }%`}}></div>
                </div>
                <div className='usuarioVideoScreen-video-range-container' style={{zIndex: '1'}}>
                    <div className='usuarioVideoScreen-video-range-loaded' style={{width: `${ loaded * 100 }%`}}></div>
                </div>
                <div className='usuarioVideoScreen-video-range-container' style={{zIndex: '0'}}>
                    <div className='usuarioVideoScreen-video-range-full'></div>
                </div>
            </Col>
        </Row>
        <Row justify='center'>
            <Col md={24}>
                <Button className={(!enabledBackward) ? 'usuarioTextScreen-btn-player usuarioTextScreen-btn-player-disabled' : 'usuarioTextScreen-btn-player'} onClick={handleBackward} disabled={!enabledBackward}>
                    <BackwardOutlined className='usuarioVideoScreen-controls-button-icon' />
                </Button>
                { (!endedAction)
                    ?  <Button className='usuarioTextScreen-btn-player' onClick={handlePlayPause}>
                            { (!playing)
                                ? <CaretRightOutlined className='usuarioVideoScreen-controls-button-icon' />
                                : <PauseOutlined className='usuarioVideoScreen-controls-button-icon' />
                            }
                        </Button>
                    :   <Button className='usuarioTextScreen-btn-player' onClick={handleReset}>
                            <ReloadOutlined className='usuarioVideoScreen-controls-button-icon' />
                        </Button>
                }
                <Button className={(!enabledForward) ? 'usuarioTextScreen-btn-player usuarioTextScreen-btn-player-disabled' : 'usuarioTextScreen-btn-player'} onClick={handleForward} disabled={!enabledForward}>
                    <ForwardOutlined className='usuarioVideoScreen-controls-button-icon' />
                </Button>
                <div style={{display: 'inline'}} onMouseOver={handleVolumeHover} onMouseLeave={handleVolumeNotHover}>
                    <Button className='usuarioTextScreen-btn-player' onClick={handleToggleMuted}>
                        { (!muted)
                            ? <AudioOutlined className='usuarioVideoScreen-controls-button-icon' />
                            : <AudioMutedOutlined className='usuarioVideoScreen-controls-button-icon' />
                        }
                    </Button>
                    { (!muted && volumeHover)
                        &&  <div style={{display: 'inline-block', position: 'relative', verticalAlign: 'top'}}>
                                <div className='h-100 centrar-v'>
                                    <input type='range' min={0} max={1} step='any' value={volume} onChange={handleVolumeChange} className='usuarioVideoScreen-video-range-volume p-relative' style={{zIndex: '2'}} />
                                    <div className='usuarioVideoScreen-video-range-container' style={{zIndex: '0'}}>
                                        <div className='usuarioVideoScreen-video-range-progress-volume' style={{width: `${ volume * 100 }%`}}></div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                <Button className='usuarioTextScreen-btn-player float-r' onClick={handleClickFullscreen}>
                    <ExpandOutlined className='usuarioVideoScreen-controls-button-icon' />
                </Button>
                <VideoTimeIndicator duration={duration} played={played} />
            </Col>
        </Row>
    </div>
  )
}

VideoControls.propTypes = {
    duration: PropTypes.number.isRequired,
    enabledBackward: PropTypes.bool.isRequired,
    enabledForward: PropTypes.bool.isRequired,
    endedAction: PropTypes.bool.isRequired,
    loaded: PropTypes.number.isRequired,
    muted: PropTypes.bool.isRequired,
    played: PropTypes.number.isRequired,
    playing: PropTypes.bool.isRequired,
    volume: PropTypes.number.isRequired,
    volumeHover: PropTypes.bool.isRequired,
    handlePlayPause: PropTypes.func.isRequired,
    handleSeekMouseDown: PropTypes.func.isRequired,
    handleSeekChange: PropTypes.func.isRequired,
    handleSeekMouseUp: PropTypes.func.isRequired,
    handleBackward: PropTypes.func.isRequired,
    handleForward: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleVolumeChange: PropTypes.func.isRequired,
    handleToggleMuted: PropTypes.func.isRequired,
    handleVolumeHover: PropTypes.func.isRequired,
    handleVolumeNotHover: PropTypes.func.isRequired,
    handleClickFullscreen: PropTypes.func.isRequired,
}