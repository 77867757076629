import { message } from "antd";
import { fetchConToken, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from "sweetalert2";


export const eventSetActive=(event)=>({//llamaremos esta accion cuando se haya grabado correctamente en la base de datos
    type:types.eventSetActive,
    payload:event
});
export const eventClearActiveEvent=()=>({
    type:types.eventClearActiveEvent
});

export const usuariosStartLoading=(_id)=>{
    return async(dispatch)=>{
        try {
            const resp=await fetchConToken(`usuario/${_id}`);
            const body=await resp.json();
            console.log(body);

            const usuarios= body.usuario;
            console.log(usuarios);
           // console.log(maestros);
            dispatch(usuariosLoaded(usuarios));
            
        } catch (error){
            console.log(error);
            
        }
    }
}
const usuariosLoaded=(usuarios)=>({
    type:types.usuariosLoaded,
    payload:usuarios
});

export const changePasswordUsuario=(_id,{old_password,password,confirmacion})=>{
    return async()=>{
        const resp=await fetchConToken(`password/${_id}`,{old_password,password,confirmacion},'PUT');
        const body=await resp.json();
        const success = () => {
            message.info("Su contraseña ha sido actualizada con exito");
        }

        const error = () => {
            message.error(body.msg);
        }
        if (resp.ok===true){
            success();
        }else{
            error();
        }
    }
}

export const editarUsuario=(_id,{nombre,apellidos},img)=>{

    return async(dispatch)=>{
        Swal.fire({
            title: 'Por favor espere...',
            text: 'Actualizando contenido',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        const url = `https://apibcoursera.iivvo.com/api/usuario/${_id}`;
        const token = localStorage.getItem('token') || '';

        const formData=new FormData();
        formData.append('nombre',nombre);
        formData.append('apellidos',apellidos);
        formData.append('img',img);
        try {
            const resp=await fetch( url, {
                
                method:'PUT',
                headers: {
                    'x-token': token
                },
                body: formData,
                redirect: 'follow'
            });
            
            if (resp.ok===true){
                dispatch(usuariosStartLoading(_id))
                Swal.fire({
                    icon: 'success',
                    title: 'Contenido actualizado',
                    showConfirmButton: false,
                    timer: 1200
                })
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Ocurrió un error al actualizar el contenido :('
                })
            }
        
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error
            })
        }
       
    }
}

/* Maestros */
export const getMaestros = (setMaestros, {pagina, search, filter, filter_key}, setLoaded, limite=8) => {
    return async() => {
        setLoaded(true);
        const resp = await fetchSinToken(`maestro?limite=${limite}&pag=${pagina}&search=${search}&filter=${filter}&filter_key=${filter_key}`, 'GET');
        const {msg, maestros, total_maestros} = await resp.json();

        const error = () => {
            message.error(msg);
        }

        const success = () => {
            const maestrosKey = maestros.map( item =>
                ( { 
                    key: item._id, ...item,
                    nombre: item.nombre + ' ' + item.apellidos,
                    iniciales: item.nombre.charAt(0).toUpperCase() + 
                    item.apellidos.charAt(0).toUpperCase(),
                } )
            );
            setMaestros({
                maestros: maestrosKey,
                total: total_maestros
            });
            setLoaded(false);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

//Busqueda y categorias
export const loadCategoriasUsuario = (setCategorias) => {
    return async() => {
        const resp = await fetchSinToken('categoria');
        const body = await resp.json();
        
        const success = () => {
            setCategorias(body.categorias);
        }

        const error = () => {

        }

        if(resp.ok===true){
            success();
        }else{
            error();
        }
    }
}

export const getMaestro = (id, setMaestro) => {
    return async () => {  
        const resp = await fetchSinToken(`maestro/${id}`, {}, 'GET');
        const body = await resp.json();

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            setMaestro(body.maestro);
        }else{
            error();
        }
    }
}

export const getCursosMaestro = (id, setCursos, pag=1, setCursosLoaded, limite=8) => {
    return async () => {  
        setCursosLoaded(false);
        const resp = await fetchSinToken(`maestro/cursos/${id}?limite=${limite}&pag=${pag}`, {}, 'GET');
        const body = await resp.json();

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            setCursos({
                cursos: body.cursos,
                total: body.total_cursos
            });
            setCursosLoaded(true);
        }else{
            error();
        }
    }
}

/* continuacion de usuario */

export const CursoUsuario=(_id, setStatusCarga, setTemas)=>{
    return async(dispatch)=>{

        try {
            const resp=await fetchConToken(`curso/${_id}`);
            const body=await resp.json();

            dispatch(cursoUsuarioLoaded(body)); 
            setStatusCarga(body.status) 
            setTemas(body.temas) 
            
        } catch (error){
            console.log(error);
            
        }
    }
}
export const CursoNoUsuario=(_id, setStatusCarga, setTemas)=>{
    return async(dispatch)=>{

        try {
            const resp=await fetchConToken(`curso/${_id}`);
            const body=await resp.json();
            
            dispatch(cursoUsuarioLoaded(body)); 
            setStatusCarga(body.status) 
            setTemas(body.temas) 
            
        } catch (error){
            console.log(error);
            
        }
    }
}
const cursoUsuarioLoaded=(curso)=>({
    type:types.cursoUsuarioLoaded,
    payload:curso
});

export const valoracionesCursoUsuario=(_id,num_pag,setStatusCarga)=>{
    return async(dispatch)=>{

        try {
            const resp=await fetchSinToken(`curso/valoraciones/${_id}?limite=8&pag=${num_pag}`);
            const body=await resp.json();
            
            dispatch(valoracionesUsuario(body)); 
            setStatusCarga({
                status:body.status,
                total:body.total_valoraciones
            })
              
            
        } catch (error){
            console.log(error);
            
        }
    }
}
const valoracionesUsuario=(valoracion)=>({
    type:types.valoracionesLoaded,
    payload:valoracion
});

//Cursos Usuario
export const loadCursosUsuario = (pag, params, setCursosInfo) => {
    return async() => {
        const resp = await fetchSinToken(`curso?limite=8&pag=${pag}${params}`);
        const body = await resp.json();
        //console.log(body);
        
        const success = () => {
            setCursosInfo({
                status: true,
                cursos: body.cursos,
                total: body.total_cursos
            });
        }

        const error = () => {

        }

        if(resp.ok===true){
            success();
        }else{
            error();
        }

    }
}
export const enrolarseCurso=(_id,setCarrito)=>{
    return async()=>{

        try {
            const resp=await fetchConToken(`curso/enrolarse/${_id}`,{},'POST');
            const body=await resp.json();
            //console.log(body);//la vista de la actividad
           
            if (resp.ok){
                setCarrito({carro:body.carrito})
            } 
        } catch (error){
            console.log(error);
            
        }
    }
}
export const cursoBienvenida=(_id)=>{
    return async(dispatch)=>{

        try {
            const resp=await fetchConToken(`curso/bienvenida/${_id}`);
            const body=await resp.json();
           // console.log(body);//la vista de la actividad
            
            if (resp.ok){
                dispatch(bienvenidaUsuario(body))
               
            } 
        } catch (error){
            console.log(error);
            
        }
    }
}
const bienvenidaUsuario=(bienvenida)=>({
    type:types.bienvenidaUsuario,
    payload:bienvenida
});

export const agregarCarrito=()=>{
    return async(dispatch)=>{

        try {
            const resp=await fetchConToken(`carrito`);
            const body=await resp.json();
            //console.log(body);//la vista de la actividad
            if (resp.ok){
                dispatch(carritoUsuario(body))
               
            } 
        } catch (error){
            console.log(error);
            
        }
    }
}
const carritoUsuario=(car)=>({
    type:types.carritoUsuario,
    payload:car
});

export const pagarCarrito=(data,setStatusPago)=>{
    return async(dispatch)=>{
        const resp=await fetchConToken(`carrito/pagar`,{data},'POST');
        const body=await resp.json();
        const success = () => {
            message.info("Su pago ha sido procesado con exito");
        }

        const error = () => {
            message.error(body.msg);
        }
        if (resp.ok===true){
            success();
            setStatusPago({
                status:body.status
            })
            dispatch(limpiarAgregarCarrito())

        }else{
            error();
        }
    }
}
const  limpiarAgregarCarrito= () => ({
    type: types.resetCarritoUsuario
});
export const cursosUsuario=(setCursosInfo,pagina)=>{
    return async()=>{
        const resp=await fetchConToken(`miaprendizaje?limite=8&pag=${pagina}`);
        const body=await resp.json();
        //console.log(body);
    
        const error = () => {
            message.error(body.msg);
        }
        if (resp.ok===true){
            setCursosInfo({
                status: body.status,
                cursos: body.cursos,
                total: body.total_cursos
            });
        }else{
            error();
        }
    }
}

export const courseTracker=(_id,setStatusCarga)=>{
    return async(dispatch)=>{

        try {
            const resp=await fetchConToken(`curso/tracker/${_id}`);
            const body=await resp.json();
            //console.log(body);//la vista de la actividad
            
            if (resp.ok){
                dispatch(cursoTracker(body));
               
            } 
        } catch (error){
            console.log(error);
            
        }
    }
}

const cursoTracker=(course)=>({
    type:types.courseTracker,
    payload:course
});

/*Preguntas y respuestas*/
export const getPreguntas=(id, setPreguntas, pag=1, limite=5)=>{
    return async(dispatch)=>{
        const resp=await fetchConToken(`preguntas-respuestas/${id}?limite=${limite}&pag=${pag}`);
        const body=await resp.json();

        const success = () => {  
            setPreguntas({
                pregunta: body.preguntas,
                total: body.total_preguntas 
            });
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const borrarPregunta=(_id, preguntaId, setPreguntas)=>{
    return async(dispatch)=>{
        const resp=await fetchConToken(`pregunta-respuesta/${preguntaId}`, {}, 'DELETE');
        const body=await resp.json();

        const success = () => {  
            message.info("Eliminado correctamente");
            dispatch(getPreguntas(_id, setPreguntas));
        }

        const error = () => {
            message.error(body.msg);
        }
        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const preguntar = (_id, texto, setPreguntas)=>{
    return async(dispatch)=>{
        const resp=await fetchConToken(`pregunta-respuesta/${_id}`, {texto}, 'POST');
        const body=await resp.json();

        const success = () => {  ;
            dispatch(getPreguntas(_id, setPreguntas));
        }

        const error = () => {
            message.error(body.msg);
        }
        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

/*view de actividad */
export const viewActividad=(_id, setActividad, setIdAct)=>{
    return async(dispatch)=>{
        try {
            const resp=await fetchConToken(`curso/actividad/${_id}`);
            const body=await resp.json();
            
            if (resp.ok){
                dispatch(vistaActividad(body))
                setActividad(body);
                setIdAct(_id);
            } else{
                setActividad(null);
            }
        } catch (error){
            console.log(error);
            
        }
    }
}

const vistaActividad=(actividad)=>({
    type:types.ViewActividad,
    payload:actividad
});

/*--------------ruta current-----------------------*/
export const currentActivity=(_id,setStatusCargaVista,setId,endView = false)=>{
    return async(dispatch)=>{

        try {
            const resp=await fetchConToken(`curso/current/${_id}`);
            const body=await resp.json();

            if (resp.ok){
                dispatch(currentActividad({...body, endView}))
                //dispatch(viewActividad(body.actividad))
                setStatusCargaVista(body.status)
            } 
        } catch (error){
            console.log(error);
        }
    }
}

const currentActividad=(current)=>({
    type:types.currentActivity,
    payload:current
});

export const currentActividadFinal=(current)=>({
    type:types.currentActivityEnd,
    payload:current
});

/* ruta de evaluacion de usuario*/

export const evaluarActividad=(_id_curso, _id_actividad, actualizarUrl, setActividad, setIdAct)=>{
    return async(dispatch)=>{

        try {
            const resp=await fetchConToken(`curso/actividad-evaluar/${_id_actividad}`,{},'POST');
            const body=await resp.json();

            if (resp.ok){
                if(body.actividad_siguiente == null && body.terminado){
                    //Curso finalizado

                    dispatch(evaluarActivi(body));
                    //Cambiar estado para mostrar vista de finalizado despues de ultima actividad
                    dispatch(currentActividadFinal({ endView: true }));
                }else{
                    //Siguiente actividad

                    dispatch(evaluarActivi(body));
                    // dispatch(viewActividad(body.actividad_siguiente, setActividad, setIdAct))
                    actualizarUrl(body.actividad_siguiente);
                }
            } 
        } catch (error){
            console.log(error);
            
        }
    }
}

export const evaluarActivi=(evaluacion)=>({
    type:types.evaluacionActividad,
    payload:evaluacion
});

export const calificarActividad=(_id,rate,rate_comment,setStatusCargaVista)=>{
    return async(dispatch)=>{

        try {
            const resp=await fetchConToken(`curso/rate/${_id}`,{rate,rate_comment},'POST');
            // eslint-disable-next-line no-unused-vars
            const body=await resp.json();
            
            if (resp.ok){
                //dispatch(califActividad(body))
                dispatch(currentActivity(_id, setStatusCargaVista, undefined, true))

            } 
        } catch (error){
            console.log(error);
            
        }
    }
}

export const evaluarQuiz = (_id, data, setResultados) => {
    return async(dispatch) => {

        const resp = await fetchConToken(`curso/actividad-evaluar/${_id}`, {data}, 'POST');
        const body = await resp.json();

        const success = () => {
            body.evaluacion_data.preguntas.map((pregunta) => (
                data.map((respuesta) => (
                    (pregunta.pregunta === respuesta.pregunta) ?
                        Object.defineProperty(pregunta, 'respuesta', {value: respuesta.respuesta, writable: true}) : null
                ))
            ))
            setResultados(body);
        }

        const error = () => {
            message.error('Fallo al evaluar la actividad');
        }
        if (resp.ok===true){
            success();
        }else{
            error();
        }
    }
}

export const progresoVideo = (_id, data) => {
    return async(dispatch) => {

        const resp = await fetchConToken(`curso/actividad-progreso/${_id}`, data, 'POST');
        // const body = await resp.json();

        if (resp.ok===true){
            return;
        }else{
            message.error('Fallo al evaluar la actividad');
        }
    }
}

/* FORO DE USUARIO */
export const postsUsuarioLoading=(_id_tema,num_pag,setStatusCarga)=>{
    
    return async(dispatch)=>{
       
        try {
            const resp=await fetchConToken(`foro/posts/${_id_tema}?limite=5&pag=${num_pag}`);
            const body=await resp.json();
            console.log(body);//todas los posts de un tema
            if (resp.ok) {
                dispatch(postsUsuarioLoaded(body));
                setStatusCarga({
                   status:body.status,
                   total:body.total_posts
                })
                
            }
            
        } catch (error){
            console.log(error);
            
        }
    }
}

const postsUsuarioLoaded=(body)=>({
    type:types.postsUsuarioLoaded,
    payload:body
});

export const crearForoUsuario=(_id,post,num_pag)=>{
    //console.log(recurso);

    return async(dispatch)=>{

        const url = `https://apibcoursera.iivvo.com/api/foro/post/${_id}`;
        //console.log(url);
        const token = localStorage.getItem('token') || '';

        const formData=new FormData();
        formData.append('post',post);
       
        try {
            const resp=await fetch( url, {
                
                method:'POST',
                headers: {
                    'x-token': token
                },
                body: formData,
                redirect: 'follow'
            });
            const success = () => {
                message.info("Foro creado");
            }

            const error = () => {
                message.error('No se pudo crear el foro');
            }
            if (resp.ok===true){
                dispatch(postsUsuarioLoading(_id,num_pag))
               
                success();
            }else{
                error();
            }
        
            } catch (error) {
                console.log(error);
        }
    }
}

export const editarForoUsuario=(_id,_id_post,content,num_pag)=>{
    //console.log(recurso);

    return async(dispatch)=>{

        const url = `https://apibcoursera.iivvo.com/api/foro/post/${_id_post}`;
        //console.log(url);
        const token = localStorage.getItem('token') || '';

        const formData=new FormData();
        formData.append('post',content);
       
        try {
            const resp=await fetch( url, {
                
                method:'PUT',
                headers: {
                    'x-token': token
                },
                body: formData,
                redirect: 'follow'
            });
            const success = () => {
                message.info("Foro editado");
            }

            const error = () => {
                message.error('No se pudo editar el foro');
            }
            if (resp.ok===true){
                dispatch(postsUsuarioLoading(_id,num_pag))
               
                success();
            }else{
                error();
            }
        
            } catch (error) {
                console.log(error);
        }
    }
}

export const eliminarForoUsuario = (_id,_id_post,num_pag) => {
    
    return async(dispatch) => {
        const resp = await fetchConToken(`foro/post/${_id_post}`, {} , 'DELETE');
        const body = await resp.json();

        const success = () => {
            message.info('Foro eliminado');
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            dispatch(postsUsuarioLoading(_id,num_pag));
            success();
        }else{
            error();
        }
    }
}

// Reset de curso
export const resetCurso = (_id,idRender,setActividad,setStatusCargaVista,setIdAct) => {
    return async(dispatch) => {

        const resp = await fetchConToken(`curso/reset/${_id}`);

        const success = () => {
            message.success('Progreso del curso reiniciado correctamente')
        }

        const error = () => {
            message.error('No se puede reiniciar el progreso del curso');
        }
        if (resp.ok===true){
            success();
            dispatch(currentActivity(_id,setStatusCargaVista))
            dispatch(viewActividad(idRender,setActividad,setIdAct))
            dispatch(courseTracker(_id))
        }else{
            error();
        }
    }
}

// Finanzas Usuario

export const loadFinanzasIndex = (pag,fechas_key,desde,hasta, setPagos) => {
    return async()=>{
        const resp=await fetchConToken(`pagos?limite=5&pag=${pag}&paginacion=true&fechas_key=${fechas_key}&fechas_desde=${desde}&fechas_hasta=${hasta}`);
        const body=await resp.json();

        const success = () => {
            setPagos({
                status: true,
                pagos: body.pagos,
                total: body.total_pagos
            });
        }

        const error = () => {
            message.error(body.msg);
        }

        if (resp.ok===true){
            success();
        }else{
            error();
        }
    }
}

// Categorias 
export const Categorias = (setCategorias) => {
    return async(dispatch) => {

        const resp = await fetchSinToken(`clasificacion-categoria`);
        const body=await resp.json();
        console.log(body);


        const error = () => {
            message.error('No se pueden cargar las categorias');
        }
        if (resp.ok===true){
            
            setCategorias({
                status:body.status,
                clsificacion:body.clsificacion
            })
        }else{
            error();
        }
    }
}



