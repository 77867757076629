import { MenuOutlined, SearchOutlined } from '@ant-design/icons';
import {  Button, Col, Pagination, Row, Table, Input, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { loadUsuariosEnroladosCurso } from '../../actions/maestros';

export const TabUsuariosEnroladosCurso = ({_id, dispatch}) => {

    const [usuarios, setUsuarios] = useState({
        status: false,
        usuarios: [],
        total: 6
    });
    const [params, setParams] = useState({
        pagina: 1,
        search: '',
        filter: 0,
        filter_key: ''
    });
    
    useEffect(() => {
        dispatch(loadUsuariosEnroladosCurso(setUsuarios, _id, params));
    }, [dispatch, _id, params])


    const handleCambioPagina = (pag) => {
        setUsuarios({
            ...usuarios,
            status: false
        });

        setParams({
            ...params,
            pagina: pag
        });
    }

    const handleBuscar = (search) => {
        setUsuarios({
            ...usuarios,
            status: false
        });

        setParams({
            ...params,
            pagina: 1,
            search: search.buscar
        })
    }

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'nombre',
            key: 'nombre',
            render: (text, record) => 
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div className='tab-usuarios-img'></div>
                {record.nombre} {record.apellidos}
            </div>
        },
        {
            title: 'Correo',
            dataIndex: 'email',
            key: 'email'
        },
        {
            width: '10%',
            title: 'Opciones',
            dataIndex: 'opciones',
            key: 'opciones' ,
            render: (text, record) => {
                    return (
                            <Button 
                                shape='round' 
                                size='middle' 
                                icon={<MenuOutlined />} 
                                className="btn-crud-opciones"
                            />
                    )
            }
        }
    ]

    return (
        <>
            <Row style={{backgroundColor: 'white', padding: '30px'}}>
                <Col xs={24} lg={20}>
                    <Form onValuesChange={handleBuscar}>
                        <Form.Item name="buscar">
                            <Input className="admin-input-buscador" placeholder="Buscar" prefix={<Button type="text" htmlType="submit" style={{padding: '5px !important'}}><SearchOutlined className="search-icon" /></Button>}/>
                        </Form.Item>
                    </Form>
                </Col>

                <Col span={24} style={{marginTop: '20px'}}>
                    <Table pagination={false} columns={columns} dataSource={usuarios.usuarios} />
                </Col>

                <Col xs={24} lg={24} style={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
                    <Pagination current={params.pagina} onChange={handleCambioPagina} pageSize={6} total={usuarios.total} showSizeChanger={false}/>
                </Col>
            </Row>
        </>
    )
}
