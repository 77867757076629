import React from 'react'
import { Empty } from 'antd'

export const VideoError = () => {
  return (
    <div className='w-100 text-center' style={{marginTop: '100px',  minHeight: '250px'}}>
        <Empty description='Error' />
        <div className='usuarioVideoScreen-error-text'>Error al cargar recurso, intenta nuevamente.</div>
    </div>
  )
}
