import React from 'react';
import ReactDOM from 'react-dom';
import { DaggosApp } from './DaggosApp';
import './styles.css';
import 'antd/dist/antd.css';

ReactDOM.render(

    <DaggosApp/>,
  document.getElementById('root')

);
