import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string';
import { autoLoginAdmin } from '../../actions/auth';
import { AdminAuth } from '../../components/auth/AdminAuth'
import { LoadingScreen } from '../../components/ui/LoadingScreen';
import { AdminPrivateRouter } from './AdminPrivateRouter';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

export const AdminRouter = () => {

    const dispatch = useDispatch();
    const {id, checking} = useSelector(state => state.auth);

    //Recuperar token para autologin de usuarios redireccionados
    const location = useLocation();    
    const history = useHistory();
    const { token } = queryString.parse(location.search);
    //eliminar token de url
    if(token){
        history.replace({
            search: '',
        });
    }

    useEffect(() => {
        dispatch(autoLoginAdmin(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch])

    if(checking){
        return(
            <LoadingScreen />
        )
    }

    return (
        <>
            <Switch>
                <PublicRoute 
                    path="/admin/auth"
                    component={AdminAuth}
                    isAuthenticated={!!id}
                />
                <PrivateRoute 
                    path="/admin/"
                    component={AdminPrivateRouter}
                    isAuthenticated={!!id}
                />
                <Redirect to="/admin/auth"/>
            </Switch>


        </>
    )
}
