import { Tabs } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadCursoById, loadIndexCategorias } from '../../actions/maestros';

import { TabContenidoCurso } from './TabContenidoCurso';
import { TabReviewsCurso } from './TabReviewsCurso';
import { TabUsuariosEnroladosCurso } from './TabUsuariosEnroladosCurso';
import { TabInformacionCurso } from './TabInformacionCurso';

export const DocenteCursoTabsScreen = () => {

    const dispatch = useDispatch();
    const { _id } = useParams();
    const [cursoData, setCursoData] = useState({});
    const [indexCategorias, setIndexCategorias] = useState([]);
    const { TabPane } = Tabs;

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(loadCursoById(_id, setCursoData));
        dispatch(loadIndexCategorias(setIndexCategorias));
    }, [dispatch, _id]);

    return (
        <div>
            {
                (cursoData.nombre) && (
                    <div className='tabs-container-curso'>
                        <Tabs defaultActiveKey='1' type='card' style={{ margin: '0px !important' }} className="docente-tabs">
                            <TabPane
                                tab={
                                    <div style={{ color: '#707070' }}>
                                        Información General
                                    </div>
                                }
                                key='1'
                                className='tab-curso'
                            >
                                <TabInformacionCurso 
                                    _id={_id}
                                    curso={cursoData} 
                                    setCurso={setCursoData}
                                    indexCategorias={indexCategorias}
                                />
                            </TabPane>

                            <TabPane
                                tab={
                                    <div style={{ color: '#707070' }}>
                                        Contenido
                                    </div>
                                }
                                key='2'
                            >
                                <TabContenidoCurso 
                                    cursoId={_id} 
                                    cursoData={cursoData} 
                                />
                            </TabPane>

                            {/* <TabPane
                                tab={
                                    <span style={{ color: '#707070' }}>
                                        Crear Diploma
                                            </span>
                                }
                                key='3'
                            >

                            </TabPane> */}

                            <TabPane
                                tab={
                                    <div style={{ color: '#707070' }}>
                                        Usuarios
                                    </div>
                                }
                                key='4'
                            >
                                <TabUsuariosEnroladosCurso 
                                    _id={_id} 
                                    dispatch={dispatch} 
                                />
                            </TabPane>

                            <TabPane
                                tab={
                                    <div style={{ color: '#707070' }}>
                                        Reseñas
                                    </div>
                                }
                                key='5'
                            >
                                <TabReviewsCurso 
                                    _id={_id} 
                                    dispatch={dispatch} 
                                />
                            </TabPane>

                            {/* <TabPane
                                tab={
                                    <span style={{ color: '#707070' }}>
                                        Estadísticas
                                            </span>
                                }
                                key='6'
                            >

                            </TabPane> */}
                        </Tabs>
                    </div>
                )
            }
        </div>
    )
}
