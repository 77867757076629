import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getMaestro, getCursosMaestro } from '../../actions/admin';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Image, Row, Col, Card, Rate, Pagination } from 'antd';
import { LeftOutlined, UserOutlined } from '@ant-design/icons';
import portada from '../../assets/nuevoCurso.jpg';
import { CursoSkeleton } from '../ui/SkeletonLoader/CursoSkeleton';

export const AdminMaestroScreen = () => {
    const dispatch = useDispatch();
    const {_id} = useParams();
    const [maestro, setMaestro] = useState({
        nombre: '',
        apellidos: '',
        img: '',
        iniciales: ''
    });
    const [cursos, setCursos] = useState({
        cursos: [],
        total: 8
    });
    const [cursosLoding, setCursosLoading] = useState(false);
    const [pagina, setPagina] = useState(1);

    if(maestro.nombre !== ''){
        maestro.iniciales = maestro.nombre.charAt(0).toUpperCase() + 
        maestro.apellidos.charAt(0).toUpperCase();
    }

    useEffect(() => {
        dispatch(getMaestro(_id, setMaestro));
    }, [dispatch, _id]);

    useEffect(() => {
        dispatch(getCursosMaestro(_id, setCursos, pagina, setCursosLoading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, pagina]);

    const cambioPagina = (p) => {
        setPagina(p);
        dispatch(getCursosMaestro(_id, setCursos, p, setCursosLoading));
    }
    
    return (
        <>
        <Link to="/admin/maestros">
            <div className="btn-atras"><LeftOutlined className="icon-atras" />  atrás</div>
        </Link>
        <div className="admin-maestro-card">
            <div className="admin-maestro-info">
                {
                    (maestro.img) 
                    ? (
                        <Image preview={false} className="admin-maestro-img" src={maestro.img}></Image>
                    )
                    : (
                        <div className="admin-maestro-iniciales">{maestro.iniciales}</div>
                    )
                }
                <div className="admin-maestro-nombre bold">{maestro.nombre + ' ' + maestro.apellidos}</div>
                <div className="admin-maestro-profesion">{maestro.profesion}</div>
            </div>
                <div className="admin-maestro-resumen">{maestro.resumen}</div>
        </div>
        <Row style={{justifyContent: 'center'}}>
            <div className="maestro-cursos-texto">Cursos</div>
        </Row>
        <Row gutter={[16, 16]} className="maestro-cursos">
        { 
            (cursosLoding)
            ?(  (cursos.cursos.length > 0)
                ? (cursos.cursos.map ((curso) => {
                    return (
                        <Col key={curso._id} className="gutter-row" xs={24} sm={12} md={12} lg={6} >
                            <Link to={"/admin/curso/"+curso._id}>
                                    <Card
                                        hoverable
                                        cover={<img style={{height: '170px'}} alt="Podcast" src={(curso.img) ? curso.img : portada} />}
                                    >  
                                        <Card.Grid hoverable={false} style={{width: '100%', height:'60px', boxShadow: 'none'}}>
                                            <div className="card-titulo bold">{curso.nombre}</div>
                                        </Card.Grid>

                                        <Card.Grid hoverable={false} style={{width: '100%', boxShadow: 'none'}}>
                                            <div style={{display: 'flex'}}>
                                                <span><h3>{curso.rate}</h3></span>
                                                <Rate disabled allowHalf defaultValue={parseFloat(curso.rate)} style={{fontSize: '14px', color: 'orange', margin: '0px 5px 0px 5px'}} />
                                                <span>({curso.no_evaluaciones})</span>
                                            </div>
                                            <div className="card-header">
                                                <div><UserOutlined /> {curso.no_participantes}</div>
                                                <div style={{color: 'green'}}>{(curso.precio === '0') ? "Gratis" : '$'+curso.precio}</div>
                                            </div>
                                        </Card.Grid>
                                    </Card>
                            </Link>
                        </Col>
                    
                    )
                })
                ):(
                    <div className="no-disponible">No hay cursos disponibles</div>
                ) 
            ):(      
                [0,1,2,3,4,5,6,7].map(n => (
                    <Col className="gutter-row" xs={24} sm={12} md={6}>
                        <CursoSkeleton key={n} loading={!cursosLoding} />
                    </Col>
                ))
                
            )
        }
        </Row>
        <Row>
            <div style={{width: '100%', textAlign: 'center', marginTop: '15px'}}>
                <Pagination onChange={cambioPagina} pageSize={8} total={cursos.total} responsive={true} />
            </div>
        </Row>
        </>
    )
}
