import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Empty } from 'antd'
import { RollbackOutlined } from '@ant-design/icons'

export const ActividadError = () => {
  return (
    <div className='w-100 text-center' style={{marginTop: '100px',  minHeight: '250px'}}>
        <Empty description='Error' />
        <div className='usuarioVideoScreen-error-text'>Actividad no identificada.</div>
        <Link style={{color: 'var(--primary)' }} to="/">
            <Button
                className='viewMakeCourse-progress-reset-btn'
                icon={<RollbackOutlined />}
                type="primary"
            >
                Ir a inicio
            </Button>
        </Link>
    </div>
  )
}
