import React from 'react';
import { Rate, Image } from 'antd';

export const AdminValoracion = ({valoracion}) => {
    const {usuario, fecha_rate, rate, rate_comment} = valoracion;
    const iniciales = usuario.nombre.charAt(0).toUpperCase() + usuario.apellidos.charAt(0).toUpperCase();

    return (
        <div className="admin-curso-valoracion-container">
            <div className="admin-curso-valoracion-item">
                <div className="admin-curso-valoracion-info">
                {(usuario.img) 
                    ? (
                        <Image preview={false} className="admin-curso-valoracion-img" src={usuario.img}></Image>
                    )
                    : (
                        <div className="admin-curso-valoracion-iniciales">{iniciales}</div>
                    )
                }
            <div>
                <div className="admin-curso-valoracion-nombre">{usuario.nombre + ' ' + usuario.apellidos}</div> 
                <div style={{display: 'flex', alignItems: 'flex-end'}}>
                    <Rate disabled allowHalf defaultValue={rate} style={{fontSize: '14px', color: 'orange', margin: '0px 5px 0px 5px'}} />
                    <div className="admin-curso-valoracion-fecha">{fecha_rate}</div>
                    </div>
                </div>
            </div>

            <div className="admin-curso-comment">{rate_comment}</div>

            </div>
        </div>
    )
}
