import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, Popconfirm, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { bajarNivelTema, eliminarTema, loadTemaById, loadTemasIndex, subirNivelTema } from '../../actions/maestros';
import { ModalAddTemas } from './ModalAddTemas';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, EditOutlined, TeamOutlined } from '@ant-design/icons';
import { ActividadesDocente } from './ActividadesDocente';
import { useHistory } from 'react-router';

export const DocenteTemasIndex = ( {_id, cursoStatus, handleMostrarActividad, actividad} ) => {

    const {Panel} = Collapse;

    const dispatch = useDispatch();
    const [vis, setVis] = useState(false);
    const history=useHistory();

    // eslint-disable-next-line no-unused-vars
    const [statusCarga, setStatusCarga] = useState(false);

    const initValues = {
        '_id': '',
        'nombre': '',
        'descripcion': '',
        'quiz': false,
        'foro': false
    }

    const [temaValues, setTemaValues] = useState(initValues);

    const [temas, setTemas] = useState([]);

    useEffect(() => { 
        dispatch(loadTemasIndex(_id, setTemas));
    }, [dispatch, _id])

    const handleAddTema = () => {
        setTemaValues(initValues); 
        setVis(true);
    }

    const handleSubirNivel = (_id_tema) => {
        dispatch(subirNivelTema(_id_tema, setTemas, _id));
    }

    const handleBajarNivel = (_id_tema) => {
        dispatch(bajarNivelTema(_id_tema, setTemas, _id));
    }

    const handleEditarTema = (_id_tema) => {
        dispatch(loadTemaById(_id_tema, setTemaValues, setVis));
    }

    const handleEliminarTema = (_id_tema) => {
        dispatch(eliminarTema(_id_tema, _id, setTemas));
    }
    const handleVentana=(_id_tema)=>{
        history.push(`/docente/foro/${_id_tema}`)

    }   

    const genOpciones = (_id_tema,foro) => {
        return(
            <div style={{display: 'flex'}}>
                <div>
                   {
                       (foro===true) &&<TeamOutlined onClick={()=>handleVentana(_id_tema)} style={{fontSize: '17px'}} />
                   }
                    
                </div>
                <div>
                    <Popconfirm 
                        placement='topRight' 
                        title={'¿Seguro que quieres eliminar este tema?'} 
                        okText='Sí, eliminar' 
                        cancelText='Cancelar' 
                        onConfirm={event =>{
                            handleEliminarTema(_id_tema);
                            event.stopPropagation();
                        }}
                        onCancel={event => {
                            event.stopPropagation();
                        }}
                    >
                        <DeleteOutlined 
                            style={{fontSize: '17px'}}
                            onClick={event => {
                                event.stopPropagation();
                            }}
                        />
                    </Popconfirm>
                </div>
                <div>
                    <EditOutlined 
                        style={{fontSize: '17px', marginRight:'10px'}} 
                        onClick={event => {
                            handleEditarTema(_id_tema);
                            event.stopPropagation();
                        }}
                    />
                </div>

                <div>
                    <ArrowUpOutlined 
                        style={{fontSize: '17px'}} 
                        onClick={event => {
                            handleSubirNivel(_id_tema)
                            event.stopPropagation();
                        }}
                    />
                </div>

                <div>
                    <ArrowDownOutlined 
                        style={{fontSize: '17px'}} 
                        onClick={event => {
                            handleBajarNivel(_id_tema)
                            event.stopPropagation();
                        }}
                    />
                </div>
            </div>
        )
    }

    const genForo = (_id_tema,foro) => {
        return(
            <div>
                {
                    (foro===true) &&<TeamOutlined onClick={()=>handleVentana(_id_tema)} style={{fontSize: '17px'}} />
                }  
            </div>
        )
    }

    
    return (
        <>
            <div>
                {
                    (temas.length > 0 ) && (
                        <Row>
                            <Col span={24}>
                                <Collapse	
                                    expandIconPosition="left"
                                    accordion
                                >
                                    {
                                        temas.map(tema => (
                                            
                                            <Panel
                                                bodyStyle={{padding: '0', overlow: 'hidden'}}
                                                header={tema.nombre}
                                                key={tema._id}
                                                extra={(cursoStatus !== 2) ? genOpciones(tema._id,tema.foro) : genForo(tema._id,tema.foro)}
                                            >
                                                <ActividadesDocente 
                                                    temaId={tema._id} cursoStatus={cursoStatus} handleMostrarActividad={handleMostrarActividad}
                                                    actividad={actividad} 
                                                />
                                            </Panel>
                            
                                        ))
                                    }             
                                </Collapse>
                            </Col>
                        </Row>
                    )
                }
                {
                    (cursoStatus !== 2)
                        &&
                            <div style={{textAlign: 'center'}}>
                                <Button className='btn-link-agregar' style={{color: 'grey'}} type='link' onClick={() => {handleAddTema()}} >+Agregar Tema</Button>
                            </div>
                }
                
            </div>

            <ModalAddTemas 
                _id={_id}
                temaValues={temaValues}
                setTemaValues={setTemaValues}
                initValues={initValues}
                setVis={setVis} 
                vis={vis}
                setTemas={setTemas}
                setStatusCarga={setStatusCarga}
            />
        </>
    )
}
