import React from 'react';
import { Skeleton } from 'antd';

export const CursoSkeleton = ({loading}) => {
    return (
        <div style={{height: '350px', padding: '0px 10px'}}>
            <Skeleton.Image active loading={loading} className="curso-skeleton"/>
                <Skeleton active loading={loading} className="card-titulo bold text">Aprende Python de 0 a experto</Skeleton>
        </div>
    )
}
